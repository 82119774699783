import React, {useEffect} from "react";
import MobileHeader from "./mHeader";
import MobileFooter from "./mFooter";
import {useNavigate} from "react-router-dom";
import $ from "jquery";

const MobileQA = () => {
    const navigate = useNavigate();

    const openGameQAPage = (gameName) => {
        navigate('/mobile/qa_game', {state: {gameName: gameName}});
    };

    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'zoom': 'unset'
        });

        // $('.m_footer_box').css('position', 'fixed');
    }, []);

    return (
        <>
            <MobileHeader/>

            <div style={{background: '#f5f5f5'}}>
                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>고객센터</span>
                </div>

                <div className="m_qa_content">
                    <div className="m_qa_title_box">
                        도움말
                    </div>

                    <div className="m_qa_box_border">
                        <div className="m_qa_box">
                            <div className="m_aq_title_box" onClick={event => openGameQAPage('sm_dualgo')}>
                                <div className="m_qa_inner">
                                    신맞고
                                </div>

                                <div className="m_qa_button_img"/>
                            </div>
                        </div>

                        <div className="m_qa_box">
                            <div className="m_aq_title_box" onClick={event => openGameQAPage('sm_baduki')}>
                                <div className="m_qa_inner">
                                    바둑이
                                </div>

                                <div className="m_qa_button_img"/>
                            </div>
                        </div>

                        <div className="m_qa_box">
                            <div className="m_aq_title_box" onClick={event => openGameQAPage('sm_poker')}>
                                <div className="m_qa_inner">
                                    SM 7포커
                                </div>

                                <div className="m_qa_button_img"/>
                            </div>
                        </div>

                        <div className="m_qa_box">
                            <div className="m_aq_title_box" onClick={event => openGameQAPage('other')}>
                                <div className="m_qa_inner">
                                    홈페이지
                                </div>

                                <div className="m_qa_button_img"/>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <MobileFooter/>
        </>
    );
}

export default MobileQA;