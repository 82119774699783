import React, {useEffect, useState} from "react";
import "../css/gameGuide.css";
import $ from 'jquery';

const InstallAndRun = () => {
  return (
      <div className="guide_right_content guide_right_content_ol_img">
          <ol style={{marginLeft: '10px'}}>
              <img src={require("../images/daulgo_guide_img/start_01.png")}/>
              <div className="guide_right_txt_content">
                  <li>
                      에스엠 게임즈 홈페이지에서 게임 시작 버튼을 눌러, 신맞고 클라이언트 다운로드를 진행합니다.
                  </li>
              </div>

              <img src={require("../images/daulgo_guide_img/start_02.png")}/>
              <div className="guide_right_txt_content">
                  <li>
                      다운로드된 프로그램을 안내에 따라 설치해주세요.
                  </li>
              </div>

              <img src={require("../images/daulgo_guide_img/start_03.png")}/>
              <div className="guide_right_txt_content">
                  <li>
                      클라이언트를 실행하여, 로그인 화면에서 아이디와 비밀번호를 입력하고 대기실로 이동합니다.
                  </li>
              </div>


              <img src={require("../images/daulgo_guide_img/start_04.png")}/>
              <div className="guide_right_txt_content">
                  <li>
                      대기실에서 게임 방을 생성하시거나, 방에 입장하여 SM 신맞고를 즐겨주세요!
                  </li>
              </div>

          </ol>

      </div>
  );

};

const MakingRoom = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/daulgo_guide_img/start_05.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_img"></span>
                    대기실에서 원하는 설정에 따라, 게임 방을 직접 만들 수 있습니다.
                </div>

                <div>
                    <span className="guide_bullet_img"></span>
                    방 만들기는 아래와 같은 기능으로 구성되어 있습니다.
                </div>
            </div>

            <ol>
                <div className="guide_right_txt_content making_room">
                    <li>
                         방 제목 : 설정된 방 제목 중, 원하는 방 제목을 간편하게 선택할 수 있습니다.
                    </li>

                    <li>
                         방 제목 입력 : 원하시는 방 제목을 직접 입력할 수 있습니다.
                    </li>

                    <li>
                         시드머니 : 점당 얻는 머니를 설정합니다. 채널에 따라 다릅니다.
                    </li>

                    <li>
                         확인 : 설정하신 조건으로 방을 생성합니다.
                    </li>

                </div>

            </ol>

        </div>
    );
}


const DirectEntry = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/daulgo_guide_img/start_06.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_img"></span>
                    바로 입장 버튼을 클릭하여 생성된 방에 원하는 조건에 맞추어 즉시 입장이 가능합니다.
                </div>

                <div>
                    <span className="guide_bullet_img"></span>
                    바로 입장은 아래와 같은 기능으로 구성되어 있습니다.
                </div>
            </div>

            <ol>
                <div className="guide_right_txt_content making_room">
                    <li>
                        활성화 시드머니 : 해당 시드머니 값으로 설정된 방이 존재하고 있음을 나타냅니다.<br/>
                        활성화된 시드머니 클릭 시, 해당 시드머니 방으로 입장합니다.
                    </li>

                    <li>
                        비활성화 시드머니 : 해당 시드머니 값으로 설정된 방이 존재하고 있지 않음을 나타냅니다.
                    </li>

                    <li>
                        취소 : 방 입장을 취소하고, 대기실 화면으로 이동합니다.
                    </li>

                </div>

            </ol>


            <img src={require("../images/daulgo_guide_img/start_07.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_img"></span>
                    만약 입장 가능한 방이 없는 상태에서 입장 버튼을 클릭 시, 위와 같은 팝업이 노출됩니다.
                </div>
            </div>

        </div>
    );
}


const UseGameRoom = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/daulgo_guide_img/start_05.png")}/>

            <ol>
                <div className="guide_right_txt_content making_room">
                    <li>
                        상대 플레이어가 획득한 패를 볼 수 있는 부분입니다.
                    </li>

                    <li>
                        상대 플레이어의 손 패의 남은 매수를 보여줍니다.
                    </li>

                    <li>
                        상대 플레이어의 닉네임, 등급, 보유머니, 아바타를 확인할 수 있습니다.
                    </li>

                    <li>
                        바닥패와 현재 점수를 볼 수 있습니다.
                    </li>

                    <li>
                        진행 중인 미션, 미션의 달성 여부를 볼 수 있습니다.
                    </li>

                    <li>
                        채팅창입니다. 상대방과 채팅을 할 수 있으며, 게임진행정보를 볼 수 있습니다.
                    </li>

                    <li>
                        내가 먹은 패를 보여줍니다.
                    </li>

                    <li>
                        내가 가지고 있는 손패를 보여줍니다. 마우스나 단축키를 사용하여 패를 낼 수 있습니다.
                    </li>

                    <li>
                         나의 게임정보를 확인할 수 있으며, 자동치기 기능이나 나가기 예약을 할 수 있습니다.
                    </li>

                </div>

            </ol>

        </div>
    );
}


const CardComposition = () => {

    const images = [
        "guide_01.jpg",
        "guide_02.jpg",
        "guide_03.jpg",
        "guide_04.jpg",
        "guide_05.jpg",
        "guide_06.jpg",
        "guide_07.jpg",
        "guide_08.jpg",
        "guide_09.jpg",
        "guide_10.jpg",
        "guide_11.jpg",
        "guide_12.jpg",
        "guide_13.jpg"
    ];

    return (
        <div className="guide_right_content">
            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_img"></span>
                    같은 종류의 무늬를 가진 4장의 화투가 총 12쌍과 보너스패 2장을 합쳐 총 50장으로 구성됩니다.
                </div>
            </div>


            {images.map((image, index) => (
                <img
                    key={index}
                    src={require(`../images/daulgo_guide_img/${image}`)}
                    style={{
                        margin: index % 2 === 0 ? "0 24px 24px 0" : "0 0 24px 0",
                        width: "305px"
                    }}
                    alt={`Guide ${index + 1}`}
                />
            ))}

        </div>
    );
}


const HowToPlay = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/daulgo_guide_img/guide_14.png")}/>

            <ol>
                <div className="guide_right_txt_content making_room">
                    <li>
                        플레이어간 게임이 시작되면 가운데 패를 쌓고, 바닥에 8장의 패를 깔아둡니다.
                    </li>

                    <li>
                        각 플레이어에게 10장의 패를 분배합니다.
                    </li>

                    <li>
                        바닥에 깔린 패를 각각 1장씩 골라 선/후를 결정합니다.
                    </li>

                    <li>
                        보유한 패 중, 원하는 패를 클릭하여 바닥에 냅니다. 이 때 동일한 월 패가 있는 경우 짝을 맞춥니다.
                    </li>

                    <li>
                        기리패를 뒤집어 바닥에 놓습니다.
                    </li>

                    <li>
                        짝이 맞은 패를 습득합니다.
                    </li>

                    <li>
                        패를 습득하여 먼저 7점을 달성한 플레이어에게 고/스톱의 기회를 부여합니다.
                        고를 진행한 경우, 점수가 증가하며 게임이 이어집니다.
                        이 때 고를 한 플레이어는 점수가 고 할때보다 점수가 올라가야 고/스톱의 기회가 다시 주어집니다.
                        스톱을 진행한 경우, 현재 점수 x 시드머니 x 점수 배율만큼 머니를 정산하고 경기를 종료하며,
                        다음 경기를 준비합니다.
                    </li>

                    <li>
                        다음 경기 진행 시, 이전 경기 승자가 선이 되므로 선/후 결정 과정을 진행하지 않습니다.
                    </li>

                </div>

            </ol>

        </div>
    );
}


const AdditionalRules = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/daulgo_guide_img/guide_15.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">미리 흔들기</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        동일 월 패가 3장 있을 경우, 미리 흔들기를 선언할 수 있습니다.
                    </li>

                    <li>
                        흔들기 패 클릭 후, 예 를 누를 경우 흔들기 횟수가 1회 증가 합니다.
                    </li>

                    <li>
                        아니오 를 클릭한 경우, 흔들기 횟수는 증가하지 않고 클릭한 패를 바닥에 냅니다.
                    </li>

                    <li>
                        흔들기를 선언한 패를 가지고 있다가, 바닥에 동일한 월패가 나오면 폭탄으로 사용할 수 있습니다.
                    </li>

                    <li>
                        이 경우는, 이미 흔들기를 했어도 추가로 흔들기 횟수가 1회 증가합니다.
                    </li>

                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/guide_16.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">흔듬패 폭탄 만들기</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        미리 흔들어둔 패 중, 1장을 바닥에 낸 뒤 보너스 패를 사용해 동일 월 패를 뽑아 폭탄을 만들 수 있습니다.
                    </li>

                    <li>
                        이 경우 미리 흔들어 두었기 때문에 흔들기 횟수가 1회 증가 하며, 폭탄에 따른 흔들기 횟수가 1회 추가 증가합니다.
                    </li>

                    <li>
                        만약 흔들지 않은 패로 폭탄을 만든 경우, 폭탄 패에 따른 흔들기 횟수만 1회 증가합니다.
                    </li>

                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/guide_17.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">흔듬패 총통 만들기</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        게임 중 동일한 월 패가 3장 있을 때, 보너스 패를 사용해 동일한 월 패를 뽑아 총통을 만들 수 있습니다.
                    </li>

                    <li>
                        미리 흔들기를 선언한 뒤, 보너스 패를 사용해 총통을 만들 수 있습니다.
                    </li>

                    <li>
                        총통으로 만든 패를 들고치기 할 때, 만약 미리 흔든 패를 총통으로 만들어 들고치기 하는 경우 미리 흔든 횟수 1회 총통 들고치기에 따른 흔들기 횟수 1회, 남은 총통패를 폭탄으로 사용하여 흔들기 횟수가 1회 추가 증가하여 흔들기 횟수가 총 3회 만큼 만들 수 있습니다.
                    </li>

                </ol>
            </div>

        </div>
    );
}


const CalculateScore = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/daulgo_guide_img/progress_01.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">뻑</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        바닥패와 같은 패를 냈는데 뒤집어서 나온패가 먹은패와 같은 종류의 패일 경우 가져오지 못하고 3장을 바닥에 쌓아 놓습니다.
                    </li>

                </ol>
            </div>


            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">첫뻑</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        첫 베팅에서 뻑인 경우, 7점 x 점당 금액 x 점수 배율(미션, 밀기)만큼 머니를 획득합니다.
                    </li>

                </ol>
            </div>


            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">연뻑</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        첫뻑에 이어 두 번째 베팅에서 뻑인 경우, 14점 x 점당 금액 x 점수 배율(미션, 밀기)만큼 머니를 획득합니다.
                    </li>
                </ol>
            </div>


            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">삼연뻑</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        연뻑에 이어 세 번째 베팅에서 뻑인 경우, 21점 x 점당 금액 x 점수 배율(미션, 밀기)만큼 머니를 획득하고 7점 x 점당 금액 x 점수 배율(미션, 밀기) 하며 승자로
                        게임을 종료합니다.
                    </li>

                </ol>
            </div>


            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">삼뻑</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        불연속 뻑이 3번인 경우, 7점x점당 금액x 점수 배율(미션, 밀기)만큼 머니를 획득합니다.
                    </li>

                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_02.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">뻑패 습득</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        뻑패를 먹을 경우, 상대방으로부터 피를 1장 뺏어옵니다.
                    </li>
                </ol>
            </div>


            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">자뻑</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        자신이 만든 뻑패를 자신이 먹을 경우, 상대방으로부터 피를 2장 뺏어옵니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_03.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">쪽</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        짝이 없는 패를 바닥에 내고, 기리패를 뒤집어 나온 패가 짝이 맞는 경우 쪽이라고 합니다.
                    </li>
                    <li>
                        쪽을 한 경우, 상대방의 피를 1장 뺏어옵니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_04.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">따닥</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        바닥에 짝이 맞는 두 장의 패가 있을 때, 그 중 1장을 보고 패를 냈는데 기리패를 뒤집어 나온 패가 짝이 맞는 경우를 따닥이라고 합니다.
                    </li>
                    <li>
                        따닥을 한 경우, 상대방의 피를 1장 뺏어옵니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_05.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">판쓸</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        바닥에 1장도 남기지 않고, 모든 패를 가져왔을 경우 판쓸 이라고 합니다.
                    </li>
                    <li>
                        판쓸을 할 경우, 상대방으로부터 피 1장을 가지고 옵니다.
                    </li>
                </ol>
            </div>

            <img src={require("../images/daulgo_guide_img/progress_06.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">총통</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        바닥 혹은 패에 동일한 월 패가 4장 모두 존재할 때를 총통이라고 합니다.
                    </li>
                    <li>
                        바닥에 동일 월 패가 4장 깔려있는 경우, 선이 승자로 해당 경기를 종료합니다.
                    </li>
                    <li>
                        손 패에 동일한 월 패가 4장 깔려있는 경우, 총통을 들고 게임을 재개할지, 종료할 지 정할 수 있습니다.
                        이때 끝내기를 한 경우 10점 x 점당 금액 x 점수 배율(미션, 밀기)에 해당하는 머니를 획득합니다.
                    </li>
                    <li>
                        총통이 2개 존재할 경우, 40점 x 점당 금액 x 점수 배율(미션, 밀기)에 해당하는 머니를 획득할 수 있습니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_07.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">폭탄</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        동일 월 패를 3장 가지고 있다가, 짝이 맞는 패가 바닥 패로 나왔을 때 내면 3장이 한꺼번에 나가면서
                        폭탄이 되고 흔들기 횟수가 1회 증가합니다.
                    </li>
                    <li>
                        폭탄을 할 경우, 상대방으로부터 피를 1장을 가지고 오며 손 패가 적어지기 때문에
                        기리패를 뒤집을 수 있는 공패를 2매 생성합니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_08.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">수류탄</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        동일 월 패가 바닥에 2매 있고, 손 패에 2매 있을 때 내면 2장이 한꺼번에 나가면서 수류탄이 됩니다.
                    </li>
                    <li>
                        수류탄을 할 경우, 상대방으로부터 피를 1장을 가지고 옵니다.
                        수류탄을 할 경우, 손 패가 적어지기 때문에 기리패를 뒤집을 수 있는 공패를 1매 생성합니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_09.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">박</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        특정 조건에 의하여 박 이라는 효과가 적용됩니다.
                    </li>
                </ol>
            </div>


            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">고박</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        고를 한 후 상대 플레이어가 점수가 나서 스톱을 할 경우 고박이 발생합니다.
                    </li>
                    <li>
                        상대 플레이어에게 2배의 점수를 물어야 합니다.
                    </li>
                </ol>
            </div>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">광박</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        상대가 광으로 점수가 나고, 자신이 광을 1장도 가지고 있지 않을 때 발생합니다.
                    </li>
                    <li>
                        상대 플레이어에게 2배의 점수를 물어야 합니다.
                    </li>
                </ol>
            </div>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">멍박</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        상대가 열끗이 7장 이상이면 발생합니다.
                    </li>
                    <li>
                        상대 플레이어에게 2배의 점수를 물어야 합니다.
                    </li>
                </ol>
            </div>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">피박</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        상대가 피로 점수가 나고, 자신이 피를 8장 미만으로 가지고 있을 때 발생합니다.
                    </li>
                    <li>
                        상대 플레이어에게 2배의 점수를 물어야 합니다.
                    </li>
                    <li>
                        단 피를 1장도 먹지 못한 경우는 피박으로 인정되지 않습니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_10.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">밀기</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        승리한 플레이어는 획득한 머니를 받지 않고, 다음 판 점수를 x2, x4배로 진행할 지 선택할 수 있습니다.
                    </li>

                    <li>
                        2배 밀기는 상대 플레이어의 승인이 필요하지 않습니다.
                    </li>
                    <li>
                        4배 밀기는 상대 플레이어의 승인이 필요하며, 밀기 거절 시 2배 배수로 적용되어 다음 경기가 진행됩니다.
                    </li>
                    <li>
                        밀기는 최대 32배까지 적용이 가능합니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_11.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">나가리</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        양쪽 플레이어가 7점을 내지 못하거나, 고를 한 플레이어가 고를 한 후 추가점수를 내지 못하고 이 끝나는 경우를 나가리라고 합니다.
                    </li>
                    <li>
                        나가리 발생 시, 승부는 무승부 처리되며 다음 판 2배 배수가 적용 됩니다. 만약 밀기 배수가 32배일 때, 나가리가 발생할 경우 2배로 다음 경기를 시작합니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_12.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">보너스패</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        보너스 패를 손 패에서 내거나, 기리패에서 뒤집은 패가 보너스 패인 경우 상대방으로부터 피를 1장 뺏어옵니다.
                    </li>
                    <li>
                        보너스 패를 손에서 낼 경우, 기리패에서 패를 1장 가져옵니다.
                    </li>
                </ol>
            </div>



        </div>
    );
}


const Pedigree = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/daulgo_guide_img/progress_13.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">광</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        광(光)자가 표시되어 있는 패를 의미합니다.
                    </li>
                    <li>
                        비광 포함 3장일 경우, 2점을 획득하며
                    </li>
                    <li>
                        비광 미포함 3장일 경우, 3점을 획득하며
                    </li>
                    <li>
                        비광에 상관없이 4장일 경우, 4점을 획득하며
                    </li>
                    <li>
                        광 5장을 모두 모을 경우, 15점을 획득합니다.
                    </li>
                    <li>
                        광으로 점수가 났으나, 상대 광이 없다면 상대는 광박이 됩니다.
                    </li>

                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_14.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">열끗</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        열끗 패가 5장 모이면 1점을 획득합니다.
                    </li>
                    <li>
                        이후 추가로 획득하는 열끗 1장마다 1점씩 추가됩니다.
                    </li>
                    <li>
                        열끗이 7장 이상으로 승리할 경우, 상대는 멍박이 됩니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_15.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">고도리</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        2월, 4월, 8월 열끗 패를 모두 모았을 때 고도리라고 합니다.
                    </li>
                    <li>
                        고도리를 만들 경우, 5점을 획득합니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_16.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">띠</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        띠가 5장 모이면 1점을 획득합니다.
                    </li>
                    <li>
                        이후 추가로 획득하는 띠 1장마다 1점씩 추가됩니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_17.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">홍단</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        1월, 2월, 3월의 띠를 모으면 홍단이 됩니다.
                    </li>
                    <li>
                        홍단을 만들 경우, 3점을 획득합니다.
                    </li>
                </ol>
            </div>

            <img src={require("../images/daulgo_guide_img/progress_18.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">초단</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                       4월, 5월, 7월의 띠를 모으면 초단이 됩니다.
                    </li>
                    <li>
                        초단을 만들 경우, 3점을 획득합니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_19.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">청단</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        6월, 9월, 10월의 띠를 모으면 청단이 됩니다.
                    </li>
                    <li>
                        청단을 만들 경우, 3점을 획득합니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_20.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">쌍피</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        11월, 12월에 존재하며, 쌍피 1매가 피 2매의 역할을 합니다.
                    </li>
                    <li>
                         9월 열끗을 플레이어의 선택에 따라 쌍피로 사용할 수 있습니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_21.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">국진 열끗</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        9월의 열끗 패를 의미합니다.
                    </li>
                    <li>
                        플레이어의 선택에 따라 피, 열끗으로 선택하여 사용할 수 있습니다.
                    </li>
                    <li>
                        국진 열끗을 잘 활용하여, 피박을 방지할 수 있습니다.
                    </li>
                </ol>
            </div>


            <img src={require("../images/daulgo_guide_img/progress_22.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">피</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        10장의 피가 모이면 1점을 획득합니다.
                    </li>

                    <li>
                        이후 추가로 획득하는 피 1장마다 1점씩 추가됩니다.
                    </li>
                    <li>
                        피로 점수가 났을 때, 상대 피가 1장 이상 ~ 8장 미만이면 피박이 됩니다.
                    </li>
                </ol>
            </div>

        </div>
    );
}


const Mission = () => {

    return (
        <div className="guide_right_content">
            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_img"></span>
                    매 게임당 주어진 미션을 수행하고 승리했을 경우 해당 미션의 점수(배수)를 획득하는 제도입니다.<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;미션을 수행하고 승리하지 못했을 경우에는 미션 배율을 적용받지 못합니다.
                </div>
            </div>


            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/progress_23.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">홀짝 미션</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>
                            베팅 순간마다 내고 뒤집은 패의 짝이 맞는 경우<br/>
                            곱하기 배당을 1씩 증가시킵니다.
                        </li>
                        <li>
                            최종 승자에게 곱하기 배당이 적용됩니다!
                        </li>
                    </ol>
                </div>
            </div>


            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/progress_24.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">대박 미션</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>
                            무작위 미션 패를 습득 시 점수가 2배 증가합니다.<br/>
                            곱하기 배당을 1씩 증가시킵니다.
                        </li>
                        <li>
                            모든 미션 패를 모을 경우 8배가 되며<br/>
                            최종 승자에게 곱하기 배당이 적용됩니다!
                        </li>
                    </ol>
                </div>
            </div>


            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/progress_25.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">2장 미션</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>
                            2장으로 이뤄진 미션 패를 모아보세요.
                        </li>
                        <li>
                            최종 승자에게 곱하기 배당이 적용됩니다!
                        </li>
                    </ol>
                </div>
            </div>


            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/progress_26.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">4장 미션</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>
                            4장으로 이뤄진 미션 패를 모아보세요.
                        </li>
                        <li>
                            최종 승자에게 곱하기 배당이 적용됩니다!
                        </li>
                    </ol>
                </div>
            </div>


            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/progress_27.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">족보 미션</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>
                            지정된 미션 족보를 만들어 보세요.
                        </li>
                        <li>
                            최종 승자에게 곱하기 배당이 적용됩니다!
                        </li>
                    </ol>
                </div>
            </div>


            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/progress_28.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">쌍피 미션</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>
                            지정된 미션 쌍피를 모아보세요!
                        </li>
                        <li>
                            최종 승자에게 곱하기 배당이 적용됩니다!
                        </li>
                    </ol>
                </div>
            </div>


            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/progress_29.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">돼지잡이 미션</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>
                            7월 열끗 패를 모으세요.
                        </li>
                        <li>
                            미션 달성자의 점수가 5점 증가합니다.
                        </li>
                    </ol>
                </div>
            </div>


            <div style={{display: "flex", gap: '20px', paddingBottom: '40px'}}>
                <img src={require("../images/daulgo_guide_img/progress_30.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">무조건 미션</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>
                            무조건 조건 없이! 이기기만 하세요.
                        </li>
                        <li>
                            최종 승자에게 곱하기 배당이 적용됩니다!
                        </li>
                    </ol>
                </div>
            </div>


        </div>
    );
}


const RankAndMoney = () => {
    const GradeBox = ({ title, range }) => (
      <div className="guide_grade_box">
        <div className="guide_grade_inner_box">{title}</div>
        <div className="guide_grade_inner_box_2">{range}</div>
      </div>
    );

    return (
        <div className="guide_right_content">
            <div className="guide_right_txt_content">
                <div>· SM게임즈에서 냥은 바둑이, 7포커, 신맞고에 공통으로 적용됩니다.</div>
                <div>· 보유하고 있는 냥에 따라 등급이 결정됩니다.</div>
                <div>· 게임에 참여하여 냥을 획득하거나 아바타 구입 후 냥을 획득하여도 등급이 올라갑니다.</div>
                <div>· 10,000전은 1냥의 가치를 지닙니다.</div>
                <div>· 실버채널에서는 전 단위로 표기됩니다.</div>
            </div>

            <div className="gradeBox" style={{ borderTop: '2px solid #603512' }}>
            <GradeBox title="초　보" range="0냥 ~ 999냥" />
            </div>
            <GradeBox title="하　수" range="1천냥 ~ 9999냥" />
            <GradeBox title="중　수" range="1만냥 ~ 9만9999냥" />
            <GradeBox title="고　수" range="10만냥 ~ 99만9999냥" />
            <GradeBox title="영　웅" range="100만냥 ~ 999만9999냥" />
            <GradeBox title="지　존" range="1천만냥 ~ 4999만9999냥" />
            <GradeBox title="초　인" range="5천만냥 ~ 9999만9999냥" />
            <GradeBox title="제　왕" range="1억냥 ~ 4억9999만9999냥" />
            <GradeBox title="도　신" range="5억냥 ~ 9억9999만9999냥" />
            <GradeBox title="신" range="10억냥 ~" />
            <br /><br />

        </div>
    );
}


const Channel = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/daulgo_guide_img/elements_01.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">무료 채널</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        등급에 제한 없이, 모든 등급이 이용할 수 있는 채널입니다.
                    </li>
                    <li>
                        매일 무료로 지급되는 알을 사용하여, 게임을 즐길 수 있습니다.
                    </li>
                    <li>
                        1회 베팅 한도가 적용되지 않습니다.
                    </li>
                </ol>
            </div>


            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">실버 채널</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        초보~초인 등급까지 접속이 가능한 채널입니다.
                    </li>
                    <li>
                        전용 단위인 전 을 이용해 게임을 즐기실 수 있습니다.
                    </li>
                    <li>
                        1만전 = 1냥의 가치를 지니며, 보유 냥이 전으로 자동으로 환산됩니다.
                    </li>
                </ol>
            </div>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">친구 채널</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        등급에 제한 없이, 모든 등급이 이용할 수 있는 채널입니다.
                    </li>
                    <li>
                        냥을 사용하며, 본인의 보유머니 이상으로 금액을 획득할 수 없습니다.
                    </li>
                </ol>
            </div>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">골드1 채널</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        지존,초인,제왕 등급만 접속이 가능한 채널입니다.
                    </li>
                    <li>
                        냥을 사용하며, 본인의 보유머니 이상으로 금액을 획득할 수 없습니다.
                    </li>
                </ol>
            </div>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">골드2 채널</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        도신,신 등급만 접속이 가능한 채널입니다.
                    </li>
                    <li>
                        냥을 사용하며, 본인의 보유머니 이상으로 금액을 획득할 수 없습니다.
                    </li>
                </ol>
            </div>


        </div>
    );
}

const Safe = () => {
  return (
      <div className="guide_right_content">
          <img src={require("../images/daulgo_guide_img/elements_02.png")}/>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_txt_img"></span>
                  <em className="guide_bullet_txt">입금</em>
              </div>

              <ol className="guide_additional_rules">
                  <li>
                      현재 본인이 보유한 머니(알, 냥)을 금고에 입금할 수 있습니다.
                  </li>
                  <li>
                      본인이 소지한 머니 이상 입금은 불가능합니다.
                  </li>
              </ol>
          </div>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_txt_img"></span>
                  <em className="guide_bullet_txt">출금</em>
              </div>

              <ol className="guide_additional_rules">
                  <li>
                      현재 본인이 금고에 입금한 머니(알, 냥)를 출금할 수 있습니다.
                  </li>
                  <li>
                      본인이 입금해둔 금액 이상의 출금은 불가능합니다.
                  </li>
              </ol>
          </div>

      </div>
  );
}

const FreeRecharge = () => {
  return (
      <div className="guide_right_content">
          <img src={require("../images/daulgo_guide_img/elements_03.png")}/>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_img"></span>
                  머니가 올인되어도 걱정하지 마세요! 무료 머니를 제공해드립니다.
              </div>
          </div>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_txt_img"></span>
                  <em className="guide_bullet_txt">냥 무료충전</em>
              </div>

              <ol className="guide_additional_rules">
                  <li>
                      냥 무료충전은 금고 보유금을 포함하고 보유한 냥이 500냥 이하일 때 충전할 수 있습니다.
                  </li>
                  <li>
                      1회 5,000 냥만큼 충전되며, 1일 5회만큼 제공됩니다.
                  </li>
              </ol>
          </div>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_txt_img"></span>
                  <em className="guide_bullet_txt">알 무료충전</em>
              </div>

              <ol className="guide_additional_rules">
                  <li>
                      알 무료충전은 금고 보유금을 포함하고 보유한 알이 100만알 이하일 때 충전할 수 있습니다.
                  </li>
                  <li>
                      1회 300만알 충전되며, 1일 5회만큼 제공됩니다.
                  </li>
              </ol>
          </div>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_img"></span>
                  무료충전은 24시간 기준으로 매일 오전 0시에 갱신됩니다.
              </div>
          </div>

      </div>
  );
}


const DalerFee = () => {
    return (
        <div className="guide_right_content">
            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_img"></span>
                    딜러비는 일종의 게임 이용료를 의미합니다.
                </div>

                <div>
                    <span className="guide_bullet_img"></span>
                    SM신맞고 딜러비는 승리 플레이어가 획득하는 금액의 8% 만큼을 공제합니다.
                </div>
            </div>
        </div>
    );
}


const BettingRules = () =>{
    return (
        <div className="guide_right_content">
            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/etc_01.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">1회 베팅 한도</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>플레이어간 1회 베팅 한도는<br/>
                            현금 7만원(49억냥) 상당의 금액을<br/>
                            초과하여 베팅할 수 없습니다.
                        </li>
                        <li>
                            1회 베팅한도는 상시 적용됩니다.
                        </li>
                    </ol>
                </div>
            </div>

            <div style={{display: "flex", gap: '20px', paddingBottom: '40px'}}>
                <img src={require("../images/daulgo_guide_img/etc_02.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">머니 획득</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>플레이어는, 본인의 보유머니보다<br/>
                            많은 머니를 획득할 수 없습니다.
                        </li>
                    </ol>
                </div>
            </div>

        </div>
    );
}

const LimitRegulation = () => {
    return (
        <div className="guide_right_content">
            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/etc_04.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">결제 한도</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>플레이어의 결제 한도는 명의 기준<br/>
                            월 70만원을 초과할 수 없습니다.
                        </li>
                        <li>
                            월별 결제 한도는, 매월 1일에 초기화 됩니다.
                        </li>
                        <li>
                            게임물산업진흥에 관한 법률에 따라 사용자가<br/>
                            직접 고스톱/포커류 손실 머니 금액을 설정할 수<br/>
                            있습니다.
                        </li>
                    </ol>
                </div>
            </div>

        </div>
    );
}



const GuideDualgo = () => {
    const [tabs, setTabs] = useState([true, false, false, false, false]);
    const [content, setContent] = useState([true, false, false, false, false]);

    const handelMenuButtonClick = (event, tab_index) => {
        const newTabs = [false, false, false, false, false];
        newTabs[tab_index] = !tabs[tab_index];
        setTabs(newTabs);

        switch (tab_index){
            case 0:
                setContent(InstallAndRun);
                break;
            case 1:
                setContent(CardComposition);
                break;
            case 2:
                setContent(CalculateScore);
                break;
            case 3:
                setContent(RankAndMoney);
                break;
            case 4:
                setContent(BettingRules);
                break;
        }

        $('.guide_left_menu_inner_tab .active').removeClass('active');
        $('#tab_'+tab_index).addClass('active');
        $('.guide_inner_tab span').remove('span');
        $('#tab_'+tab_index).append('<span class="guide_left_arrow"></span>');
    };

    const changeContent = (event, pageName) => {
        $('.guide_left_menu_inner_tab .active').removeClass('active');
        $(event.target).addClass('active');
        setContent(pageName);

        $('.guide_inner_tab span').remove('span');
        $(event.currentTarget).append('<span class="guide_left_arrow"></span>');
    };

    useEffect(() => {
        if(!localStorage.getItem('isMobile')){
                $('body').css({
                'transform': 'unset',
                'min-width': 'unset',
                'background': 'white',
                'zoom': 'unset',

            });
        } else {
            $('body').css({
                'background': 'white',
            });
        }

        setContent(<InstallAndRun/>);
    }, []);

    return (
        <div className="guide_wrap">
            <div className="guide_left_wrap" style={{backgroundColor: '#004701'}}>
                <div className="guide_left_logo_box">
                    <div style={{fontSize: '21.5px', color: '#307132'}}>SM신맞고</div>
                    <div style={{fontSize: '24.34px', color: '#ffffff'}}>게임방법</div>
                </div>

                <div>
                    <div className="guide_left_menu_button">
                        <div className="guide_left_menu_button_inner"
                             onClick={event => handelMenuButtonClick(event, 0)}>
                            <div style={{color: tabs[0] ? "#fff": "#559856"}}>게임시작</div>
                            <div id="icon_tab_1"
                                 className={tabs[0] ? "guide_left_button_img_open_tab" : "guide_left_button_img_close_tab"}/>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#003a01'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#015702'}}/>
                    </div>

                    <div className="guide_left_menu_inner_button"
                         style={{display: tabs[0] ? 'block' : 'none', height: '145px'}}>

                        <div className="guide_left_menu_inner">
                            <div className="guide_left_menu_inner_tab">
                                <div id="tab_0" className="guide_inner_tab active"
                                     onClick={event => changeContent(event, InstallAndRun)}>설치 및 실행
                                    <span className="guide_left_arrow"></span>
                                </div>

                                <div className="guide_inner_tab"
                                     onClick={event => changeContent(event, MakingRoom)}>방만들기
                                </div>

                                <div className="guide_inner_tab"
                                     onClick={event => changeContent(event, DirectEntry)}>바로입장
                                </div>
                                <div className="guide_inner_tab"
                                     onClick={event => changeContent(event, UseGameRoom)}>게임방 사용법
                                </div>
                            </div>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#003a01'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#015702'}}/>

                    </div>
                </div>


                <div>
                    <div className="guide_left_menu_button">
                        <div className="guide_left_menu_button_inner"
                             onClick={event => handelMenuButtonClick(event, 1)}>
                            <div style={{color: tabs[1] ? "#fff": "#559856"}}>초보자가이드</div>
                            <div id="icon_tab_1"
                                 className={tabs[1] ? "guide_left_button_img_open_tab" : "guide_left_button_img_close_tab"}/>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#003a01'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#015702'}}/>
                    </div>

                    <div className="guide_left_menu_inner_button"
                         style={{display: tabs[1] ? 'block' : 'none', height: '108px'}}>
                        <div className="guide_left_menu_inner">
                            <div className="guide_left_menu_inner_tab">
                                <div id="tab_1" className="guide_inner_tab"
                                     onClick={event => changeContent(event, CardComposition)}>카드의 구성
                                </div>
                                <div className="guide_inner_tab"
                                     onClick={event => changeContent(event, HowToPlay)}>게임방법
                                </div>
                                <div className="guide_inner_tab"
                                     onClick={event => changeContent(event, AdditionalRules)}>추가룰
                                </div>
                            </div>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#003a01'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#015702'}}/>

                    </div>
                </div>


                <div>
                    <div className="guide_left_menu_button">
                        <div className="guide_left_menu_button_inner"
                             onClick={event => handelMenuButtonClick(event, 2)}>
                            <div style={{color: tabs[2] ? "#fff" : "#559856"}}>게임진행</div>
                            <div id="icon_tab_1"
                                 className={tabs[2] ? "guide_left_button_img_open_tab" : "guide_left_button_img_close_tab"}/>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#003a01'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#015702'}}/>
                    </div>

                    <div className="guide_left_menu_inner_button"
                         style={{display: tabs[2] ? 'block' : 'none', height: '108px'}}>
                        <div className="guide_left_menu_inner">
                            <div className="guide_left_menu_inner_tab">
                                <div id="tab_2" className="guide_inner_tab"
                                     onClick={event => changeContent(event, CalculateScore)}>점수계산법
                                </div>
                                <div className="guide_inner_tab" onClick={event => changeContent(event, Pedigree)}>족보
                                </div>
                                <div className="guide_inner_tab" onClick={event => changeContent(event, Mission)}>미션
                                </div>
                            </div>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#003a01'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#015702'}}/>

                    </div>
                </div>


                <div>
                    <div className="guide_left_menu_button">

                        <div className="guide_left_menu_button_inner"
                             onClick={event => handelMenuButtonClick(event, 3)}>
                            <div style={{color: tabs[3] ? "#fff" : "#559856"}}>게임요소</div>
                            <div id="icon_tab_1"
                                 className={tabs[3] ? "guide_left_button_img_open_tab" : "guide_left_button_img_close_tab"}/>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#003a01'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#015702'}}/>
                    </div>

                    <div className="guide_left_menu_inner_button"
                         style={{display: tabs[3] ? 'block' : 'none', height: '180px'}}>
                        <div className="guide_left_menu_inner">
                            <div className="guide_left_menu_inner_tab">
                                <div id="tab_3" className="guide_inner_tab" onClick={event => changeContent(event, RankAndMoney)}>등급과 머니</div>
                                <div className="guide_inner_tab" onClick={event => changeContent(event, Channel)}>채널</div>
                                <div className="guide_inner_tab" onClick={event => changeContent(event, Safe)}>금고</div>
                                <div className="guide_inner_tab" onClick={event => changeContent(event, FreeRecharge)}>무료충전</div>
                                <div className="guide_inner_tab" onClick={event => changeContent(event, DalerFee)}>딜러비</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <div className="guide_left_menu_button">
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#003a01'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#015702'}}/>

                        <div className="guide_left_menu_button_inner"
                             onClick={event => handelMenuButtonClick(event, 4)}>
                            <div style={{color: tabs[4] ? "#fff" : "#559856"}}>기타</div>
                            <div id="icon_tab_1"
                                 className={tabs[4] ? "guide_left_button_img_open_tab" : "guide_left_button_img_close_tab"}/>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#003a01'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#015702'}}/>
                    </div>

                    <div className="guide_left_menu_inner_button" style={{display: tabs[4] ? 'block' : 'none'}}>
                        <div className="guide_left_menu_inner">
                            <div className="guide_left_menu_inner_tab">
                                <div id="tab_4" className="guide_inner_tab active" onClick={event => changeContent(event, BettingRules)}>베팅 규정</div>
                                <div className="guide_inner_tab" onClick={event => changeContent(event, LimitRegulation)}>한도 규정</div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className="guide_right_wrap">
                {content}
            </div>
        </div>
    );
};

export default GuideDualgo;