import React, {useState} from 'react';
import "../css/serviceCenter.css";
import "../css/myPage.css";
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";
import $ from 'jquery';

const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [conformPassword, setConformPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const navigate = useNavigate();

    const handleOldPasswordChange = (e) => {
        const inputVal = e.target.value;
        const filteredVal = inputVal.replace(/[ \ㄱ-ㅎㅏ-ㅣ|가-힣 ]/gi, '');
        setOldPassword(filteredVal);
    };


    const handlePasswordChange = (e) => {
        const inputVal = e.target.value;
        const filteredVal = inputVal.replace(/[ \ㄱ-ㅎㅏ-ㅣ|가-힣 ]/gi, '');
        setPassword(filteredVal);
        if (conformPassword) {
             $('#conform_password_msg').html("<span style='color: #cd642b;'>비밀번호와 비밀번호 확인이 서로 다릅니다.</span>");
        }
    };

    const handleConformPasswordChange = (e) => {
        const inputVal = e.target.value;
        const filteredVal = inputVal.replace(/[ \ㄱ-ㅎㅏ-ㅣ|가-힣 ]/gi, '');

        if(!password){
            alert("변경 비밀번호를 입력해주세요.");
            return;
        } else {
            setConformPassword(filteredVal);

            if(password === filteredVal) {
                $('#conform_password_msg').html("<span style='color: #979797;'>일치 합니다.</span>");
            } else {
                $('#conform_password_msg').html("<span style='color: #cd642b;'>비밀번호와 비밀번호 확인이 서로 다릅니다.</span>");
            }
        }
    };

    const handlePasswordChangeBlur = (e) => {
        const inputVal = e.target.value;
        const pattern = /^[A-Za-z0-9~!@#$%^*()_+]{6,15}$/;

        if (!pattern.test(inputVal)) {
          alert('비밀번호는 최소 6글자, 최대 15글자의 영문 + 숫자 + 특문만\n입력가능합니다.');
        } else {
          setPassword(inputVal);
        }
      };


    const submitChangePassword = async () => {
        if(!oldPassword){
            return alert('현재 비밀번호를 입력해주세요.');
        } else if(!password){
            return alert('변경 비밀번호를 입력해주세요.');
        } else if(password.length < 6){
            return alert('비밀번호는 최소 6글자, 최대 15글자의 영문 + 숫자 + 특문만\n입력가능합니다.');
        }
        else if(!conformPassword){
            return alert('비밀번호 확인을 입력해 주세요.');
        } else if (password !== conformPassword){
            return alert('비밀번호와 비밀번호 확인이 서로 다릅니다.');
        }

        const data = {
            password: password,
            conform_password: conformPassword,
            old_password: oldPassword
        };

        var response;

        try {
            response = await axios.post(baseUrl + 'my_page/forgot_password/', data,{
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            });
            if (response.status === 200) {
                sessionStorage.removeItem('token');
                navigate('/login');
            }
        } catch (error) {
            if (error.response) {
                if(error.response.status === 400) {
                    const error_msg = error.response.data.error;
                    alert(error_msg);
                    setOldPassword('');
                    setPassword('');
                    setConformPassword('');
                }
            } else {
                setOldPassword('');
                setPassword('');
                setConformPassword('');
                alert("Internal Server Error");
            }
        }
    };

    // function myPage() {
    //     navigate('/my_page');
    //     window.location.reload();
    // }
    //
    const myPage = () => {
        navigate('/my_page');
    };

    return (
        <>
            <div className="subMaintitleBox">
                <div className="subMainGrayBox"/>
                <div className="subMaintitle">비밀번호 변경</div>

                <div className="subMainDepth">내정보 > 비밀번호 변경</div>
            </div>

            <div className="my_page_contents_box">
                <div className="my_page_contents">
                    <div className="my_contents_inner_left_box">현재 비밀번호</div>

                    <div className="my_password_box">
                        <div className="my_password_input_box">
                            <input
                                type="password"
                                name="nowpw"
                                maxLength="15"
                                value={oldPassword}
                                onChange={handleOldPasswordChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="my_page_contents">
                    <div className="my_contents_inner_left_box">변경 비밀번호</div>

                    <div className="my_password_box">
                        <div className="my_password_input_box">
                            <input
                                type="password"
                                name="nowpw"
                                maxLength="15"
                                value={password}
                                placeholder="최소 6글자 ~ 최대 15글자"
                                onChange={handlePasswordChange}
                                onBlur={handlePasswordChangeBlur}
                            />
                        </div>
                    </div>

                    <div className="my_password_explanation">
                        - 6글자 ~ 15글자의 영문자, 숫자, 특수문자 <br/>
                        - 한글, 알기쉬운 비밀번호 금지
                    </div>

                </div>

                <div className="my_page_contents"  style={{height: '100px'}}>
                    <div className="my_contents_inner_left_box">변경 비밀번호 확인</div>

                    <div className="my_password_box">
                        <div className="my_password_input_box">
                            <input
                                type="password"
                                name="nowpw"
                                maxLength="15"
                                value={conformPassword}
                                onChange={handleConformPasswordChange}
                            />
                        </div>
                    </div>

                    <div
                        id="conform_password_msg"
                        className="result_msg"
                        style={{position: 'absolute', width: '500px', fontSize: '16px'}}
                    >

                    </div>

                </div>
            </div>


            <div className="ok_no_box_button">
                <button className="ok_no_button" onClick={submitChangePassword}>
                    확인
                    <div className="man_ok_no_button_img">
                        <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                    </div>
                </button>
                <button
                    className="ok_no_button"
                    onClick={myPage}
                    style={{backgroundColor: '#4e4e4e', marginLeft: '5px'}}
                >
                    취소
                    <div className="man_ok_no_button_img">
                        <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                    </div>
                </button>
            </div>

        </>
    );
};

export default ChangePassword;
