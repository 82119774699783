import React, {useEffect, useState} from 'react';
import close_btn from "../images/Icon-closebutton.png";
import icon_mobile from "../images/membership_img/Icon-mobile.jpg";
import icon_button from "../images/mypage_img/Icon-button.png";

import {useLocation, useNavigate} from 'react-router-dom';
import BaseUrl from "../BaseUrl";
import $ from 'jquery';
import MobileHeader from "./mHeader";
import MobileFooter from "./mFooter";
import axios from "axios";

function MobileLogin(props) {
    const navigate = useNavigate();
    const location = useLocation();
    var redirect;

    try {
        redirect = location.state.redirect;
    } catch (e) {

    }

    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [showIdPopup, setShowIdPopup] = useState(false);

    const [randNum, setRandNum] = useState(1);

    const handleIdChange = (e) => {
        const trimmedValue = e.target.value.replace(/\s/g, '');
        setId(trimmedValue);
    };
    const handlePwChange = (e) => {
        const trimmedValue = e.target.value.replace(/\s/g, '');
        setPw(trimmedValue);
    };

    const handleMobileVerify = (page) => {
        setRandNum(randNum + 1);
        setShowIdPopup(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!id){
            return alert('아이디를 입력해 주세요.');
        } else if(!pw){
            return alert('비밀번호를 입력해 주세요.');
        }

        const formData = new FormData(e.target);
        const data = {};

        formData.forEach((value, key) => {
            data[key] = value;
        });

        var inputs = document.querySelectorAll('.input_form input');
        inputs.forEach(function (input) {
            input.value = '';
        });

        try {
            const response = await axios.post(BaseUrl + 'login/', data);

            const responseData = response.data;

            // If status code is 200, print success
            if (response.status === 200) {
                localStorage.setItem('token', responseData.access);
                sessionStorage.setItem('token', responseData.access);
                localStorage.setItem('is_user_restricted', responseData.is_user_restricted);
                localStorage.setItem('restricted_date_upto', responseData.restricted_date_upto);
                localStorage.setItem('gender', responseData.gender);

                if(redirect){
                    window.location.href = redirect;
                } else {
                    window.location.href = "/mobile";
                }

            }
            else if (response.status === 203) {
                window.location.href = '/mobile/membership_verify?user_id=' + responseData.user_id;
            }
            else {
                alert("아이디와 비밀번호를 확인해주세요.");
            }
        } catch (error) {
            alert("아이디와 비밀번호를 확인해주세요.");
        }

    };

    const handleClose = () => {
        setShowIdPopup(false);
    };


    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'zoom': 'unset'
        });

    }, []);

    return (
        <>
            <MobileHeader/>

            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>로그인</span>
                </div>

                <div id="contents">
                    <div className="m_center_warp">
                        {/* ID Popup */}
                        {showIdPopup && (
                            <div className="Findid_popup" id="idpopup" style={{display: 'block', zIndex: 2}}>
                                <div className="Findid_titleBox">
                                    <em>아이디 찾기</em>
                                    <button onClick={handleClose}
                                            style={{position: 'absolute', margin: '6px 0 0 134px'}}>
                                        <img src={close_btn} alt="Close"/>
                                    </button>
                                </div>
                                <div className="Findid_Box">
                                    <div className="Findid_Inner">
                                        <div className="Findid_mobileimg">
                                            <img src={icon_mobile} alt="Mobile"/>
                                        </div>
                                        <div className="Findid_Title" id="find_id_text_1"></div>
                                        <div className="Findid_Contents" id="find_id_text_2"></div>
                                    </div>
                                </div>
                                <div className="oknoBox_button Findid">
                                    <button id="find_id_ok_btn" className="okno_button"
                                            onClick={event => handleMobileVerify('findID')}>
                                        다음
                                        <div className="okno_button_img"><img src={icon_button} alt="Next"/></div>
                                    </button>
                                    <button className="okno_button"
                                            onClick={handleClose}
                                            style={{backgroundColor: '#4e4e4e', marginLeft: '3px'}}>
                                        취소
                                        <div className="okno_button_img"><img src={icon_button} alt="Cancel"/></div>
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Membership Box */}
                        <div className="m_membership_box" style={{backgroundColor: '#fff'}}>
                            <div className="m_login login_common_ly" id="login_common_ly">
                                <h2 className="blind">로그인</h2>
                                <div className="login_setd lg_iptxt">
                                    {/* Social Media Login */}
                                </div>
                                <form method="post" name="login_enterform" onSubmit={handleSubmit}>
                                    <div className="m_login_form idpw_area">
                                        <div id="login_box">
                                            <div className="input_form">
                                                <div id="inputId" className="m_login_form_itemp input_id">
                                                    <input
                                                        type="text"
                                                        name="user_id"
                                                        id="id"
                                                        title="아이디"
                                                        className="txt_inp"
                                                        placeholder="아이디"
                                                        maxLength="11"
                                                        value={id}
                                                        onChange={handleIdChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div id="inputPassword" className="m_login_form_itemp input_pw">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        id="pw"
                                                        title="비밀번호"
                                                        className="txt_inp"
                                                        placeholder="비밀번호"
                                                        maxLength="15"
                                                        value={pw}
                                                        onChange={handlePwChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button id="btnLogin" type="submit" title="로그인"
                                                className="m_login_btnd is_disabled lg_input_btn"
                                                style={{cursor: 'pointer'}}>
                                            <span className="login_btn_txt">로그인</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="m_login_help lg_member_con">
                                    <span className="mem_id_search">
                                        <button className="login_help_link" onClick={() => navigate('/mobile/find_id')}>아이디 찾기</button>
                                    </span>
                                <span className="m_login_help_bar"></span>

                                <span className="mem_pw_search">
                                        <button className="login_help_link"
                                                onClick={() => navigate('/mobile/find_password')}>비밀번호 찾기</button>
                                    </span>
                                <span className="m_login_help_bar"></span>

                                <span className="mem_join">
                                      <button className="login_help_link"
                                              onClick={() => navigate('/mobile/membership')}>회원가입</button>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MobileFooter/>
        </>
    );
}

export default MobileLogin;
