import React, {useEffect, useState} from "react";
import "../css/otherGame.css";

import gostop from '../images/gostop_img/m_gostop_P01.jpg';
import game_info from '../images/battlesniper_img/m_battlesniper_info1.png';

import sudda from '../images/sudda_img/m_sudda_P02.jpg';

import darkworld from '../images/darkworld_img/m_darkworld_P01.jpg';

import battlesniper from '../images/battlesniper_img/m_battlesniper_P01.jpg';
import battlesniper_character from "../images/battlesniper_img/battlesniper_character.png";

import probaseball from '../images/probaseball_img/m_probaseball_P01.jpg';

import baduk from '../images/baduk_img/m_baduk_P01.jpg';

import janggi from '../images/janggi_img/m_janggi_P01.jpg';

import matching from '../images/matching_img/m_matching_P03.png';

import {useLocation} from "react-router-dom";
import $ from "jquery";
import UpcomingGamesPointTable from "./mUpcomingGamesPointTable";
import MobileHeader from "./mHeader";
import MobileFooter from "./mFooter";


const GoStopDescription = () => {
    return (
        <div>
            SM 고~스톱은 PC, Mobile 어디서나 즐겁게 여러 플레이어들과 함께 <br/>고스톱을 즐길 수 있는 게임입니다.<br/>
            <em style={{color: '#ffffff'}}>짝짝 붙는 패의 손 맛과, 시선을 사로잡는 이펙트</em>로 오늘도 즐거움 Up! Up!
        </div>
    );
};


const SuddoDescription = () => {
    return (
        <div>
            SM 섯다는 16:9 해상도를 지원하는 <em style={{color: '#ffffff'}}>고퀄리티</em> 섯다 게임입니다.<br/>
            어느 플랫폼에서나 쉽고 간편하게 즐길 수 있으며,<br/>
            짜릿한 족보 완성의 재미와 긴장감 넘치는 <br/>
            <em style={{color: '#ffffff'}}>베팅 심리전</em>의 재미를 느끼실 수 있습니다.
        </div>
    );
};


const DarkworldDescription = () =>
{
    return (
        <div className="ExplainText">
            Dark World는 <em style={{color: '#ffffff'}}>온갖 종족이 존재하는 세계</em>에서 <br/>
            세력의 패권을 두고 <br/>
            <em style={{color: '#ffffff'}}>전쟁</em>을 벌이는 MMORPG입니다.<br/>
        </div>
    );
}
;


const BattlesniperDescription = () =>
{
    return (
        <div>
            Battle Sniper는 <em style={{color: '#ffffff'}}>극한의 생존 전략</em>을<br/>
            토대로 즐기는 FPS 게임입니다.<br/>
            주변의 물건을 이용해 자신을 보호하고 적을 제거하여 <br/>
            <em style={{color: '#ffffff'}}>최후의 1인</em>이 되세요!
        </div>
    );
}
;

const ProbaseballDescription = () =>
{
    return (
        <div>
            SM 프로 베이스볼은 PC에서 경기를 진행하거나<br/>
            Mobile에서 간소화된 경기를 확인하는 등의 <br/>
            상호 보완적인 관계를 통해 끊임없이 구단을<br/>
            운영하며, <em style={{color: '#ffffff'}}>선수들을 육성해 나만의 구단</em>을
            만들 수 있습니다
        </div>
    );
}
;

const BadukDescription = () =>
{
    return (
        <div>
            SM 바둑은 <em style={{color: '#ffffff'}}>Cross-Platform 기술</em>을 적용하여 <br/>
            언제, 어디서나 쾌적한 환경에서 <br/>
            즐길 수 있는 바둑 게임입니다.<br/>
        </div>
    );
};

const JanggiDescription = () =>
{
    return (
        <div className="ExplainText">
            SM 장기는 환경에 관계없이 게임을 즐길 수 있는<br/>
            <em style={{color: '#ffffff'}}> Cross-Platform 기능</em>이 구현되어 있는 장기 게임입니다.<br/>
            <em style={{color: '#ffffff'}}>전통적이면서도 클래식한 디자인</em>과 룰 이러한 클래식에 개인의 감성을 <br/>뿜뿜! 할 수 있는 캐주얼함까지 준비되어 있습니다.<br/>
        </div>
    );
};

const MatchingDescription = () =>
{
    return (
        <div className="ExplainText">
            SM matching은 떨어지는 그림들의 <em style={{color: '#ffffff'}}>짝을 맞추는</em> 게임이며,<br/>
            타이밍에 맞게 본인이 <em style={{color: '#ffffff'}}>버튼을 눌러</em><br/>
            그림을 맞출 수 있습니다.<br/>
        </div>
    );
};


function MobileUpcomingGames(props) {
    const params = new URLSearchParams(window.location.search);
    var gameName = params.get('gameName');

    const [game, setGame] = useState('');
    const [gameInfoBanner, setGameInfoBanner] = useState('');

    const gamesList = {
        game_gostop: {gameBanner: gostop, gameDescription: GoStopDescription(), well: "고스톱 류", release_date: "2025년 출시 예정", usageLevel: "청소년 이용불가"},
        game_sudda: {gameBanner: sudda, gameDescription: SuddoDescription(), well: "고스톱 류", release_date: "2025년 출시 예정", usageLevel: "청소년 이용불가"},
        game_darkworld: {gameBanner: darkworld, gameDescription: DarkworldDescription(), well: "MMORPG", release_date: "출시일 미정", usageLevel: "청소년 이용불가"},
        game_battlesniper: {gameBanner: battlesniper, gameDescription: BattlesniperDescription(), well: "FPS", release_date: "출시일 미정", usageLevel: "15세 이용가"},
        game_probaseball: {gameBanner: probaseball, gameDescription: ProbaseballDescription(), well: "스포츠 게임", release_date: "2027년 출시 예정", usageLevel: "전체 이용가"},
        game_baduk: {gameBanner: baduk, gameDescription: BadukDescription(), well: "보드게임", release_date: "2026년 출시 예정", usageLevel: "전체 이용가"},
        game_janggi: {gameBanner: janggi, gameDescription: JanggiDescription(), well: "보드게임", release_date: "2026년 출시 예정", usageLevel: "전체 이용가"},
        game_matching: {gameBanner: matching, gameDescription:MatchingDescription(), well: "보드게임", release_date: "2026년 출시 예정", usageLevel: "청소년 이용불가" },
    };


    useEffect(() => {
        setGame(gamesList[gameName]);
        setGameInfoBanner(game_info);

        if (gameName === 'game_battlesniper'){
            $(".center-warp-img-other-game").css('display', 'block');
        }


    }, []);

    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'zoom': 'unset'
        });
    }, []);

    return (
        <>
            <MobileHeader/>

            <div className="m_top_game_section">
                <div className="m_other-game-section" style={{backgroundImage: `url(${game.gameBanner})`}}/>
            </div>

            <div id="contents" style={{backgroundColor: '#4e4e4e'}}>

                <div className="m_other-game-info-area">
                    <div className="center-warp-other-game">
                        <div className="m_title-text-other-game">
                            <em>게임 정보</em>
                        </div>
                        <div className="m_info_img_box">
                            <img src={gameInfoBanner} alt="Game Info"/>
                        </div>
                        <div style={{textAlign: "center", paddingTop: "20px", fontFamily: "ap"}}>
                            <div className="m_info-text-other-game">에스디소프트</div>
                            <div className="m_info-text-other-game">에스엠게임즈</div>
                            <div className="m_info-text-other-game">{game['well']}</div>
                        </div>

                        <div className="m_info_img_box">
                            <img style={{width: "56.2500vw", height: "24.2188vw"}} src={require('../images/battlesniper_img/m_battlesniper_info2.png')} alt="Game Info"/>
                        </div>
                        <div style={{textAlign: "center", paddingTop: "20px", fontFamily: "ap"}}>
                            <div className="m_info-text-other-game">{game['release_date']}</div>
                            <div className="m_info-text-other-game">{game['usageLevel']}</div>
                        </div>
                    </div>
                </div>


                <div className="explain-area-other-game" style={{height: '67.8125vw'}}>
                    <div className="center-warp-other-game">
                        <div className="m_title-text-other-game">
                            <em>게임 설명</em>
                        </div>
                        <div className="m_explain-text-other-game">
                            {game['gameDescription']}
                        </div>
                    </div>
                </div>

                <div className="PointArea" style={{height: "auto", paddingBottom: '10vw'}}>
                    <div className="center-warp-other-game">
                        <div className="m_title-text-other-game">
                            <em>게임 포인트</em>
                        </div>

                        <UpcomingGamesPointTable gameName={gameName}/>
                    </div>


                </div>
            </div>

            <MobileFooter/>
        </>
    );
}

export default MobileUpcomingGames;