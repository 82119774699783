import React, {useEffect, useState} from 'react';
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";

const MobileTesting2 = () => {
    const navigate = useNavigate();

    useEffect(() => {
    const intervalId = setInterval(() => {
      const redirect = localStorage.getItem('redirect');
      if (redirect) {
          navigate(redirect);
      }
    }, 3000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

    useEffect(() => {
        localStorage.removeItem('redirect');
        console.log(window.location.hostname);
         window.open('/mobile_testing', 'KMCISWindow', 'width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250');
    }, []);

    return ;
};

export default MobileTesting2;
