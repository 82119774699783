import React, {useEffect} from "react";
import $ from 'jquery';
import AuthOperating from "../authentication/operatingPolicy";

const ServiceCenterOperatingPolicy = () => {

    useEffect(() => {
        $('.policy2').css('width', '840px');
    }, []);
  return (
      <div className="policy_center_warp">
          <div style={{width: '1000px', background: "rgb(255, 255, 255, 1)"}}>
              <AuthOperating/>
          </div>
      </div>

  )
};


export default ServiceCenterOperatingPolicy;