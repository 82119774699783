import React, {useEffect, useState} from "react";
import "../css/otherGame.css";
import SubHeader from "../home/subHeader";
import SubFooter from "../home/subFooter";

import gostop from '../images/gostop_img/gostop_P01.jpg';
import gostop_title from '../images/gostop_img/gostop_F01.jpg';
import game_info from '../images/battlesniper_img/battlesniper_info.png';

import sudda from '../images/sudda_img/sudda_P02.jpg';
import sudda_title from '../images/sudda_img/sudda_F01.jpg';

import darkworld from '../images/darkworld_img/darkworld_P01.jpg';
import darkworld_title from '../images/darkworld_img/darkworld_F01.jpg';

import battlesniper from '../images/battlesniper_img/battlesniper_P01.jpg';
import battlesniper_title from '../images/battlesniper_img/battlesniper_F01.jpg';
import battlesniper_character from "../images/battlesniper_img/battlesniper_character.png";

import probaseball from '../images/probaseball_img/probaseball_P01.jpg';
import probaseball_title from '../images/probaseball_img/probaseball_F01.jpg';

import baduk from '../images/baduk_img/baduk_P01.jpg';
import baduk_title from '../images/baduk_img/baduk_F01.jpg';

import janggi from '../images/janggi_img/janggi_P01.jpg';
import janggi_title from '../images/janggi_img/janggi_F01.jpg';

import matching from '../images/matching_img/matching_P03.png';
import matching_title from '../images/matching_img/matching_F02.jpg';

import {useLocation} from "react-router-dom";
import $ from "jquery";
import UpcomingGamesPointTable from "./upcomingGamesPointTable";


const GoStopDescription = () => {
    return (
        <div>
            SM 고~스톱은 PC, Mobile 어디서나 즐겁게 여러 플레이어들과 함께 고스톱을 즐길 수 있는 게임입니다.<br/>
            <em style={{color: '#ffffff'}}>짝짝 붙는 패의 손 맛과, 시선을 사로잡는 이펙트</em>로 오늘도 즐거움 Up! Up!
        </div>
    );
};


const SuddoDescription = () => {
    return (
        <div>
            SM 섯다는 16:9 해상도를 지원하는 <em style={{color: '#ffffff'}}>고퀄리티</em> 섯다 게임입니다.<br/>
            어느 플랫폼에서나 쉽고 간편하게 즐길 수 있으며,<br/>
            짜릿한 족보 완성의 재미와 긴장감 넘치는 <em style={{color: '#ffffff'}}>베팅 심리전</em>의 재미를 느끼실 수 있습니다.
        </div>
    );
};


const DarkworldDescription = () =>
{
    return (
        <div className="ExplainText">
            Dark World는 <em style={{color: '#ffffff'}}>온갖 종족이 존재하는 세계</em>에서 세력의 패권을 두고 <em
            style={{color: '#ffffff'}}>전쟁</em>을 벌이는 MMORPG입니다.<br/>
        </div>
    );
}
;


const BattlesniperDescription = () =>
{
    return (
        <div>
            Battle Sniper는 <em style={{color: '#ffffff'}}>극한의 생존 전략</em>을 토대로 즐기는 FPS 게임입니다.<br/>
            주변의 물건을 이용해 자신을 보호하고 적을 제거하여 <br/>
            <em style={{color: '#ffffff'}}>최후의 1인</em>이 되세요!
        </div>
    );
}
;

const ProbaseballDescription = () =>
{
    return (
        <div>
            SM 프로 베이스볼은 PC에서 경기를 진행하거나
            Mobile에서 간소화된 경기를 확인하는 등의 <br/>
            상호 보완적인 관계를 통해 끊임없이 구단을
            운영하며, <em style={{color: '#ffffff'}}>선수들을 육성해 나만의 구단</em>을
            만들 수 있습니다
        </div>
    );
}
;

const BadukDescription = () =>
{
    return (
        <div>
            SM 바둑은 <em style={{color: '#ffffff'}}>Cross-Platform 기술</em>을 적용하여 언제, 어디서나 <em style={{color: '#ffffff'}}>쾌적한
            환경</em>에서 즐길 수 있는 바둑 게임입니다.<br/>
        </div>
    );
};

const JanggiDescription = () =>
{
    return (
        <div className="ExplainText">
            SM 장기는 환경에 관계없이 게임을 즐길 수 있는
            <em style={{color: '#ffffff'}}> Cross-Platform 기능</em>이 구현되어 있는 장기 게임입니다.<br/>
            <em style={{color: '#ffffff'}}>전통적이면서도 클래식한 디자인</em>과 룰 이러한 클래식에 개인의 감성을 뿜뿜! 할 수 있는 캐주얼함까지 준비되어 있습니다.<br/>
        </div>
    );
};

const MatchingDescription = () =>
{
    return (
        <div className="ExplainText">
            SM matching은 떨어지는 그림들의 <em style={{color: '#ffffff'}}>짝을 맞추는</em> 게임이며,<br/>
            타이밍에 맞게 본인이 <em style={{color: '#ffffff'}}>버튼을 눌러</em><br/>
            그림을 맞출 수 있습니다.<br/>
        </div>
    );
};


function UpcomingGames(props) {
    const params = new URLSearchParams(window.location.search);
    var gameName = params.get('gameName');


    const [game, setGame] = useState('');
    const [gameInfoBanner, setGameInfoBanner] = useState('');
    const [gameCharacterBanner, setGameCharacterBanner] = useState('');

    const gamesList = {
        game_gostop: {gameBanner: gostop, gameTitleBanner: gostop_title, gameDescription: GoStopDescription(), well: "고스톱 류", release_date: "2025년 출시 예정", usageLevel: "청소년 이용불가"},
        game_sudda: {gameBanner: sudda, gameTitleBanner: sudda_title, gameDescription: SuddoDescription(), well: "고스톱 류", release_date: "2025년 출시 예정", usageLevel: "청소년 이용불가"},
        game_darkworld: {gameBanner: darkworld, gameTitleBanner: darkworld_title, gameDescription: DarkworldDescription(), well: "MMORPG", release_date: "출시일 미정", usageLevel: "청소년 이용불가"},
        game_battlesniper: {gameBanner: battlesniper, gameTitleBanner: battlesniper_title, gameDescription: BattlesniperDescription(), well: "FPS", release_date: "출시일 미정", usageLevel: "15세 이용가"},
        game_probaseball: {gameBanner: probaseball, gameTitleBanner: probaseball_title, gameDescription: ProbaseballDescription(), well: "스포츠 게임", release_date: "2027년 출시 예정", usageLevel: "전체 이용가"},
        game_baduk: {gameBanner: baduk, gameTitleBanner: baduk_title, gameDescription: BadukDescription(), well: "보드게임", release_date: "2026년 출시 예정", usageLevel: "전체 이용가"},
        game_janggi: {gameBanner: janggi, gameTitleBanner: janggi_title, gameDescription: JanggiDescription(), well: "보드게임", release_date: "2026년 출시 예정", usageLevel: "전체 이용가"},
        game_matching: {gameBanner: matching, gameTitleBanner: matching_title, gameDescription:MatchingDescription(), well: "보드게임", release_date: "2026년 출시 예정", usageLevel: "청소년 이용불가" },
    };


    useEffect(() => {
        window.scrollTo(0, 0);
        setGame(gamesList[gameName]);
        setGameInfoBanner(game_info);

        if (gameName === 'game_battlesniper'){
            $(".center-warp-img-other-game").css('display', 'block');
            setGameCharacterBanner(battlesniper_character);
        }

    }, [gameName]);

    return (
        <>
            <SubHeader/>
            <div className="other-game-section" style={{backgroundImage: `url(${game.gameBanner})`}}/>

            <div id="contents" style={{backgroundColor: '#4e4e4e'}}>

                <div className="other-game-info-area">
                    <div className="center-warp-other-game">
                        <div className="other-game-title-text-img" style={{marginTop: "-18px"}}>
                            <img src={game.gameTitleBanner} alt="Game Title"/>
                        </div>
                        <div className="title-text-other-game">
                            <em>게임 정보</em>
                        </div>
                        <div style={{width: "auto", height: "auto", textAlign: "center"}}>
                            <img src={gameInfoBanner} alt="Game Info"/>
                        </div>
                        <div style={{textAlign: "center", paddingTop: "20px", fontFamily: "ap"}}>
                            <div className="info-text-other-game">에스디소프트</div>
                            <div className="info-text-other-game">에스엠게임즈</div>
                            <div className="info-text-other-game">{game['well']}</div>
                            <div className="info-text-other-game">{game['release_date']}</div>
                            <div className="info-text-other-game">{game['usageLevel']}</div>
                        </div>
                    </div>
                </div>


                <div className="explain-area-other-game">
                    <div className="center-warp-other-game">
                        <div className="center-warp-img-other-game" >
                            <img className="center-warp-img2-other-game" src={gameCharacterBanner} alt="Game Character"/>
                        </div>

                        <div className="title-text-other-game">
                            <em>게임 설명</em>
                        </div>
                        <div className="explain-text-other-game">
                            {game['gameDescription']}
                        </div>
                    </div>
                </div>

                <div className="PointArea">
                    <div className="center-warp-other-game">
                        <div className="title-text-other-game" style={{width: '165px'}}>
                          <em>게임 포인트</em>
                        </div>

                        <UpcomingGamesPointTable gameName={gameName}/>
                    </div>


                </div>
            </div>

            <SubFooter/>
        </>
    );
}

export default UpcomingGames;