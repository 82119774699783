import React from "react";

function AuthOperating() {
    return (
        <div className="policy">
            <div>
                <div className="OperatingPolicyTitle">
                    <span className="PolicyTitle2">제1조 목적</span><br/>
                </div>


                <div className="policy2">
                    이 운영정책은 주식회사 에스엠게임즈(이하 “회사”)에서 이용고객(이하 “회원”)에게 제공하는 서비스 및 웹보드 게
                    임(포커, 고스톱 등) 을 이용하는데 있어 회원이 준수하여야 할 사항을 구체적으로 규정함을 목적으로 합니다.
                    <br/>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제2조 용어의 정의</span><br/>
                        <ol>
                            <li>이 운영정책에서 사용하는 용어의 정의는 다음과 같습니다.</li>
                            <ol>
                                <li>"게임머니"라 함은 포커머니, 고스톱머니 등을 말하며 회사의 게임 서비스 이용을 위한 도구를 말합니다.</li>
                                <li>"알 머니"라 함은 무료 채널 등에서 이용할 수 있는 무료 게임머니를 말합니다.</li>
                                <li>"캐쉬"라 함은 서비스를 이용 또는 구매하기 위해 사용되는 가상의 데이터로서 회원이 대금을 지급하고
                                    구입하는 것을 말합니다.
                                </li>
                            </ol>
                            <li>이 운영정책에서 사용하는 용어의 정의는 제1항 각호에서 정하는 것을 제외하고는 관계법령 및 기타 일반적
                                인 상관례에 의합니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제3조 정책 외 준칙  </span><br/>
                        이
                        정책에서
                        정하지
                        아니한
                        사항과
                        이
                        정책의
                        해석에
                        관하여는
                        이용약관에
                        따르고, 그
                        외
                        사항에
                        대하여는
                        관련
                        법령에
                        따릅니다.
                        <br/>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제4조 게임머니의 운영  </span><br/>
                        <ol>
                            <li>회원이 서비스를 이용하면서 생성 또는 취득되는 게임머니는 현금이나 캐쉬와는 상관없으며, 게임머니로
                                회사의 유료서비스를 이용할 수 없습니다.
                            </li>
                            <li>게임머니에 대한 일체의 권리는 회사에게 있습니다.</li>
                            <li>회사는 회원 간의 게임머니 거래를 인정하지 않습니다.</li>
                            <li>회사는 회원의 네트워크 문제로 인한 접속 끊김 등으로 발생한 게임머니를 복구하지 않습니다.</li>
                            <li>회사는 서비스 운영상 필요하다고 판단될 때, 회원의 게임머니 및 등급을 수정할 수 있습니다.</li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제5조 알 머니의 운영 </span><br/>
                        <ol>
                            <li>회사는 회원이 서비스를 효율적으로 이용하게 할 수 있도록 알 머니를 운영할 수 있습니다.</li>
                            <li>알 머니는 무료 재화로써 일체의 재산적 가치가 없습니다.</li>
                            <li>알 머니에 대한 일체의 권리는 회사에게 있으며, 회원은 알 머니를 제3자에게 양도,증여,판매할 수 없습니다.</li>
                            <li>회원이 탈퇴할 경우 알 머니는 자동소멸 됩니다.</li>
                            <li>회원이 알 머니를 정책상의 기준 이외의 방법으로 취득한 경우 회사는 임의로 알 머니의 전부 또는
                                일부의 삭제 및 기타 제반조치를 취할 수 있습니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제6조 이용 제재 기준 </span><br/>
                        1. &nbsp;
                        제재
                        대상
                        행위
                        및
                        처리
                        기준 <br/>


                        <table>
                            <tr>
                                <th>분류</th>
                                <th>항목</th>
                                <th>1차</th>
                                <th>2차</th>
                                <th>3차</th>
                                <th>4차</th>
                            </tr>

                            <tr>
                                <td rowspan="8">비매너 행위</td>
                                <td>불건전한 내용의 닉네임, 프로필 등을 사용하는 행위</td>
                                <td>강제변경</td>
                                <td>7일 접속제한</td>
                                <td>30일 접속제한</td>
                                <td>1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>욕설, 비방, 성희롱 등 타인의 명예를 훼손하는 행위</td>
                                <td>경고</td>
                                <td>30일 접속제한</td>
                                <td>6개월 접속제한</td>
                                <td>1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>게시판, 쪽지, 대화창 등에서 도배하는 행위</td>
                                <td>경고</td>
                                <td>30일 접속제한</td>
                                <td>6개월 접속제한</td>
                                <td>1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>게시판, 쪽지, 대화창 등에서 도배하는 행위</td>
                                <td>30일 접속제한</td>
                                <td>6개월 접속제한</td>
                                <td>1년 접속제한</td>
                                <td>이용정지</td>
                            </tr>

                            <tr>
                                <td>욕설, 위협, 성적수치심을 유발하는 표현 등으로 고객상담 업무를 방해하는 행위</td>
                                <td>30일 접속제한</td>
                                <td>6개월 접속제한</td>
                                <td>1년 접속제한</td>
                                <td>이용정지</td>
                            </tr>

                            <tr>
                                <td>게임과 무관한 내용을 반복적으로 게시하거나 영리목적으로 광고를 게시하는 행위</td>
                                <td>30일 접속제한</td>
                                <td>6개월 접속제한</td>
                                <td>1년 접속제한</td>
                                <td>이용정지</td>
                            </tr>

                            <tr>
                                <td>운영자 및 회사 관계자를 사칭하여 타인에게 피해를 주는 행위</td>
                                <td>30일 접속제한</td>
                                <td>6개월 접속제한</td>
                                <td>1년 접속제한</td>
                                <td>이용정지</td>
                            </tr>

                            <tr>
                                <td>운영자를 속이는 허위신고, 증거조작 등의 모든 행위</td>
                                <td>30일 접속제한</td>
                                <td>6개월 접속제한</td>
                                <td>1년 접속제한</td>
                                <td>이용정지</td>
                            </tr>


                            <tr>
                                <td rowspan="3">개인정보</td>
                                <td>타인의 개인정보를 당사자의 동의 없이 취득하는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>타인의 개인정보로 계정을 만들거나 게임을 이용하는 행위</td>
                                <td colspan="4">이용정지</td>
                            </tr>

                            <tr>
                                <td>도용한 결제정보와 수단으로 게임자산을 구매, 사용하는 행위</td>
                                <td colspan="4">이용정지</td>
                            </tr>


                            <tr>
                                <td rowspan="5">비정상 플레이(짜고치기)</td>
                                <td>연관성이 확인되는 사용자들의 플레이로 타인에게 피해를 주는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>2명 이상의 사용자가 조직적으로 플레이를 하여 승패를 조작하는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>동일한 장소 또는 다수의 아이디로 조직적 플레이를 하는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>비정상인 베팅, 플레이로 인해 타인에게 피해를 주는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>비정상적인 패턴의 게임 플레이를 반복하는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>


                            <tr>
                                <td rowspan="8">부정이용</td>
                                <td>해킹 및 불법프로그램을 사용하는 행위</td>
                                <td colspan="4">이용정지</td>
                            </tr>

                            <tr>
                                <td>비정상 또는 부정한 방법으로 게임자산을 취득하는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>이벤트용 쿠폰, 게임자산 등을 과다하게 수집/이용하는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>정당한 사유 없는 유료상품의 결제취소, 환불 요청하는 행위</td>
                                <td colspan="4">이용정지</td>
                            </tr>

                            <tr>
                                <td>게임에서 허용하지 않은 방법으로 타인의 플레이에 피해를 주는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>대리게임 진행을 통해 게임머니, 사이버 포인트 등을 취득하는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>게임머니, 사이버 포인트 등을 의도적으로 여러 계정에 이동시키는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>하나의 IP에서 다수의 회원가입 및 결제를 하는 행위</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>


                            <tr>
                                <td rowspan="3">현금거래</td>
                                <td>고의적인 져주기, 게임머니, 사이버 포인트 등을 잃어주는 행위를 상습적으로 하는 경우</td>
                                <td colspan="4">1년 접속제한</td>
                            </tr>

                            <tr>
                                <td>게임머니, 아이템을 현금으로 사고 팔거나 거래를 알선하는 행위</td>
                                <td colspan="4">이용정지</td>
                            </tr>

                            <tr>
                                <td>게임머니, 아이템의 현금 거래를 유도하거나 광고하는 행위</td>
                                <td colspan="4">이용정지</td>
                            </tr>

                        </table>

                    </div>


                    <div className="PolicyContent">
                        2. &nbsp; 제재 및 회수 관련<br/>
                        <ol>
                            <li>제재 대상 행위에 따라 서비스 전체 또는 개별 게임 별로 이용 제재될 수 있습니다.</li>
                            <li>제재 대상 행위에 따라 제재 대상 계정(ID)를 비롯하여 동일명의의 계정(ID) 전부 혹은 일부에 대한
                                자산회수가 이루어질 수 있습니다.
                            </li>
                            <li>회수되는 항목은 게임머니, 알 머니 등의 내용 일체를 포함합니다.</li>
                            <li>이용제한 기간은 제재 대상 행위의 경중에 따라 운영자의 판단으로 변경될 수 있습니다.</li>
                            <li>이용정지 제재 시 제재 대상 계정(ID) 또는 동일명의의 모든 계정(ID)의 이용이 영구히 제한됩니다.</li>
                            <li>이용제한 및 이용정지 제재 시 제재 대상 계정(ID)을 비롯하여 동일명의의 계정(ID) 전부
                                혹은 일부 또한 제재 대상에 포함될 수 있습니다.
                            </li>
                            <li>이용제한 및 이용정지 제재 시 제재 대상 계정(ID)와 동일명의의 이용자는 제재 기간 내 재가입이
                                금지될 수 있습니다.
                            </li>
                            <li>차수에 따라 제재되는 경우 시간 대에 따라 연속 제재 될 수 있습니다.</li>
                            <li>제재 기준에 규정에도 불구하고 회원의 위반 정도에 따라 제재를 감경하거나 가중할 수 있습니다.</li>
                            <li>제재 기준에 규정되어 있지 않더라도 게임운영에 지장을 초래하거나 다른 회원에게 피해를 주는 행위에
                                대해서 제재가 가해질 수 있습니다.
                            </li>
                            <li>자동 결제 상품의 경우 당월 결제 분에 대한 환불은 불가하며, 다음달 자동 결제는 해지 처리됩니다.</li>
                            <li>제재 대상 계정(ID)의 캐쉬 환불은 캐쉬정책을 따르며 고객센터를 통해 요청해주시기 바랍니다.</li>
                            <li>제재 대상 행위가 현행 법령에 위반되는 경우 제재 외에 추가적으로 수사 의뢰 등을 통해
                                민·형사상의 처벌을 받을 수 있습니다.
                            </li>
                        </ol>
                        <br/>


                        3. &nbsp;
                        예외
                        사항 <br/>
                        <ol>
                            <li>사실관계 조사 및 조치에 필요한 기간인 최대 7일동안 회원의 로그인을 제한할 수 있으며, 조사 기간 중
                                무혐의로 확인된 경우 제재조치는 해제됩니 다.
                            </li>
                            <li>해킹으로 인한 결제도용이나 문제 확인을 위해 일시적으로 이용제한이 될 수 있으며, 해당 계정(ID)은
                                진위여부 확인 후 복구 가능합니다.
                            </li>
                            <li>비정상 이용으로 오인하여 제한한 것이 확인된 경우 제재조치(이용제한 및 이용정지 등)는 해제됩니다.</li>
                        </ol>

                    </div>

                    <div className="PolicyContent">
                        <span className="PolicyTitle2"> 제7조 장애 관리 </span><br/>
                        장애로
                        인한
                        유료서비스
                        사용에
                        손해를
                        본
                        회원에게는
                        소비자
                        피해보상
                        규정에
                        근거하여
                        보상을
                        해드리며, 회원
                        은
                        회사에
                        대하여
                        별도의
                        손해배상을
                        청구할
                        수
                        없습니다.
                        <br/><br/>
                        단, 임시점검
                        등의
                        부득이하게
                        발생되는
                        점검에
                        대해서는
                        보상에서
                        제외됩니다.
                        <br/><br/>

                    </div>

                    <div>
                        <span className="PolicyTitle2"> 부칙 </span><br/>
                        본 운영정책은 2024년 02월 01일부터 시행합니다.<br/>

                    </div>

                    <div className="PolicyContent">
                        <span className="PolicyTitle2"> </span><br/>

                    </div>
                </div>
            </div>
        </div>
);
}

export default AuthOperating;