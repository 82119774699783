import React, {useEffect, useState} from 'react';
import  logo_bottom from "../images/logo-bottom.png";
import icon_18agemark from "../images/Icon-18agemark.png";
import icon_gamblingmark from "../images/Icon-gamblingmark.png";
import {useNavigate} from "react-router-dom";
import $ from 'jquery';

const GameFooter = ({gameName}) => {
    const navigate = useNavigate();
    const [expulsion, setExpulsion] = useState();
    const [mutual, setMutual] = useState();
    const [usageLevel, setUsageLevel] = useState();
    const [classificationNumber, setClassificationNumber] = useState();
    const [classificationDate, setClassificationDate] = useState();
    const [registrationNumber, setRegistrationNumber] = useState();
    const [isMobile, setIsMobile] = useState();


    function policy(category){
        navigate(`/policy?page=${category}`);
    }

    useEffect(() => {
        if(gameName === 'sm_dualgo'){
            setExpulsion("에스엠신맞고");
            setMutual("주식회사 에스엠게임즈");
            setUsageLevel("청소년이용불가");
            setClassificationNumber("CC-NP-170125-001");
            setClassificationDate("2017.01.25");
            setRegistrationNumber("24112-2013-33");
        }
        else if(gameName === 'sm_baduki'){
            setExpulsion("에스엠바둑이");
            setMutual("주식회사 에스엠게임즈");
            setUsageLevel("청소년이용불가");
            setClassificationNumber("CC-NP-140326-010");
            setClassificationDate("2014.03.26");
            setRegistrationNumber("24112-2013-33");
        }
        else if(gameName === 'sm_poker'){
            setExpulsion("에스엠세븐포커");
            setMutual("주식회사 에스엠게임즈");
            setUsageLevel("청소년이용불가");
            setClassificationNumber("CC-NP-140326-011");
            setClassificationDate("2014.03.26");
            setRegistrationNumber("24112-2013-33");
        }
        else if(gameName === 'sm_smpl'){
            setExpulsion("");
            setMutual("");
            setUsageLevel("");
            setClassificationNumber("");
            setClassificationDate("");
            setRegistrationNumber("");
        }

        setIsMobile(localStorage.getItem('isMobile'));

        if (localStorage.getItem('isMobile')) {
            $('.game-footer').css('transform', 'scale(0.75)');
        }

        if(window.innerWidth >= 2560){
            $('.game-footer').css('height', '20rem');
        }

        $("body").css("background-color", "#333333");

    }, [gameName]);

    return (
        <>
            <div className="game-footer" style={{backgroundColor: "#333333"}}>
                <div className="info_sectionGame">
                    <div className="inner">
                        <div className="info_area">
                            <address>
                                <ul className="info_lst">

                                    <li className="info_item" style={{display: "flex"}}>
                                        <a href="../" className="info_txt" style={{fontSize: '14px'}}>회사소개<span
                                            className="info_txt_bar_game"></span></a>

                                        <div className="info_txt" style={{fontSize: '14px', cursor: "pointer"}}
                                             onClick={event => policy('terms_of_use')}>
                                            이용약관
                                            <span className="info_txt_bar_game"></span>
                                        </div>

                                        <div className="info_txt" style={{fontSize: '14px', cursor: "pointer"}}
                                             onClick={event => policy('privacy_policy')}>
                                            개인정보처리방침
                                        </div>
                                    </li>


                                    <li className="info_item">
                                        <span className="info_txt"></span>
                                    </li>
                                    <li className="info_item">
                                        <span className="info_txt">주식회사 에스엠게임즈　　</span>
                                        <span className="info_txt">대표이사 : 김수희　　</span>
                                        <span className="info_txt">통신판매업신고번호: 2023-서울영등포-0749　</span>
                                        <span className="info_txt">사업자 등록번호 : 285-88-00365</span>
                                    </li>
                                    <li className="info_item">
                                        <span className="info_txt">주소: 서울시 영등포구 대림로 186, 대림하우스 703호(대림동) </span>
                                        <span className="info_txt">대표전화 : 1899-9365　　</span>
                                        <span className="info_txt">Email : helpdesk@smgame.co.kr</span>
                                    </li>
                                </ul>
                            </address>
                            <p className="copyright">
                                <img loading="lazy" src={logo_bottom} className="logo_SM"
                                     onClick={() => window.location.href = '../'} alt=""/> ©
                                2020 SMGAMES. All rights reserved.
                            </p>
                        </div>
                        <div className="grade_markimg_game">
                            <img loading="lazy" src={icon_18agemark} alt=""/>
                            <img loading="lazy" src={icon_gamblingmark} alt=""/>
                        </div>

                        <div id="all_game_footer" className="all_game_footer">
                            <div className="gradeGame">
                                <div className="grade_textBoxGame" style={{lineHeight: '32px'}}>
                                    <div className="grade_textinnerGame">
                                        <div className="grade_innerleftGame"> 제명</div>
                                        <div className="grade_innerrightGame"><span
                                            className="info_txt_bar_game"></span>{expulsion}</div>
                                    </div>
                                    <div className="grade_textinnerGame">
                                        <div className="grade_innerleftGame"> 상호</div>
                                        <div className="grade_innerrightGame"><span
                                            className="info_txt_bar_game"></span>{mutual}</div>
                                    </div>
                                </div>
                                <div className="grade_textBoxGame">
                                    <div className="grade_textinnerGame">
                                        <div className="grade_innerleftGame"> 이용등급</div>
                                        <div className="grade_innerrightGame"><span
                                            className="info_txt_bar_game"></span>{usageLevel}</div>
                                    </div>
                                    <div className="grade_textinnerGame">
                                        <div className="grade_innerleftGame"> 등급분류번호</div>
                                        <div className="grade_innerrightGame"><span
                                            className="info_txt_bar_game"></span>{classificationNumber}</div>
                                    </div>
                                </div>
                                <div className="grade_textBoxGame" style={{lineHeight: '17px'}}>
                                    <div className="grade_textinnerGame">
                                        <div className="grade_innerleftGame"> 등급분류일자</div>
                                        <div className="grade_innerrightGame"><span
                                            className="info_txt_bar_game"></span>{classificationDate}</div>
                                    </div>
                                    <div className="grade_textinnerGame">
                                        <div className="grade_innerleftGame"> 신고등록번호</div>
                                        <div className="grade_innerrightGame"><span
                                            className="info_txt_bar_game"></span>{registrationNumber}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)

            {isMobile && (
                <div className="GOMobileButton" onClick={() => navigate('/mobile')}>모바일버전
                    <div className="GOMobileButtonImg">
                        <img loading="lazy" src={require('../images/Icon-mobilebutton.png')} alt=""/>
                    </div>
                </div>
            )}
        </>
    );
}

export default GameFooter;
