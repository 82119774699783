import React, {useEffect} from "react";
import MobileHeader from "./mHeader";
import ShopAvatar from "./mShopAvatar";
import MobileFooter from "./mFooter";
import $ from "jquery";

const MobileShop = () => {
    const params = new URLSearchParams(window.location.search);
    var status = params.get('status');

    useEffect(() => {
         $('body').css({
            'transform': 'unset',
            'min-width': '320px',
            'zoom': 'unset'
        });

         if(status === 'close') {
            // window.history.pushState(null, '', '/mobile/buy_avatar_web');
         }
         else if (status) {
            alert(status);
         }
    }, []);

    return (
        <div style={{background: '#f5f5f5'}}>
            <MobileHeader/>

            <div className="m_top_my_section">
                <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>상점</span>
            </div>

            <div className="m_shop_contents">
                <ShopAvatar/>
            </div>

            <MobileFooter/>
        </div>
    );
}

export default MobileShop;