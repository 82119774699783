import React, {useEffect, useState} from "react";
import icon_close_button from "../images/Icon-closebutton.png";
import right_gold from "../images/right_gold.png";
import right_go from "../images/right_go.png";
import right_al from "../images/right_al.png";
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";
import $ from "jquery";
import CashBoxPopup from "../popups/webCashBoxPopup";
import FreeChargePopup from "../popups/webFreeChargePopup";

const getDivisionText = (division) => {

    switch (division) {
        case 0:
            return '[공지]';
        case 1:
            return '[이벤트]';
        case 2:
        case 3:
        case 4:
            return '[점검]';
        default:
            return '';
    }
};


function calculateGrade(totalMoney) {
    let grade = "";

    if (totalMoney >= 1000000000) {
        grade = "신";
    } else if (totalMoney >= 500000000) {
        grade = "도신";
    } else if (totalMoney >= 100000000) {
        grade = "제왕";
    } else if (totalMoney >= 50000000) {
        grade = "초인";
    } else if (totalMoney >= 10000000) {
        grade = "지존";
    } else if (totalMoney >= 1000000) {
        grade = "영웅";
    } else if (totalMoney >= 100000) {
        grade = "고수";
    } else if (totalMoney >= 10000) {
        grade = "중수";
    } else if (totalMoney >= 1000) {
        grade = "하수";
    } else {
        grade = "초보";
    }

    return grade;
}


const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

function RightGames({shop}) {
    const navigate = useNavigate();
    const [dateLogined, setDateLogined] = useState('');
    const [lostPopupVisible, setLostPopupVisible] = useState(false);
    const [alPopupVisible, setAlPopupVisible] = useState(false);
    const [goPopupVisible, setGoPopupVisible] = useState(false);
    const [rightGold, setRigthGold] = useState('');
    const [moneyBoxText, setMoneyBoxText] = useState('');
    const [moneyAL, setMoneyAL] = useState('');
    const [moneyGOLD, setMoneyGOLD] = useState('');
    const [moneyCAL, setMoneyCAL] = useState('');
    const [moneyCGOLD, setMoneyCGOLD] = useState('');
    const [grade, setGrade] = useState('');
    const [nickname, setNickname] = useState('');
    const [notices, setNotices] = useState([]);
    const [avatarID, setAvatarID] = useState('');

    const [showCashBox, setShowCashBox] = useState(false);
    const [showFreeCharge, setShowFreeCharge] = useState(false);

    var gameName = localStorage.getItem('gameName');

    const toggleLostPopup = () => setLostPopupVisible(!lostPopupVisible);
    const toggleAlPopup = () => setAlPopupVisible(!alPopupVisible);
    const toggleGoPopup = () => setGoPopupVisible(!goPopupVisible);

    const openCashBoxPopup = () => {
        setShowCashBox(true);
        $('html').css('overflow', 'hidden');
        $('.cashfree_popup').css("display", "block");
    };

    const openFreeChargePopup =() => {
        setShowFreeCharge(true);

        $('html').css('overflow', 'hidden');
        $('.freecharge_popup').css("display", "block");
    };

    const rigthGamedata = async () => {
        try {
            var response;
            const token = sessionStorage.getItem('token');

            response = await axios.get(baseUrl + 'game/right_game_data/', {
                headers: {
                    Authorization: 'Bearer '+ token,
                },
            });

            const data = response.data;
            setMoneyAL(data.money_al);
            setMoneyGOLD(data.money_go);
            setMoneyCAL(data.money_cal);
            setMoneyCGOLD(data.money_cgo);

            setDateLogined(data.date_login.split('T')[0]+".");
            setGrade(calculateGrade(data.money_go));
            setNickname(data.nickname);
            setNotices(data.notices);
            setAvatarID(data.avatar_id);

            sessionStorage.setItem("money_al", data.money_al.toLocaleString());
            sessionStorage.setItem("money_go", data.money_go.toLocaleString());
            sessionStorage.setItem("money_cal", data.money_cal.toLocaleString());
            sessionStorage.setItem("money_cgo", data.money_cgo.toLocaleString());

            sessionStorage.setItem("date_login", data.date_login.split('T')[0] + ".");
            sessionStorage.setItem("grade", calculateGrade(data.money_go));
            sessionStorage.setItem("nickname", data.nickname);
            sessionStorage.setItem("notice", JSON.stringify(data.notices));
            sessionStorage.setItem("avatar_id", data.avatar_id);
        } catch (error) {
            navigate('/login', {state: {redirect: `/game?gameName=${gameName}`}});
        }
    };

    function serviceCenter(noticeId) {
        window.location.href = `/service_center/notice_content?notice_id=${noticeId}`;
    }


    const getAvatarId = async () => {
        try {
            const response = await axios.get(baseUrl + 'my_page/get_avatar_id/', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            });

            const data = response.data;
            setAvatarID(data.avatar_id);
            sessionStorage.setItem("avatar_id", data.avatar_id);
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        $('.right_warp').css('height', '794px');

        if(sessionStorage.getItem("date_login")){
            setAvatarID(sessionStorage.getItem("avatar_id"));
            setNickname(sessionStorage.getItem("nickname"));
            setGrade(sessionStorage.getItem("grade"));

            setDateLogined(sessionStorage.getItem("date_login"));

            setMoneyAL(sessionStorage.getItem("money_al"));
            setMoneyGOLD(sessionStorage.getItem("money_go"));
            setMoneyCAL(sessionStorage.getItem("money_cal"));
            setMoneyCGOLD(sessionStorage.getItem("money_cgo"));

            setNotices(JSON.parse(sessionStorage.getItem("notice")));
        } else {
            rigthGamedata();
        }

        if(sessionStorage.getItem('token')){
            getAvatarId();
        }

        if(shop){
            setRigthGold(right_gold);
            setMoneyBoxText(' 골드/냥');
        }
        else if (gameName === 'sm_dualgo'){
            setRigthGold(right_go);
            setMoneyBoxText(' 냥');
        } else {
            setRigthGold(right_gold);
            setMoneyBoxText(' 골드');
        }

    }, [gameName]);


    return (
        <>
            <div className="right_warp">
                <div className="Rightwarp_myinfoBox">
                    <div className="myinfo_imgBox">
                        {avatarID && (<img src={require('../images/shop_img/' + avatarID + '.gif')} alt="user avatar"
                                           onClick={() => navigate('/my_page')}/>)}

                    </div>
                    <div className="myinfo_textBox" style={{paddingTop: '28px'}}>

                        <em style={{color: '#7b7b7b'}}>닉네임: {nickname}<br/></em>
                        <em style={{color: '#7b7b7b'}}>등급: {grade}<br/></em>
                        <div style={{marginTop: '20px'}} onClick={event => navigate('/my_page')}>내정보보기
                        </div>
                        <div onClick={(event) => navigate('/my_page?page=myWallet')}>내지갑보기
                        </div>
                    </div>
                </div>

                <div className="Rightwarp_lastjoinBox">
                    <em>최종 접속일 {dateLogined}</em>
                </div>


                <div className="Rightwarp_moneyinfoBox">
                    <div className="moneyinfo_popup" style={{
                        display: lostPopupVisible ? 'block' : 'none',
                        height: '334px',
                        marginTop: '-117px',
                        zIndex: 2
                    }}>
                        <div className="popup_titleBox">
                            <em>손실머니보기</em>
                            <button onClick={toggleLostPopup} style={{position: 'absolute', margin: '11px 0 0 78px'}}>
                                <img
                                    src="../images/Icon-closebutton.png" alt="close button"/>
                            </button>
                        </div>
                    </div>


                    <div className="moneyinfo_popup" style={{
                        display: goPopupVisible ? 'block' : 'none',
                        marginTop: '155px',
                        height: '130px',
                        zIndex: 2
                    }}>
                        <div className="popup_titleBox">
                            <em>머니정보</em>
                            <button onClick={toggleGoPopup} style={{position: 'absolute', margin: '11px 0 0 97px'}}><img
                                src={icon_close_button} alt="close button"/>
                            </button>

                            <div className="popup_moneyBoxGame">
                                <div className="moneyBox_innertext">금 고</div>
                                <img src={right_al} style={{width: '32px', height: '30px', padding: '18px 0 0 90px'}}
                                     alt="Right Go"/>
                                <em style={{
                                    fontSize: '22px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Apple SD Gothic Neo'
                                }}>{moneyCAL.toLocaleString()}</em>
                                <div className="moneyBox_innertext">
                                     알<br/>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="moneyinfo_popup"
                         style={{display: alPopupVisible ? 'block' : 'none', height: '130px'}}>
                        <div className="popup_titleBox">
                            <em>머니정보</em>
                            <button onClick={toggleAlPopup} style={{position: 'absolute', margin: '11px 0 0 97px'}}><img
                                src={icon_close_button} alt="close button"/>
                            </button>

                            <div className="popup_moneyBoxGame">
                                <div className="moneyBox_innertext">금 고</div>
                                <img src={rightGold} style={{width: '32px', height: '30px', padding: '18px 0 0 90px'}}
                                     alt="Right Go"/>
                                <em style={{
                                    fontSize: '22px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Apple SD Gothic Neo'
                                }}>{moneyCGOLD.toLocaleString()}</em>
                                <div className="moneyBox_innertext">
                                    {moneyBoxText}<br/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="moneyinfo_imgBox">
                        <img src={rightGold} alt="gold icon"/>
                    </div>

                    <div className="moneyinfo_textBox">
                        <em style={{
                            fontSize: '22px',
                            fontWeight: 'bold',
                            fontFamily: 'Apple SD Gothic Neo'
                        }}> {moneyGOLD.toLocaleString()}</em>
                        <em> {moneyBoxText} <br/></em>
                        <button onClick={() => {
                            toggleAlPopup();
                            setGoPopupVisible(false);
                        }}>[머니정보]
                        </button>
                    </div>

                    <div style={{paddingTop: '70px'}}>
                        <div className="moneyinfo_imgBox">
                            <img src={right_al} alt="al icon"/>
                        </div>
                        <div className="moneyinfo_textBox">
                            <em style={{
                                fontSize: '22px',
                                fontWeight: 'bold',
                                fontFamily: 'Apple SD Gothic Neo'
                            }}>{moneyAL.toLocaleString()}</em><em> 알<br/></em>
                            <button onClick={() => {
                                toggleGoPopup();
                                setAlPopupVisible(false);
                            }}>[머니정보]
                            </button>
                        </div>
                    </div>

                </div>


                <div id="RightWarpCashORfree" className="Rightwarp_cashORfree" style={{display: 'block'}}>
                    <div className="Rightwarp_box_button">
                        <button className="cashfree_button" onClick={openCashBoxPopup}
                                style={{marginLeft: '-30px'}}>금&nbsp;&nbsp;고
                        </button>
                        <button className="cashfree_button" onClick={openFreeChargePopup}
                                style={{marginLeft: '20px', backgroundColor: '#4e4e4e'}}>무료충전
                        </button>
                    </div>
                </div>


                <div className="Rightwarp_noticeBox">
                    <div className="notice_textBox">
                        <em style={{fontSize: '27px', fontWeight: 'bold'}}>공지사항</em>
                        <em className="notice_text_box_see_more">
                            <span onClick={event => navigate('/service_center/notice')}>더보기+</span><br/><br/></em>
                        {notices.map((notice, index) => (
                            <div className="notice_text_box_content" key={index}
                                 onClick={event => serviceCenter(notice.notice_id)}>
                                <em>{getDivisionText(notice.division)} {notice.title}<br/></em>
                                <em className="notice_text_box_date">{formatDate(notice.date)}<br/><br/></em>
                            </div>
                        ))}
                    </div>
                </div>

            </div>

            {showCashBox && <CashBoxPopup gameName={gameName}/>}
            {showFreeCharge && <FreeChargePopup gameName={gameName}/>}
        </>
    );
}


export default RightGames;