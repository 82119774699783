import '../css/main.css';
import footer_logo from "../images/SM Logo 1.png";
import FooterNoticeSlider from "./footerNoticeSlider";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import $ from "jquery";


function Footer() {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState();

    function policy(category){
        window.location.href = '/policy?page='+category;
        // navigate('/policy?page='+category);
    }

    function searchHelp() {
         window.location.href = '/service_center/search_help';
    }

    function inquiry() {
         window.location.href = '/service_center/inquiry';
    }

    function notice() {
        window.location.href = '/service_center/notice';
    }

    useEffect(() => {
        setIsMobile(localStorage.getItem('isMobile'));
        $("body").css("background-color", "#333333");
    }, []);

    return (
        <div className="footer">
            <div className="info_Topsection">
                <div className="info_Topsection_innerBox">
                    <div className="info_Topsection_inner1" id="bottom">
                        <a onClick={notice} className="info_Topsection_inner1_txt info_top_section_inner_a">공지사항</a>

                        <FooterNoticeSlider/>

                    </div>
                    <div className="info_Topsection_inner2">
                        <div className="info_Topsection_inner2Box"></div>
                        <a className="info_Topsection_inner2_txt" style={{fontSize: '25px', cursor: 'default'}}>서비스
                            바로가기</a>
                        <div className="info_Topsection_inner2_txt2"
                             style={{fontSize: '16px', display: "flex", gap: '45px'}}>
                            <div className="info_Topsection_inner2_txt2_hover" onClick={searchHelp}
                                 style={{cursor: "pointer"}}>도움말검색
                            </div>
                            <div className="info_Topsection_inner2_txt2_hover" onClick={inquiry}
                                 style={{cursor: "pointer"}}>문의/제안하기
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="info_section">
                <div className="inner">
                    <div className="logo_footer">
                        <img src={footer_logo} onClick={() => window.location.href = '../'}/>
                    </div>
                    <div className="info_area">
                        <address>
                            <ul className="info_lst">
                                <li className="info_item" style={{display: "flex", justifyContent: "center"}}>
                                    <div className="info_txt info_txt_hover"
                                         style={{fontSize: '14px', cursor: "pointer"}}
                                         onClick={() => navigate('/')}>회사소개
                                        <span className="info_txt_bar"></span>
                                    </div>

                                    <div className="info_txt info_txt_hover"
                                         style={{fontSize: '14px', cursor: "pointer"}}
                                         onClick={event => policy('terms_of_use')}>이용약관
                                        <span className="info_txt_bar"></span>
                                    </div>

                                    <div className="info_txt info_txt_hover"
                                         style={{fontSize: '14px', cursor: "pointer"}}
                                         onClick={event => policy('privacy_policy')}>개인정보처리방침
                                    </div>
                                </li>
                                <li className="info_item">
                                    <span className="info_txt">　</span>
                                </li>
                                <li className="info_item">
                                    <span className="info_txt">주식회사 에스엠게임즈　　</span>
                                    <span className="info_txt">대표이사 : 김수희　　</span>
                                    <span className="info_txt">통신판매업신고번호: 2023-서울영등포-0749　</span>
                                    <span className="info_txt">사업자 등록번호 : 285-88-00365　　</span>
                                    <span className="info_txt">주소: 서울시 영등포구 대림로 186, 대림하우스 703호(대림동)　　</span>
                                    <span className="info_txt">대표전화 : 1899-9365　　</span>
                                    <span className="info_txt">Email : helpdesk@smgame.co.kr　　</span>
                                </li>
                            </ul>
                        </address>

                        <p className="copyright">
                            © 2020 SMGAMES. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>

            { isMobile && (
                <div className="GOMobileButton" onClick={() => navigate('/mobile')}>모바일버전
                    <div className="GOMobileButtonImg">
                        <img loading="lazy" src={require('../images/Icon-mobilebutton.png')}/>
                    </div>
                </div>
            )}


        </div>
    );
}

export default Footer;
