import React, {useEffect, useState} from 'react';

const MobilePaymentRequest = ({paymentData}) => {

    useEffect(() => {
        document.getElementById("mobile_pay").submit();
    }, [paymentData]);


    return (
        <div>
            <form id="mobile_pay" name="form1" method="post" acceptCharset="euc-kr"
                  action="https://ksmobile.inicis.com/smart/payment/">

                <input type="hidden" name="P_INI_PAYMENT" value="MOBILE"/>
                <input type="hidden" name="P_MID" value={paymentData.mid}/>
                <input type="hidden" name="P_GOODS" value={paymentData.goodsName}/>
                <input type="hidden" name="P_OID" value={paymentData.orderNumber}/>
                <input type="hidden" name="P_AMT" value={paymentData.price}/>
                {/*<input type="hidden" name="P_AMT" value={'1'}/>*/}
                <input type="hidden" name="P_HPP_METHOD" value="1"/>
                <input type="hidden" name="P_UNAME" value={paymentData.userId}/>
                <input type="hidden" name="P_NEXT_URL"
                       value={"https://" + window.location.hostname + "/api/v1/pay/mobile_pay_return/"}/>
                <input type="hidden" name="P_NOTI" value={paymentData.merchantData}/>
            </form>
        </div>
    );
};

export default MobilePaymentRequest;
