import React from "react";
import Header from "./header";

function SubHeader() {
    return (
        <>
            <Header gameHeader={"gameHeader"}/>
        </>
    );
}

export default SubHeader;