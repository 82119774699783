import SubHeader from "../home/subHeader";
import "../css/shop.css";
import "../css/serviceCenter.css";
import React, {useEffect} from "react";
import $ from 'jquery';
import {useNavigate} from "react-router-dom";


function ServiceCenterHeader({category, page=null}) {
    const navigate = useNavigate();

    function changeTab(e, tabCategory) {
        if(tabCategory === 'policy'){
            navigate('/policy', {state: {category: 'terms_of_use'}});
        } else {
            window.location.href = '/service_center/'+tabCategory;
            if(tabCategory === "search_help"){
                $('#search_help').addClass('active');
            }
        }
    }

    function inquiryPages(e, page) {
        if(sessionStorage.getItem('token')){
            window.location.href = `/service_center/inquiry?page=${page}`;
        } else {
            navigate('/login', {state:{redirect: `/service_center/inquiry?page=${page}`}});
        }
    }

    useEffect(() => {
        $('.Rightwarp_moneyinfoBox').css('display', 'none');
        $('#RightWarpCashORfree').css('display', 'none');
        $('.right_warp').css('height', '542px');

        $('.service_tab_links').removeClass('active');

        if(category === 'notice') {
            $('#notice').addClass('active');
        }
        else if (category === 'search_help') {
            $('#search_help').addClass('active');
        }
        else if(category === 'inquiry') {
            $('#inquiry').addClass('active');
            $('.inquiry_sub_page').removeClass('inquiry_sub_page');
            $('#'+page).addClass('inquiry_sub_page');
        }
        else if (category === 'policy') {
            navigate('/policy');
        }

    }, [category, page]);


    return (
        <>
            <SubHeader bodyColor={false}/>
            <div className="TopMysection">
                <div className="TopMysection_inner" style={{justifyContent: 'left', maxWidth: '1400px'}}>
                    <div className="shop-tab">
                        <div className="tablinks">
                            <div className="shop-top-my-section-title">고객센터</div>
                        </div>

                        <div id="notice" className="service_tab_links active"
                             onClick={event => changeTab(event, 'notice')}>
                            <div className="service-top-my-section-tit">공지사항</div>
                        </div>

                        <div id="search_help" className="service_tab_links"
                             onClick={event => changeTab(event, 'search_help')}>
                            <div className="service-top-my-section-tit">도움말 검색</div>
                        </div>

                        <div id="inquiry" className="service_tab_links man_to_man_category_hover">
                            <div className="service-top-my-section-tit"
                                 onClick={event => inquiryPages(false, 'main')}>문의/제안하기
                            </div>
                            <div className="man_to_man_category" id="man_to_man_category">
                                <span id="receipt" onClick={event => inquiryPages(event, 'receipt')}>
                                    1:1문의 접수
                                </span>
                                <span id="statement" onClick={event => inquiryPages(event, 'statement')}>
                                    접수 내역
                                </span>
                                <span id="selfUserRestriction" onClick={event => inquiryPages(event, 'selfUserRestriction')}>
                                    자가 이용제한
                                </span>
                            </div>
                        </div>

                        <div id="policy" className="service_tab_links" onClick={event => changeTab(event, 'policy')}>
                            <div className="service-top-my-section-tit">정책/약관</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceCenterHeader;