import React, {useEffect} from "react";
import MobileHeader from "./mHeader";
import MobileFooter from "./mFooter";
import AuthTermOfUse from "../authentication/termOfUsePolicy";
import $ from 'jquery';
import AuthPrivacyPolicy from "../authentication/privacyPolicy";
const MobileTermOfUse = () => {

    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'zoom': 'unset',
            'background-color': '#333333'
        });

        $('.m_footer_box').css('position', 'relative');
        window.scroll(0, 0);
    }, []);

  return (
      <>
          <MobileHeader/>
          <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>개인정보처리방침</span>
                </div>

                <div className="m_policy_content">
                    <div className="m_policy_content_box">
                        <div className="m_agreement_contents">
                            <div className="m_agreement_contents_text">
                                <AuthPrivacyPolicy/>
                            </div>
                        </div>
                    </div>
                </div>
          </div>

          <MobileFooter/>
      </>
  );
}

export default MobileTermOfUse;