import React from "react";


function AuthPrivacyPolicy() {
    return (
        <div className="policy">
            <div>
                <div className="policy2">
            <span style={{lineHeight: '2'}}>주식회사 에스엠게임즈(이하 "회사" 또는 “에스엠게임즈”라 함)는 정보통신서비스제공자가 준수하여야 하는 대한
            민국의 관계 법령 및 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보 처리방침을 정하여 이용자 권
            익 보호에 최선을 다하고 있습니다.<br/><br/></span>


                    에스엠게임즈의 개인정보 처리방침은 다음과 같은 내용을 담고 있습니다.<br/>

                    <div className="PolicyContent" style={{marginTop: '20px'}}>
                        <ol>
                            <li>수집하는 개인정보의 항목 및 수집방법</li>
                            <li>개인정보의 수집 및 이용목적</li>
                            <li>개인정보의 보유 및 이용기간</li>
                            <li>개인정보의 공유 및 제공</li>
                            <li>개인정보의 처리위탁</li>
                            <li>개인정보 파기절차 및 방법</li>
                            <li>이용자 및 법정대리인의 권리와 그 행사방법</li>
                            <li>개인정보의 안전성 확보조치</li>
                            <li>개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</li>
                            <li>온라인 맞춤형 광고에 관한 사항</li>
                            <li>개인정보 보호책임자 및 연락처</li>
                            <li>본 개인정보 처리방침의 적용범위</li>
                            <li>고지의 의무</li>
                        </ol>
                        <br/>

                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">1. 수집하는 개인정보의 항목 및 수집방법  </span><br/>
                        가. 수집하는 개인정보의 항목<br/>
                        회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br/><br/>


                        <div className="PrivacyTable1">
                            <table>
                                <tr>
                                    <th>구분</th>
                                    <th>수집하는 개인정보 항목</th>
                                </tr>
                                <tr>
                                    <td>회원가입</td>
                                    <td>- 필수 : 아이디, 비밀번호, 별명, 이메일 주소, 본인인증 시 수집정보[이름, 생년월일, 성별,
                                        내외국인정보, 휴대폰번호/통신사(휴대폰인증 시), 중복가입확인정보(DI), 암호화된 동일인
                                        식별연계정보(CI)], 게임산업진흥에 관한 법률에 따른 '청소년'인 경우 법정대리인 본인인증
                                        시 수집정보[법정대리인 이름, 생년월일, 성별, 내외국인정보, 휴대폰번호/통신사(휴대폰인
                                        증 시), 중복가입확인정보(DI), 암호화된 동일인 식별연계정보(CI), 법정대리인 이메일]<br/>
                                        - 선택 : 휴대폰 번호
                                    </td>
                                </tr>
                                <tr>
                                    <td>모바일 게임 이용</td>
                                    <td>- 친구목록 연동 시 페이스북 친구목록<br/>
                                        - 최초 게임 실행시 닉네임(별명)
                                    </td>
                                </tr>

                                <tr>
                                    <td>외부 플랫폼 인증</td>
                                    <td>
                                        - 페이스북 인증 이용 시: 페이스북 회원번호, 닉네임, 프로필 사진 URL<br/>
                                        - 구글 인증 이용 시: 구글 회원번호, 닉네임, 프로필 사진 URL<br/>
                                        - 애플게임센터 인증 이용 시: 애플게임센터 회원번호<br/>
                                        - 네이버 인증 이용 시: 네이버 회원번호, 별명<br/>
                                        - 세이클럽 인증 이용 시: 세이클럽 회원번호, 닉네임<br/>
                                        - 페이코 인증 이용 시: 페이코 아이디(이메일 또는 휴대폰번호), 페이코 회원번호<br/>
                                        - 애플 인증 이용 시: 애플 회원번호<br/>
                                        * 외부 플랫폼 인증을 통한 회원 가입 시 위 정보 이외에 외부 플랫폼에서 제공하는 다른
                                        개인정보는 에스엠게임즈에서 저장하지 않습니다.

                                    </td>
                                </tr>

                                <tr>
                                    <td>서비스 이용과정</td>
                                    <td>
                                        - IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 부정 이용 기록, 결제/구매 기록, 단말기
                                        정보(단말장치 식별 고유정보, 단말기 환경정보, 광고 ID, 서비스 이용에 대한 기본 통계),
                                        애플리케이션 설치 및 사용 이력, PC의 프로세스 정보(프로세스 목록, 프로세스의 속성 정
                                        보, 네트워크 정보)
                                    </td>
                                </tr>

                                <tr>
                                    <td>유료 결제</td>
                                    <td>
                                        - 휴대폰 결제 시: 휴대폰번호<br/>
                                        - 신용카드 결제 시: 카드사명, (암호화된)카드번호<br/>
                                        - 무통장 입금 결제 시: 은행명, 이름, 계좌번호, 연락처<br/>
                                        - 계좌이체 결제 시: 은행명, 계좌번호<br/>
                                        - 만19세 미만 미성년 결제 시: 법정대리인 이름, 생년월일, 성별, 휴대폰번호(휴대폰인증
                                        시), 이메일 주소<br/>
                                        서비스 이용과정에서 이용자의 동의를 받아 아래와 같은 정보들이 추가 수집될 수 있습니
                                        다.
                                    </td>
                                </tr>

                            </table>

                        </div>
                        <br/>
                        <br/>


                        <div className="PrivacyTable1">
                            <table>
                                <tr>
                                    <th>추가 수집 유형</th>
                                    <th>수집하는 개인정보 항목</th>
                                </tr>
                                <tr>
                                    <td>본인 인증 시</td>
                                    <td>- 이름, 아이디, IP, 생년월일, 성별, 내외국인정보, 휴대폰번호, 중복가입확인정보(DI), 암호
                                        화된 동일인 식별연계정보(CI), 통신사, 신용카드사
                                    </td>
                                </tr>
                                <tr>
                                    <td>환불 신청 시</td>
                                    <td>- 이름, 이메일, 은행명, 계좌번호
                                    </td>
                                </tr>

                                <tr>
                                    <td>현금 영수증 발행 시</td>
                                    <td>
                                        - 휴대폰번호, 현금영수증 카드번호
                                    </td>
                                </tr>

                                <tr>
                                    <td>고객 문의 및 신고 시</td>
                                    <td>
                                        - 이메일 문의 시 : (필수) 이메일 / (선택) 휴대폰번호(단, 일부 문의 유형은 휴대폰번호를
                                        선택적으로 수집하지 않습니다.)<br/>
                                        - 불법프로그램 신고 문의 시 : (필수) 연락 가능 전화번호, 이메일, 문의내용 / (선택) 불법
                                        프로그램 사용 아이디, 첨부파일<br/>
                                        - 결제정보도용 신고 시 : 증빙 서류 (결제정보도용 피해확인원 작성본, 마스킹된 사건사
                                        고 사실 확인원 사본, 납입영수증 사본, 입출금 내역서, 통장 사본)<br/>
                                        - 게임머니유실 신고 시 : 이메일, 연락가능한 전화번호<br/>
                                        - 에스엠게임즈 가입 차단 신청 시: 중복가입확인정보(DI), 암호화된 동일인 식별연계정보
                                        (CI)<br/>
                                        - 사망자 계정 명의변경 시 : (명의 변경 대상자) 성명, 에스엠게임즈 아이디, 생년월일, 성
                                        별, 전화번호(휴대폰번호), 주소, 사망자와의 가족관계/ (계정 포기 대상자)성명, 생년월일,
                                        성별, 주소, 사망자와의 관계
                                    </td>
                                </tr>

                                <tr>
                                    <td>이벤트 경품배송 시</td>
                                    <td>
                                        - 휴대폰번호, 이메일주소, 주소, 이름, 주민등록번호(제세공과금 처리가 필요한 경우에 한함)
                                    </td>
                                </tr>

                            </table>

                        </div>

                        <br/>


                        나. 개인정보 수집방법<br/>
                        회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br/>
                        <ol className="custom_ol">
                            <li>홈페이지, 서면양식, 팩스, 전화, 상담 게시판, 이메일, 이벤트 응모, 배송요청</li>
                            <li>협력회사로부터의 제공</li>
                            <li>생성정보 수집 툴을 통한 수집</li>
                            <br/>

                            <li>필수ㆍ선택 항목 차이</li>
                            <ol>
                                <li>필수 항목 : 기본적인 서비스 제공을 위하여 반드시 필요한 개인정보</li>
                                <li>선택 항목 : 이용자가 선택한 추가 서비스의 제공을 위하여 수집하는 개인정보</li>
                                <br/>
                            </ol>

                            <li>법정대리인의 개인정보를 수집하는 사유</li>
                            <ol>
                                <li>관련 법령에 의하여 아동, 청소년이 에스엠게임즈 서비스를 이용할 경우 법정대리인의 동의를
                                    받아야 합니다. 이를 위하여 법정대리인 의 정보를 수집하고 있습니다.
                                </li>
                            </ol>

                        </ol>
                        <br/>

                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">2. 개인정보의 수집 및 이용목적 </span><br/>
                        회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/>

                        <ol>

                            <li>이용자와 약속한 서비스 제공, 서비스 제공에 따른 본인인증, 구매 및 요금결제, 상품 및 서비스의 배송을 위하
                                여 개인정보를 이용합니다.
                            </li>

                            <li>회원 가입 의사의 확인, 연령 확인 및 법정대리인 동의 진행, 이용자 및 법정대리인의 본인 확인, 이용자 식별,
                                회원탈퇴 의사의 확인, 문의사항 또는 불만처리 등 회원관리를 위하여 개인정보를 이용합니다.
                            </li>

                            <li>연락처 불러오기 시 이용자의 휴대폰번호 및 이용자의 휴대폰에 저장된 연락처 정보를 이용하여 자동 친구 추
                                가 및 친구등록 기능을 제공 하는 데 개인정보를 이용합니다.
                            </li>

                            <li>법령 및 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영
                                에 지장을 주는 행위 및 비인가 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 고지사항 전달, 분쟁 조
                                정을 위한 기록 보존 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
                            </li>

                            <li>인구통계학적 특성에 따른 서비스 제공, 접속 빈도 분석, 기능개선, 서비스 이용에 대한 통계, 서비스 분석 및
                                통계에 기반하여 이용자의 상품 구매 및 서비스 이용 성향, 관심, 이용기록 분석 등을 반영한 신규 서비스 제공
                                (개인 맞춤형 상품 추천 서비스 등 포함) 등에 개인정보를 이용합니다.
                            </li>

                            <li>이벤트 정보, 광고성 정보 제공 등 이벤트 및 프로모션의 목적 등에 개인정보를 이용할 수 있습니다.</li>

                        </ol>
                        <br/>

                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">3. 개인정보의 보유 및 이용기간</span><br/>
                        회사는 원칙적으로 이용자의 개인정보를 회원탈퇴 시까지 보관합니다. 단, 회사는 개인정보 도용 등으로 인한 원
                        치 않는 회원탈퇴 등에 대비하기 위하여 회원탈퇴 요청 후 7일 간 개인정보를 보유합니다. 또한 관련 법령 준수
                        를 위해 수집한 법정대리인 정보는 아동/청소년(미성년)이 성년이 될 때까지 보관됩니다. 그 외에도 다음의 정보
                        에 대해서는 회원탈퇴 후에도 아래의 이유로 명시한 기간 동안 보관합니다.<br/><br/>

                        가. 회사 내부 방침에 의한 정보보유 사유<br/>
                        <ol className="custom_ol">
                            <li>부정이용기록</li>
                            <ol>
                                <li>보존 이유 : 부정 이용 방지</li>
                                <li>보존 기간 : 수집일로부터 1년</li>
                            </ol>
                            <br/>

                            <li>과몰입 이용기록</li>
                            <ol>
                                <li>보존 이유 : 과몰입 이용 방지</li>
                                <li>보존 기간 : 수집일로부터 1년</li>
                            </ol>
                            <br/>

                            <li>본인인증 이용기록</li>
                            <ol>
                                <li>보존 이유 : 본인확인 및 연령확인, 부정가입방지, 고객상담 대응</li>
                                <li>보존 기간 : 회원의 경우 회원 탈퇴 시 까지(단, 수집일로부터 3년 내에 탈퇴 시 수집일로부터 3년간 저장), 비
                                    회원의 경우 수집일로부터 3년(단, 부정가입 시도 가입제한 처리시 ‘중복가입확인정보’는 가입제한 해제시까지
                                    저장), 법정대리인의 경우 청소년이 성년이 되는 시점 또는 회원 탈퇴 시까지
                                </li>
                            </ol>
                            <br/>
                        </ol>

                        나. 관련법령에 의한 정보보유 사유<br/>
                        상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사
                        는 관계법령에서 정한 일정한 기간 동안 회원정보를 보존합니다. 이 경우 회사는 보존하는 정보를 그 보존의 목
                        적으로만 이용하며 보존기간은 아래와 같습니다.<br/><br/>

                        <ol className="custom_ol">
                            <li>서비스 이용에 관한 로그 기록</li>
                            <ol>
                                <li>보존 이유 : 통신비밀보호법</li>
                                <li>보존 기간 : 3개월</li>
                            </ol>
                            <br/>


                            <li>소비자의 불만 또는 분쟁처리에 관한 기록</li>
                            <ol>
                                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                                <li>보존 기간 : 3년</li>
                            </ol>
                            <br/>


                            <li>계약 또는 청약철회 등에 관한 기록</li>
                            <ol>
                                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                                <li>보존 기간 : 5년</li>
                            </ol>
                            <br/>

                            <li>대금결제 및 재화 등의 공급에 관한 기록</li>
                            <ol>
                                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                                <li>보존 기간 : 5년</li>
                            </ol>
                        </ol>
                        <br/>

                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">4. 개인정보의 공유 및 제공 </span><br/>
                        회사는 이용자들의 개인정보를 “2. 개인정보의 수집 및 이용목적”에서 고지한 범위내에서 사용하며, 이용자의 사
                        전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 다
                        만, 아래의 경우에는 예외로 합니다.<br/><br/>

                        <ol className="custom_ol">
                            <li>이용자들이 사전에 동의한 경우</li>
                            <li>서비스 제공에 따른 요금 정산을 위해 필요한 경우</li>
                            <li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
                        </ol>
                        <br/>

                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">5. 개인정보의 처리위탁</span><br/>
                        회사는 원활한 서비스 제공을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시
                        개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 회사의 개인정보 위탁처리 기관 및 위
                        탁업무 내용은 아래와 같습니다.<br/>

                        <table>
                            <tr>
                                <th>수탁업체</th>
                                <th>위탁업무 내용</th>
                                <th>개인정보의 보유 및 이용기간</th>
                            </tr>
                            <tr>
                                <td>KG모빌리언스</td>
                                <td>휴대폰 결제, 무통장 입금, 신용카드 결제및 기타 결제수단 제공</td>
                                <td>회원 탈퇴 시 혹은 위탁계약 종료 시까지</td>
                            </tr>
                            <tr>
                                <td>한국모바일인증㈜</td>
                                <td>휴대폰 인증</td>
                                <td>해당 업체에서 이미 보유 또는 확인 가능한개인정보이기 때문에 별도로 저장하지 않음</td>
                            </tr>
                        </table>
                        <br/><br/>


                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">6. 개인정보 파기절차 및 방법</span><br/>
                        이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.
                        회사의 개인정보 파기절차 및 방법은 다음과 같습니다.<br/><br/>
                        가. 파기절차<br/>
                        이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함)
                        내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩
                        니다.<br/>
                        동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br/><br/>
                        나. 파기방법<br/>
                        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                        전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/><br/>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">7. 이용자 및 법정대리인의 권리와 그 행사방법</span><br/>
                        이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나
                        수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 회원탈퇴(동의 철회 또는
                        개인정보의 삭제)를 요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니
                        다.<br/><br/>
                        이용자 혹은 만 14세 미만 아동의 개인정보 조회 및 수정을 위해서는 ‘개인정보 변경’(또는 ‘회원정보수정’ 등)을,
                        회원탈퇴(동의 철회 또는 개인정보의 삭제)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접
                        열람, 정정 또는 탈퇴(동의 철회 또는 개인정보의 삭제)가 가능합니다.<br/><br/>

                        혹은 개인정보 보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
                        이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또
                        는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지
                        체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br/><br/>
                        회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는“5. 개인정보의 보유 및 이용기간”
                        에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br/><br/>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">8. 개인정보의 안전성 확보조치</span><br/>
                        회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성
                        확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.<br/><br/>
                        가. 개인정보 암호화<br/>
                        회사는 비밀번호 등 개인정보를 법령에서 요구하는 기준에 따라 암호화 처리하고 있으며, 중요한 데이터는 파일
                        및 전송 데이터를 암호화하는 등 별도의 보안 조치를 취하여 이용자의 개인정보를 보호하고 있습니다.에스엠게임
                        즈 회원 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및
                        변경도 비밀 번호를 알고 있는 본인에 의해서만 가능합니다.<br/><br/>
                        나. 해킹 등에 대비한 대책<br/>
                        회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다
                        하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용
                        자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에
                        서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단
                        접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하
                        고 있습니다.<br/><br/>
                        다. 처리 직원의 최소화 및 교육<br/>
                        회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로
                        갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 에스엠게임즈 개인정보 처리방침의 준수를 항상 강조하고
                        있습니다.<br/><br/>
                        라. 개인정보보호전담기구의 운영<br/>
                        회사는 사내 개인정보보호전담기구 등을 통하여 에스엠게임즈 개인정보 처리방침의 이행사항 및 담당자의 준수
                        여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로잡을 수 있도록 노력하고 있습니다.
                        단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사
                        는 일체의 책임을 지지 않습니다.<br/><br/>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">9. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</span><br/>
                        회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키
                        (cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작
                        은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.<br/><br/>

                        가. 쿠키의 사용 목적<br/>
                        <ol className="custom_ol">
                            <li>회사는 쿠키를 이용하여, 이용자들이 이용한 에스엠게임즈의 각 서비스와 이용형태 분석, 회원제 서비스 제공
                                등을 위하여 쿠키를 이용합니다.
                            </li>
                        </ol>
                        <br/>

                        나. 쿠키의 설치/운영 및 거부<br/>
                        <ol className="custom_ol">
                            <li>이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써
                                모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습
                                니다.
                            </li>
                            <li>다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 에스엠게임즈 일부 서비스는 이용에 어려움이 있을 수
                                있습니다.
                            </li>
                            <br/>

                            <li>쿠키 설치 허용 여부를 지정하는 방법</li>
                        </ol>

                        <ol>
                            <li>Chrome 웹 브라우저</li>
                            우측 상단 메뉴 [설정] > [개인 정보 보호 및 보안] > [사이트 설정] > [쿠키 및 사이트 데이터] 설정
                            <li>Edge 웹 브라우저</li>
                            우측 상단 메뉴 [설정] > [쿠키 및 사이트 권한] > [쿠키 및 사이트 데이터 관리 및 삭제] 설정<br/>
                            다. 모바일 서비스에서의 쿠키 설치/운영에 관한 사항<br/>
                            회사는 PC 환경과 동일·유사한 인터넷 환경을 모바일 서비스에서도 제공하기 위해 모바일 기기(예: 스마트폰, 태
                            블릿 PC)에서도 "쿠키(cookie)"를 사용할 수 있습니다.회사는 관련 법령 및 규제기관의 가이드라인에 따라 쿠키를
                            수집하며 단, 이용자의 개인정보를 포함하여 그 자체로 개인을 식별할 수 있는 쿠키는 이용자의 사전 동의 없이
                            임의로 수집하지 않습니다.<br/><br/>
                            모바일 기기에서도 웹 브라우저 설정 기능에서 쿠키 허용 여부를 선택할 수 있습니다. 모바일 기기의 운영체제
                            및 웹 브라우저 종류에 따라 다소 상이할 수 있지만, 대부분의 경우 모바일 웹 브라우저의 환경 설정을 통해 쿠
                            키허용 여부를 결정하거나, 기존의 쿠키 일체를 삭제할 수 있습니다. 다만 쿠키의 저장을 거부할 경우, 로그인이
                            필요한 일부 서비스의 이용에 불편함이 있을 수 있습니다.
                        </ol>
                        <br/>

                        <ol className="custom_ol">
                            <li>쿠키 설치 허용 여부를 지정하는 방법</li>
                        </ol>
                        <ol>
                            <li>Chrome 웹 브라우저</li>
                            우측 상단 메뉴 [설정] > [고급] > [사이트 설정] > [쿠키] 설정<br/>
                            <li>Safari 웹 브라우저</li>
                            [설정] > [Safari] > [모든 쿠키 차단] > 설정<br/><br/>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">10. 온라인 맞춤형 광고에 관한 사항</span><br/>
                        가. 회사는 온라인상의 이용자 행태정보를 수집하고, 이용자 대상 맞춤형 서비스를 제공하고 있습니다.
                        나. 회사는 광고 사업자가 회사 서비스 내 생성정보분석 툴을 통해 온라인상의 이용자 행태정보를 수집하고, 이
                        용자 대상 맞춤형 광고 서비스 전송 목적으로 이용할 수 있도록 허용하고 있습니다.<br/><br/>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">11. 개인정보 보호책임자 및 연락처</span><br/>
                        회사는 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 보호책임자를 지정하고 있고, 연락처는 아래
                        와 같습니다. 이용자는 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호 관련 민원을 개인정보 보호책임
                        자로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.<br/><br/>

                        개인정보 보호책임자<br/>
                        <ol className="custom_ol">
                            <li>이름 : 장호석</li>
                            <li>소속/직위 : 기획조정실/실장</li>
                            <li>E-MAIL : smgame@smgame.co.kr</li>
                            <li>전화번호: 1899-9365</li>
                        </ol>
                        <br/>

                        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/>
                        <ol className="custom_ol">
                            <li>개인정보분쟁조정위원회 (www.kopico.go.kr, 국번없이 1833-6972)</li>
                            <li>개인정보침해신고센터 (privacy.kisa.or.kr, 국번없이 118)</li>
                            <li>대검찰청 사이버수사과 (www.spo.go.kr, 국번없이 1301)</li>
                            <li>경찰청 사이버수사국(ecrm.cyber.go.kr, 국번없이 182)</li>
                        </ol>
                        <br/>

                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">12. 본 개인정보 처리방침의 적용범위</span><br/>
                        에스엠게임즈에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "에스엠게임즈 개인정보
                        처리방침"이 적용되지 않음을 알려 드립니다.<br/><br/>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">13. 고지의 의무</span><br/>
                        현 개인정보 처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항' 등
                        을 통해 고지할 것입니다.<br/><br/>
                        공고일자 : 2024년 2월 1일<br/>
                        시행일자 : 2024년 3월 4일<br/>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2"></span><br/>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default AuthPrivacyPolicy;