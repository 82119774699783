import React, {useEffect} from 'react';
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";

const MobileTesting = () => {
    const navigate = useNavigate();
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const openKMCISWindow = async () => {
        const userAgent = navigator.userAgent;

        // Check if the user agent is a mobile device
        const isMobile = userAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) !== null || userAgent.match(/LG|SAMSUNG|Samsung/) !== null;

        if (isMobile) {
            document.getElementById('KMCISform').target = '';
        } else {
            // const KMCIS_window = window.open('', 'KMCISWindow', 'width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250');
            document.getElementById('KMCISform').submit();
        }

    };


    function action() {
        // window.opener.location.href = "http://example.com";
        // window.close(); // Close the new window
        navigate('/mobile_redirect/?apiToken=test&certNum=test');
    }

    useEffect(() => {
        // openKMCISWindow();
    }, []);

    return (
        <div style={{backgroundColor: "white"}}>
            <form id="KMCISform" method="post" action={baseUrl + 'mobile_verification/testing/'} target="_parent">
                <input type="hidden" name="tr_ver" value="V2"/>
                <button type="submit">submit</button>
            </form>
        </div>

        // <div style={{backgroundColor: "white"}}>
        //     <button type="submit" onClick={action}>submit</button>
        // </div>
    )
        ;
};

export default MobileTesting;
