import React, {useEffect, useState} from 'react';
import $ from 'jquery';

export const CheckPlatform = () =>{
    var message;
    var showCloseBtn;

    if (navigator.userAgent.indexOf("Windows") !== -1){
        message = "ESC 키를 눌러 페이지를 나가실 수 있습니다.";
        showCloseBtn = false;
    }
    else if (navigator.userAgent.indexOf("IPhone") !== -1) {
        message = "닫기 버튼을 눌러 페이지를 나가실 수 있습니다";
        showCloseBtn = true;
    }
    else if (navigator.userAgent.indexOf("Mac OS") !== -1) {
        message = "닫기 버튼을 눌러 페이지를 나가실 수 있습니다";
        showCloseBtn = true;
    }
    else if (navigator.userAgent.indexOf("Android") !== -1) {
        message = "뒤로가기 버튼을 눌러 페이지를 나가실 수 있습니다.";
        showCloseBtn = false;
    } else {
        message = "ESC 키를 눌러 페이지를 나가실 수 있습니다.";
        showCloseBtn = false;
    }

    return [message, showCloseBtn];
};

export const MessagePopupDayLimit = ({message, closeMessagePopup, changeDayLimit}) => {
    return (
        <div id="popup">
            <div className="in_game_popup_dim"/>
            <div className="day_limit_popup_center">
                <div className="message_popup_background">

                    <div id="popupPass">
                        <div className="popup_img_center">
                            <div className="popup_question_img animated shake"/>
                        </div>
                        <div className="popup_message_text" style={{marginTop: '5.9vw'}}>{message}</div>
                    </div>

                    <div className="in_game_submit_btn popup_ok_button" style={{marginRight: '14vw'}}
                         onClick={changeDayLimit}>
                        <span>확 인</span>
                    </div>

                    <div className="in_game_submit_btn popup_ok_button" style={{marginLeft: '14vw'}}
                         onClick={event => closeMessagePopup(false)}>
                        <span>취 소</span>
                    </div>

                </div>
            </div>
        </div>
    );
};


const MessagePopup = ({message, status, closeMessagePopup}) => {
    return (
        <div id="popup">
            <div className="in_game_popup_dim"/>
            <div className="day_limit_popup_center">
                <div className="message_popup_background">

                    { status && (
                        <div id="popupPass">
                            <div className="popup_img_center">
                                <div className={`${status === "question" ? 'popup_question_img':'popup_ok_img'} animated shake`}/>
                            </div>
                            <div className="popup_message_text">{message}</div>
                        </div>
                    )}

                    { !status && (
                        <div id="popupFail">
                            <div className="popup_img_center">
                                <div className="popup_no_img animated shake"/>
                            </div>
                            <div className="popup_message_text">{message}</div>
                        </div>
                    )}

                    <div className="in_game_submit_btn popup_ok_button" onClick={event => closeMessagePopup(false)}>
                        <span>확 인</span>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default MessagePopup;
