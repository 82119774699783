import React, {useEffect, useState} from "react";
import MobileHeader from "./mHeader";
import {useNavigate} from "react-router-dom";
import MobileVerification from "../mobileVerify/mobileVerification";
import $ from 'jquery';
import MobileFooter from "./mFooter";

const MobileFienId = () => {
    const navigate = useNavigate();
    const [requestData, setRequestData] = useState(false);
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');
    const userIDs = params.get('userIDs');

    const mobileVerify = () => {
      setRequestData({
          page: 'mobileFindID',
      });
    };

    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'zoom': 'unset'
        });

        if (page) {
            $('#find_id_cancel_button').css('display', 'none');
            $('#find_id_next_button').css('display', 'none');
            $('#find_id_check_button').css('display', 'block');

            if (userIDs) {
                $('#find_id_text_2').text(userIDs);
            }
            else {
                $('#find_id_text_2').text('가입된 아이디가 없습니다.');
            }

            $('#find_id_text_1').text('아이디 확인 결과');
        }

    }, []);

    return (
        <>
            <MobileHeader/>

            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>로그인</span>
                </div>

                <div className="m_center_warp">
                    <div className="find_id_title_box">
                        아이디 찾기
                    </div>

                    <div className="find_id_box">
                        <div className="find_id_inner">
                            <div className="find_id_mobile_img">
                                <img src={require("../images/membership_img/Icon-mobile.jpg")}/>
                            </div>
                            <em className="find_id_title" id="find_id_text_1">휴대폰 인증<br/></em>
                            <div className="find_id_contents" id="find_id_text_2">휴대폰 인증 후 아이디를 찾으실 수 있습니다.</div>
                        </div>
                    </div>

                    <div className="m_ok_btn_box" style={{display: "flex", justifyContent: "center"}}>
                        <button className="m_ok_no_button" id="find_id_next_button" onClick={mobileVerify}>
                            다음
                            <div className="m_ok_no_button_img"/>
                        </button>

                        <button className="m_ok_no_button" id="find_id_check_button" onClick={() => navigate('/mobile/login')} style={{display: 'none'}}>
                            확인
                            <div className="m_ok_no_button_img"/>
                        </button>

                        <button className="m_ok_no_button" id="find_id_cancel_button" type="button"
                                onClick={() => navigate('/mobile/login')}
                                style={{backgroundColor: '#4e4e4e', marginLeft: '0.4688vw'}}>취소
                            <div className="m_ok_no_button_img"/>
                        </button>
                    </div>

                </div>
            </div>

            {requestData && (<MobileVerification requestData={requestData}/>)}

            <MobileFooter/>
        </>
    )
}

export default MobileFienId