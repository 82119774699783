import SubHeader from "../home/subHeader";
import event_create_account from "../images/membership_img/event_createAccount.png";
import icon_button from "../images/mypage_img/Icon-button.png";
import React, {useEffect, useState} from "react";
import axios from "axios";
import baseUrl from "../BaseUrl";
import SubFooter from "../home/subFooter";
import {useNavigate} from "react-router-dom";

function MembershipCreateOk() {
    const navigate = useNavigate();
    const [welcomeGold, setWelcomeGold] = useState('');
    const [welcomeAl, setWelcomeAL] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(baseUrl + "sign_up_money");
                if (response.status === 200) {
                    setWelcomeGold(response.data.welcome_gold);
                    setWelcomeAL(response.data.welcome_al);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();

        return () => {
            // Cleanup logic if needed
        };
    }, []);

    useEffect(() => {

        window.history.pushState(null, "", window.location.href);

        window.onpopstate = (event) => {
          window.history.pushState(null, "", window.location.href);
        };

    }, []);

    return (
        <>
            <SubHeader/>

            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="TopMysection">
                    <div className="TopMysection_inner">
                        <div className="TopMysection_title">회원가입</div>
                    </div>
                </div>
                {/* style="background-image:url(전채 뒷배경 링크로) */}
                <div id="contents" className="contents" style={{padding: 'unset'}}>
                    <div className="Centerwarp">
                        <div className="Centerwarp_inner">
                            {/* 회원가입 입력 */}
                            <div className="subMaintitleBox">
                                <div className="subMainGrayBox"></div>
                                <div className="subMaintitle"><em>가입완료</em></div>
                            </div>
                            <div className="ok_Box">
                                <div className="ok_TitleBox">
                                    <div className="ok_Title">
                                        <em style={{color: '#15a3e1'}}>에스엠게임즈 회원가입</em><em>이 완료되었습니다.<br/>감사합니다.</em>
                                    </div>
                                </div>
                                <div className="ok_contents">
                                    <img src={event_create_account} className="ok_img"/>
                                    가입 축하금으로 골드머니 <em style={{color: '#c10000'}}><a
                                    style={{
                                        fontFamily: 'Apple SD Gothic Neo',
                                        color: '#c10000'
                                    }}>{welcomeGold}</a>골드</em> 와<br/>
                                    무료머니 <em style={{color: '#c10000'}}><a
                                    style={{fontFamily: 'Apple SD Gothic Neo', color: '#c10000'}}>{welcomeAl}</a>알</em> 을
                                    지급하였습니다.<br/>
                                    회원님께서는 에스엠게임즈에서 제공하는 모든 서비스를 정상적으로<br/>
                                    이용하실 수 있습니다.
                                </div>
                            </div>
                            <div className="oknoBox_button">
                                <button className="okno_button" onClick={() => navigate('/')}>확인
                                    <div className="okno_button_img"><img src={icon_button}/>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SubFooter/>
        </>
    );
}

export default MembershipCreateOk;