import React, {useEffect, useState} from 'react';
import "../css/serviceCenter.css";
import icon_page_first from "../images/servicecenter_img/Icon-page_first.png";
import icon_page_prev from "../images/servicecenter_img/Icon-page_prev.png";
import icon_page_next from "../images/servicecenter_img/Icon-page_next.png";
import icon_page_last from "../images/servicecenter_img/Icon-page_last.png";
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";
import RightGamesWithoutLogin from "../game/rightGamesWithoutLogin";
import SubFooter from "../home/subFooter";
import ServiceCenterHeader from "./serviceCenterHeader";

const getDivisionText = (division) => {

    switch (division) {
        case 0:
            return '[공지]';
        case 1:
            return '[이벤트]';
        case 2:
        case 3:
        case 4:
            return '[점검]';
        default:
            return '';
    }
};


const ServiceNotices = () => {
    const [notices, setNotices] = useState([]);
    const [searchNotices, setSearchNotices] = useState('');
    const navigate = useNavigate();

    const handlePageChange = (page) => {
    };

    const serviceCenter = (noticeId) => {
        navigate(`/service_center/notice_content?notice_id=${noticeId}`);
    };

    const noticeData = async () => {
        try {
            const response = await axios.get(baseUrl + 'home/notice/');
            if (response.status === 200) {
                setNotices(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handelSearchNotice = (e) => {
        setSearchNotices(e.target.value);
    };

    const searchNoticeData = async () => {
        try {
            const response = await axios.get(baseUrl + 'home/notice_search/?search='+searchNotices );
            if (response.status === 200) {
                setNotices(response.data.results);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        noticeData();
    }, []);


    return (<>
            <ServiceCenterHeader category="notice"/>

            <div className="ServiceCenterWarpMain">
                <div className="ServiceGameCenterWarp">
                    <div className="GameCenterwarp_inner">
                        <div className="subMaintitleBox">
                            <div className="subMainGrayBox"/>
                            <div className="subMaintitle">공지사항</div>

                            <div className="notice_search_box">
                                <div className="notice_input_box">
                                    <input
                                        type="text"
                                        id="SerchText"
                                        name="Serch"
                                        placeholder="검색어를 입력하세요"
                                        autoComplete="off"
                                        value={searchNotices}
                                        onChange={handelSearchNotice}
                                    />
                                </div>

                                <button type="button" className="notice_search_button" onClick={searchNoticeData}>
                                    검색
                                </button>

                            </div>

                            <div className="subMainDepth">고객센터 > 공지사항</div>
                        </div>


                        <div className="notice_contents_box">
                            {notices.map((notice) => (
                                <div
                                    key={notice.id}
                                    className="notice_contents"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => serviceCenter(notice.notice_id)}
                                >
                                    <div className="notice_contents_inner">
                                        <div className="notice_contents_title">
                                            <em>{getDivisionText(notice.division)}{notice.title}</em>
                                        </div>
                                        <div className="notice_contents_admin">
                                            <em>{notice.admin}</em>
                                        </div>
                                        <div className="notice_contents_date">
                                            <em>{notice.date.split('T')[0]}</em>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>


                        <div className="notice_paging_box">

                            <div className="service_paging_button">
                                <div onClick={handlePageChange} style={{borderRight: '1px solid #dbdbdb'}}>
                                    <img src={icon_page_first} alt=""/>
                                </div>
                                <div onClick={handlePageChange} style={{borderRight: '1px solid #dbdbdb'}}>
                                    <img src={icon_page_prev} alt=""/>
                                </div>
                            </div>


                            <button className="paging_number_button" onClick={() => handlePageChange(1)}>
                                1
                            </button>

                            <div className="service_paging_button">
                                <div onClick={handlePageChange} style={{borderLeft: '1px solid #dbdbdb'}}>
                                    <img src={icon_page_next} alt=""/>
                                </div>
                                <div onClick={handlePageChange} style={{borderLeft: '1px solid #dbdbdb'}}>
                                    <img src={icon_page_last} alt=""/>
                                </div>
                            </div>

                        </div>
                    </div>

                    <RightGamesWithoutLogin/>
                </div>
            </div>

            <SubFooter/>
        </>

    );
};

export default ServiceNotices;
