import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import baseUrl from "../BaseUrl";
import $ from "jquery";

const MobileHeader = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [isLogin, setIsLogin] = useState(false);

    const navigate = useNavigate();


    function logout() {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
        setShowPopup(!showPopup);
        alert("로그아웃되었습니다.");
    }

    const verifyToken = async () => {
        try {
            const response = await axios.get(baseUrl + 'verify_token', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            });

            if (response.status === 200) {
                setIsLogin(true);
            }
        } catch (e) {
            setIsLogin(false);
        }
    };

    const menuPopup = () => {
        // $('html').css('overflow', showPopup ? 'scroll': 'hidden');
        setShowPopup(!showPopup);
    };

    const openPage = (page) => {
        setShowPopup(false);
        window.location.href = `/mobile/${page}`;
    };


    useEffect(() => {
        verifyToken();
        function handleScroll() {
            const header = document.getElementById("header-id");
            const stickyHeight = header.offsetTop;
            if (window.pageYOffset > stickyHeight) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>

            <div style={{display: showPopup ? 'block' : 'none'}}>
                <div className="m_popup_dim_box" onClick={menuPopup}/>
                <div className="m_menu_popup_box">
                    <div className="m_popup_close_img" onClick={menuPopup}/>
                    <div className="m_popup_contents_box">
                        <div onClick={event => openPage('all_games')}>전체게임</div>
                        <div onClick={event => openPage('shop')}>상점</div>
                        <div onClick={event => openPage('qa')}>고객센터</div>
                        {!isLogin && (
                            <>
                                <div onClick={() => openPage('membership')}>회원가입</div>
                                <div onClick={() => openPage('login')}>로그인</div>
                            </>

                        )}
                        {isLogin && (
                            <>
                                <div onClick={() => openPage('bag')}>내지갑</div>
                                <div onClick={logout}>로그아웃</div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div id="header-id" className={isSticky ? "m_header_sticky" : "m_header"}>
                <div className="m_contents">
                    <div className="m_sm_logo" onClick={event => navigate('/mobile')}/>
                    <div className="m_menu_img" onClick={menuPopup}>
                    </div>
                </div>
            </div>
        </>

    );
}

export default MobileHeader;