import React, {useEffect} from 'react';
import icon_won from '../images/Icon-won.png';

import {getAvatars} from "../shop/avatar";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import baseUrl from "../BaseUrl";
import $ from "jquery";
import popup_icon from "../images/dualgo_ingame_img/ingame_popupNO.png";

function MobileShopAvatar() {
    const navigate = useNavigate();
    var gender = localStorage.getItem('gender');

    const params = new URLSearchParams(window.location.search);
    var page = params.get('page');
    var redirect = params.get('redirect');


    var shopAvatars;

    if (!gender){
        gender = 'y';
    }

    if (page === "smpl_avatars") {
        $('#smpl_avatars').css('color', '#15a3e1');
        shopAvatars = getAvatars(page, gender);
    } else {
        page = "main_avatars";
        $('#main_avatars').css('color', '#15a3e1');
        shopAvatars = getAvatars(page, gender);
    }

    function numberToKorean(number) {
        var inputNumber = number < 0 ? false : number;
        var unitWords = ['', '만', '억', '조', '경'];
        var splitUnit = 10000;
        var splitCount = unitWords.length;
        var resultArray = [];
        var resultString = '';

        for (var i = 0; i < splitCount; i++) {
            var unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
            unitResult = Math.floor(unitResult);
            if (unitResult > 0) {
                resultArray[i] = unitResult;
            }
        }

        for (var i = 0; i < resultArray.length; i++) {
            if (!resultArray[i]) continue;
            resultString = String(resultArray[i]) + unitWords[i] + resultString;
        }

        return resultString;
    }

    function numberFormat(number) {
        return Intl.NumberFormat().format(number);
    }

    const verifyToken = async () => {
        try {
             await axios.get(baseUrl + 'verify_token', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            });
        } catch (e) {
            window.location.href = '/mobile/login';
        }
    };


    function buyAvatar(avatarId, reward, price, avatarName) {
        localStorage.setItem('avatarId', avatarId);
        localStorage.setItem('reward', reward);
        localStorage.setItem('avatarPrice', price);
        localStorage.setItem('avatarName', avatarName);
        if (sessionStorage.getItem('token')){
            verifyToken();
            window.location.href = '/mobile/buy_avatar_web';
        } else {
            navigate('/mobile/login', {state:{redirect: `/mobile/shop/?page${page}&redirect=true`}});
        }
    }

    const closePopup = () =>{
        $("#messagePopup").css('display', 'none');
        navigate('/mobile/shop');
    };

    useEffect(() => {
        if (redirect && gender === 'n'){
            $("#messagePopup").css('display', 'block');
        }
    }, []);

    return (
        <>
            <button className="m_tab_links_a active" onClick={() => window.location.href = "/mobile/shop"}>아바타</button>
            <button className="m_tab_links_a active" style={{marginRight: '-0.6vw', float: 'right'}}
                    onClick={() => window.location.href = "/mobile/shop/?page=smpl_avatars"}>텍사스홀덤(SMPL)
            </button>

            <div className="m_shop_section_2">
                <div className="tab_box">
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div id="tab2" className="m_shop_tab_content">
                            {shopAvatars.map((item, index) => {
                                const avatarId = item[0];
                                const avatarName = item[1];
                                const price = item[2];
                                const reward = item[3];

                                return (
                                    <div key={index} className="m_shop_col_type1">
                                        <a target="_self">
                                            <div className="card card_square">
                                            <span className="m_card_img_box_game"
                                                  onClick={() => buyAvatar(avatarId, reward, price, avatarName)}>
                                                <img loading="lazy"
                                                     src={require('../images/shop_img/' + avatarId + '.gif')} alt=""/>
                                            </span>
                                                <em className="m_shop_card_tit">{avatarName}</em>
                                                <span
                                                    className="shop_card_txt m_shop_card_txt">({numberToKorean(reward)}골드 지급)</span>
                                                <div className="card_price m_card_price">
                                                    <img loading="lazy" src={icon_won} style={{
                                                        padding: '6px 3px',
                                                        width: '2.1875vw',
                                                        height: '2.1875vw'
                                                    }}/>
                                                    {numberFormat(price)}원
                                                </div>
                                                <div onClick={event => buyAvatar(avatarId, reward, price, avatarName)}>
                                                    <button className="m_buy_button">구매</button>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                );

                            })}
                        </div>
                    </div>

                    <div className="shop_tab_center_bar" style={{position: 'relative', height: '10px'}}></div>


                    {!gender && (
                        <div id="tab1" className="tabcontentGame">
                            <div className="m_shop_card_tit"
                                 style={{width: '100%', margin: '180px auto', textAlign: 'center'}}>로그인 후 이용해주세요.
                            </div>
                        </div>
                    )}
                    <div id="tab4" className="tabcontentGame" style={{display: 'none'}}></div>
                </div>
            </div>


            <div id="messagePopup" className="m_shop_popup">
                <div className="m_my_wallet_popup_content">
                    <div className="message-popup-content" style={{marginTop: '0'}}>
                        <img src={popup_icon} className="animated shake" alt="OK"/>
                        <span style={{fontSize: '4vw', marginTop: '6vw'}}>구매할 수 없는 상품입니다.</span>
                    </div>
                    <div onClick={closePopup} className="message-pass-btn-web">
                        확 인
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileShopAvatar;
