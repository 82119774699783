import React, {useEffect, useState} from "react";
import "../css/gameGuide.css";
import $ from 'jquery';

const InstallAndRun = () => {
  return (
      <div className="guide_right_content guide_right_content_ol_img">
          <ol style={{marginLeft: '10px'}}>
              <img src={require("../images/baduki_guide_img/start_01.png")}/>
              <div className="guide_right_txt_content">
                  <li>
                      에스엠 게임즈 홈페이지에서 게임 시작 버튼을 눌러, SM 바둑이 클라이언트 다운로드를 진행합니다.
                  </li>
              </div>

              <img src={require("../images/baduki_guide_img/start_02.png")}/>
              <div className="guide_right_txt_content">
                  <li>
                      다운로드된 프로그램을 안내에 따라 설치해주세요.
                  </li>
              </div>

              <img src={require("../images/baduki_guide_img/start_03.png")}/>
              <div className="guide_right_txt_content">
                  <li>
                      클라이언트를 실행하여, 로그인 화면에서 아이디와 비밀번호를 입력하고 대기실로 이동합니다.
                  </li>
              </div>


              <img src={require("../images/baduki_guide_img/start_04.png")}/>
              <div className="guide_right_txt_content">
                  <li>
                      대기실에서 게임 방을 생성하시거나, 방에 입장하여 SM 7포커를 즐겨주세요!
                  </li>
              </div>

          </ol>

      </div>
  );

};

const MakingRoom = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/baduki_guide_img/start_05.png")}/>

            <div className="guide_right_bullet_txt">
                <div>
                    <span className="guide_bullet_img"></span>
                    대기실에서 원하는 설정에 따라, 게임 방을 직접 만들 수 있습니다.
                </div>

                <div>
                    <span className="guide_bullet_img"></span>
                    방 만들기는 아래와 같은 기능으로 구성되어 있습니다.
                </div>
            </div>

            <ol>
                <div className="guide_right_txt_content making_room">
                    <li>
                         방 제목 : 설정된 방 제목 중, 원하는 방 제목을 간편하게 선택할 수 있습니다.
                    </li>

                    <li>
                         직접입력 : 원하는 방 제목을 직접 입력할 수 있습니다.
                    </li>

                    <li>
                         시드머니 : 베팅에 사용되는 기본 머니를 설정합니다. 채널에 따라 다릅니다.
                    </li>

                    <li>
                         최대인원 : 게임에 참여할 수 있는 최대 인원을 설정합니다.
                    </li>

                    <li>
                        베팅 룰 : 베팅 룰을 설정할 수 있습니다.
                    </li>

                    <li>
                        확인 : 설정한 조건으로 게임 방을 생성합니다.
                    </li>

                    <li>
                        취소 : 방 생성을 취소하고, 대기실 화면으로 이동합니다.
                    </li>

                </div>

            </ol>

        </div>
    );
}


const DirectEntry = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/baduki_guide_img/start_06.png")}/>

            <div className="guide_right_bullet_txt">
                <div>
                    <span className="guide_bullet_img"></span>
                    바로 입장 버튼을 클릭하여 생성된 방에 원하는 조건에 맞추어 즉시 입장이 가능합니다.
                </div>

                <div>
                    <span className="guide_bullet_img"></span>
                    바로 입장은 아래와 같은 기능으로 구성되어 있습니다.
                </div>
            </div>

            <ol>
                <div className="guide_right_txt_content making_room">
                    <li>
                        활성화 시드머니 : 해당 시드머니 값으로 설정된 방이 존재하고 있음을 나타냅니다.<br/>
                        활성화된 시드머니 클릭 시, 해당 시드머니 방으로 입장합니다.
                    </li>

                    <li>
                        비활성화 시드머니 : 해당 시드머니 값으로 설정된 방이 존재하고 있지 않음을 나타냅니다.
                    </li>

                    <li>
                        취소 : 방 입장을 취소하고, 대기실 화면으로 이동합니다.
                    </li>

                </div>

            </ol>


            <img src={require("../images/baduki_guide_img/start_07.png")}/>

            <div className="guide_right_bullet_txt">
                <div>
                    <span className="guide_bullet_img"></span>
                    만약 입장 가능한 방이 없는 상태에서 입장 버튼을 클릭 시, 위와 같은 팝업이 노출됩니다.
                </div>
            </div>

        </div>
    );
}


const UseGameRoom = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/baduki_guide_img/start_08.png")}/>

            <ol>
                <div className="guide_right_txt_content making_room">
                    <li>
                        아침, 점심, 저녁 상태를 한 눈에 확인할 수 있습니다.
                    </li>

                    <li>
                        현재 진행 중인 게임 판에서 누적된 금액이 보여지는 부분입니다.
                    </li>

                    <li>
                        본인이 베팅할 최소 Call 금액이 노출됩니다.
                    </li>

                    <li>
                       상대 플레이어가 아침, 점심, 저녁에 각각 바꾼 카드 횟수를 보여주는 부분입니다.
                    </li>

                    <li>
                        상대 플레이어의 패, 보유 머니가 보여지는 부분입니다.
                    </li>

                    <li>
                        상대 플레이어의 아바타, 닉네임이 보여지는 부분입니다.
                    </li>

                    <li>
                         채팅, 족보 정보, 경기 히스토리를 확인할 수 있는 부분입니다.
                    </li>

                    <li>
                        플레이어 아바타, 닉네임이 노출되는 부분입니다.
                    </li>

                    <li>
                         본인이 아침, 점심, 저녁에 바꾼 카드 수가 보여지는 부분입니다.
                    </li>

                    <li>
                        플레이어 패, 보유 머니가 보여지는 부분입니다.
                    </li>

                    <li>
                        베팅 버튼입니다. 내 턴에 원하는 베팅을 할 수 있습니다.
                    </li>

                    <li>
                        게임 판에서 나와 대기실로 이동합니다.
                    </li>
                </div>

            </ol>

        </div>
    );
}


const HowToPlay = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/baduki_guide_img/guide_14.png")}/>

            <div className="guide_right_bullet_txt">
                <div>
                    <span className="guide_bullet_img"></span>
                    SM 바둑이는 최소 2명이 모이면 게임을 시작합니다.
                </div>

                <div>
                    <span className="guide_bullet_img"></span>
                    SM 바둑이 딜러비는 4% 입니다.
                </div>
            </div>


            <ol>
                <div className="guide_right_txt_content making_room">
                    <li>
                        총 4장의 카드를 가지고 순서에 따라 베팅합니다.
                    </li>

                    <li>
                        플레이어는 자신의 카드만 볼 수 있고, 다른 플레이어의 카드는 볼 수 없습니다. 이 상태에서 한 번의
                        베팅을 진행합니다.
                    </li>

                    <li>
                        베팅이 모두 끝났으면 자신의 카드를 한 번 바꿀 수 있습니다. (최소 0, 최대 4장까지 바꾸기 가능),
                        이 과정을 아침이라 합니다.
                    </li>

                    <li>
                        아침 종료 후, 베팅을 다시 진행하며 베팅이 끝나면 자신의 카드를 또 바꿀 수 있습니다. 이 과정을 점심이라 합니다.
                    </li>

                    <li>
                        점심 종료 후, 베팅을 다시 진행하며 베팅이 끝나면 자신의 카드를 또 바꿀 수 있습니다. 이 과정을 저녁이라 합니다.
                    </li>

                    <li>
                        저녁 후 마지막 베팅 진행 후, 마지막 레이스에 참여한 모두가 자신의 패를 보여주어 승자를 가립니다.
                    </li>

                </div>

            </ol>

        </div>
    );
}


const AdditionalRules = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/baduki_guide_img/guide_15.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">카드 교환</em>
                </div>

                <div className="guide_right_bullet_txt">
                    <div>
                        <span className="guide_bullet_img"></span>
                        좋은 패를 갖기 위해, 아침, 점심, 저녁에 [바꾸기] 버튼을 눌러 원하는 수만큼 카드를 바꿀 수 있습니다.
                    </div>

                    <div>
                        <span className="guide_bullet_img"></span>
                        바꾸려는 카드를 선택하고 [바꾸기] 버튼을 클릭합니다.
                    </div>
                </div>


                <ol className="guide_additional_rules">
                    <li>
                        Tips!. 참여자의 카드 바꾸기 패턴을 유심히 살펴보고, 승패의 판단이 서거나 6메이드 이상의 패를 들었다면 승부를 걸만 합니다.
                    </li>

                    <li>
                        이런 경우, [패스] 버튼을 클릭하여 카드를 교환하지 않습니다.
                    </li>

                    <li>
                        아주 좋은 족보를 만드려고 너무 집착하여 무리한 바꾸기를 시도하면, 오히려 나쁜 패를 받을 수 있습니다.
                    </li>
                </ol>
            </div>
        </div>
    );
}


const HowToBet = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/baduki_guide_img/progress_01.png")}/>

            <div className="guide_right_txt_content" style={{padding: '5px 0 10px 0'}}>
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">베팅방법</em>
                </div>

                <div className="guide_right_bullet_txt" style={{padding: 'unset'}}>
                    <div>
                        <span className="guide_bullet_img"></span>
                        기본적인 게임 체계와 베팅 방법은, 다른 포커류 게임과 동일합니다.
                    </div>

                    <div>
                        <span className="guide_bullet_img"></span>
                        단축키를 사용하여 보다 원활한 게임을 즐길 수 있습니다.
                    </div>
                </div>

            </div>


            <div className="guide_right_txt_content" style={{lineHeight: 2}}>
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">체크[키보드 단축키 5] </em>
                    <em>: 베팅하지 않고 차례를 넘깁니다.</em>
                </div>

                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">하프[키보드 단축키 0] </em>
                    <em>: 전체 판돈의 절반, 즉 50% 금액을 베팅합니다.</em>
                </div>

                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">콜[키보드 단축키 7] </em>
                    <em>: 앞 사람이 베팅한 금액과 동일한 금액을 베팅합니다.</em>
                </div>

                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">삥[키보드 단축키 4] </em>
                    <em>: 기본 판돈만 베팅합니다.</em>
                </div>

                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">따당[키보드 단축키 1] </em>
                    <em>: 앞 사람이 베팅한 금액의 2배를 베팅합니다.</em>
                </div>

                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">다이[키보드 단축키 8] </em>
                    <em>: 베팅하지 않고, 이번 판을 포기합니다.</em>
                </div>

            </div>

            <div className="guide_baduki_how_to_bet">
                <div className="guide_right_txt_content" style={{marginLeft: '21px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">베팅 룰</em>
                    </div>

                    <em style={{lineHeight: 2}}>
                        - 방을 생성할 때 베팅 룰을 선택할 수 있으며, 세부 내용은 아래와 같습니다.<br/>

                        1. 선택 안함 : 베팅 룰을 선택하지 않고 게임을 진행합니다.<br/>

                        2. 하프 1 : 아침 전 첫 베팅에서 첫 번째 플레이어는 하프 또는 다이만 선언할 수 있습니다.<br/>

                        3. 하프 2 : 아침 전 첫 베팅에서 두 번째 플레이어까지 하프 또는 다이만 선언할 수 있습니다.<br/>

                        4. 하프 3 : 아침 전 첫 베팅에서 세 번째 플레이어까지 하프 또는 다이만 선언할 수 있습니다.<br/>

                        5. 하프 4 : 아침 전 첫 베팅에서 네 번째 플레이어까지 하프 또는 다이만 선언할 수 있습니다.<br/>

                        6. 하프 5 : 아침 전 첫 베팅에서 모든 플레이어가 하프 또는 다이만 선언할 수 있습니다.<br/>
                    </em>
                </div>
            </div>


        </div>
    );
}


const Pedigree = () => {
    return (
        <div className="guide_right_content">
            <div className="guide_right_bullet_txt">
                <div>
                    <span className="guide_bullet_img"></span>
                    최고의 패는 1-2-3-4(골프) 이며, 최악의 패는 K-K-K-K 다!
                </div>

                <div className="guide_bullet_txt_container">
                    <span className="guide_bullet_img guide_bullet_img_top"></span>
                    <em>SM 바둑이에서는 기본적으로 카드 4장의 무늬와 숫자가 모두 다른 상황에서 그중 제일 큰 숫자가 작으면 작을수록
                        좋은 족보입니다. 족보는 아래와 같고 위에 있는 것일수록 좋은 패입니다.</em>
                </div>

                <div>
                    <span className="guide_bullet_img"></span>
                    <em>같은 종류의 족보가 나왔을 경우에는 가장 높은 숫자부터 차례대로 비교해서 낮은 숫자를 가진 쪽이 이깁니다.</em>
                </div>

                <div className="guide_bullet_txt_container">
                    <span className="guide_bullet_img guide_bullet_img_top"></span>
                    <em>카드의 무늬에 따른 우열은 없습니다. 따라서 우열을 가릴 수 없는 경우가 나올 수도 있으며, 이때에는 우승자가
                        여러 명이 되어 판돈을 나누어 갖습니다.</em>
                </div>

                <div className="guide_bullet_txt_container">
                    <span className="guide_bullet_img guide_bullet_img_top"></span>
                    <em>메이드는 4장의 카드에 새겨져 있는 무늬와 숫자가 모두 다른 패로서 로우바둑이의 족보에서 가장 높은 족보입니다.
                        가장 좋은 패 3가지를 순서대로 골프, 세컨드, 써드라고 합니다.</em>
                </div>
            </div>

            <img src={require("../images/baduki_guide_img/progress_13.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">골프</em>
                    <div>
                        무늬가 모두 다르면서 A, 2, 3, 4로 이루어진 골프입니다.<br/>
                        로우 바둑이에서 가장 높은 족보입니다.
                    </div>
                </div>
            </div>


            <img src={require("../images/baduki_guide_img/progress_14.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">세컨드</em>
                    <div>
                        무늬가 모두 다르면서 A, 2, 3, 5로 이루어진 패로서<br/>
                        두번째로 높은 족보입니다.
                    </div>
                </div>
            </div>


            <img src={require("../images/baduki_guide_img/progress_15.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">써드</em>
                    <div>
                        무늬가 모두 다르면서 A, 2 ,4, 5로 이루어진 패로서<br/>
                        세번째로 높은 족보입니다.
                    </div>
                </div>
            </div>

            <div className="guide_bullet_txt_container">
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em>베이스는 3장의 카드에 새겨져 있는 무늬와 숫자가 모두 다르지만, 나머지 한 장의 카드가 다른 세 카드의 무늬 또는 숫자와
                    겹치는 패입니다.</em>
            </div>

            <div className="guide_bullet_txt_container">
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em>무늬가 겹치는 경우는 겹치는 두 카드 중 숫자가 큰 것을 제외하고 나머지 3장의 카드로 상대방의 베이스와 우열을 가립니다.</em>
            </div>

            <div className="guide_bullet_txt_container" style={{marginBottom: '40px'}}>
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em>숫자가 겹치는 경우는 로우바둑이에서 무늬의 우열이 없기 때문에 다른 베이스와 우열을 가릴 때 상관이 없습니다.</em>
            </div>


            <img src={require("../images/baduki_guide_img/progress_16.png")}/>

            <div className="guide_bullet_txt_container" style={{marginBottom: '40px'}}>
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <span style={{color: '#603513'}}>숫자가 중복되는 경우의 예</span>
            </div>


            <img src={require("../images/baduki_guide_img/progress_17.png")}/>

            <div className="guide_bullet_txt_container">
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <span style={{color: '#603513'}}>무늬가 중복되는 경우의 예</span>
            </div>
            <br/>

            <div className="guide_bullet_txt_container">
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em>투베이스는 4장의 카드가 두 가지 종류의 무늬나 두 가지 종류의 숫자로만 이루어져 있는 패입니다.</em>
            </div>

            <div className="guide_bullet_txt_container">
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em> 다른 투베이스와 비교할 때는 서로 다른 무늬가 되면서 최소의 숫자들이 나오는 두 장의 카드를 뽑아서 비교하게 됩니다.</em>
            </div>

            <div className="guide_bullet_txt_container" style={{marginBottom: '40px'}}>
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em>아래 경우는 무늬가 ♣와 ◆의 두 종류 밖에 없습니다.</em>
            </div>


            <img src={require("../images/baduki_guide_img/progress_18.png")}/>

            <div className="guide_bullet_txt_container" style={{marginBottom: '40px'}}>
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em>◆중에서 가장 작은 숫자가 7이므로 ♣ 4 ◆ 7 투베이스가 되어 다른 투베이스와 우열을 가립니다.</em>
            </div>


            <img src={require("../images/baduki_guide_img/progress_19.png")}/>

            <div className="guide_bullet_txt_container">
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em>무늬가 모두 다르긴 하지만 숫자가 2와 Q 두 종류 밖에 없습니다.</em>
            </div>

            <div className="guide_bullet_txt_container" style={{marginBottom: '40px'}}>
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em>바둑이에서는 무늬에 따른 우열은 없으므로 어느 무늬이든지 2, Q 두 장으로 <br/>
                    다른 투베이스와 숫자 비교를 하여 우열을 가립니다.</em>
            </div>


            <img src={require("../images/baduki_guide_img/progress_20.png")}/>

            <div className="guide_bullet_txt_container">
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em>무늬가 ♣와 ◆의 두 종류, 숫자가 4, 8, J가 있습니다.</em>
            </div>

            <div className="guide_bullet_txt_container" style={{marginBottom: '40px'}}>
                <span className="guide_bullet_img guide_bullet_img_top"></span>
                <em>바둑이에서는 무늬에 따른 우열은 없으므로 작은 숫자 ◆ 4, ♣ 8 두 장으로 다른 투베이스와 숫자 비교를 하여 우열을
                    가립니다.</em>
            </div>

        </div>
    );
}

const Jackpot = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/baduki_guide_img/elements_05.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">잭팟</em>
                    <div className="guide_blt_line_hiegth">
                        골프로 승리할 경우, 특별히 지급되는 축하 머니입니다.<br/>
                        누적되어 있는 잭팟 금액을 받을 수 있습니다.
                    </div>
                </div>
            </div>
        </div>
    );
}


const RankAndMoney = () => {
    const GradeBox = ({title, range}) => (
        <div className="guide_grade_box">
            <div className="guide_grade_inner_box">{title}</div>
            <div className="guide_grade_inner_box_2">{range}</div>
        </div>
    );

    return (
        <div className="guide_right_content">
            <div className="guide_right_txt_content">
                <em>· SM게임즈에서 골드(골드)은 바둑이, 7포커, 신맞고에 공통으로 적용됩니다.<br/>
                    · 보유하고 있는 골드(골드)에 따라 등급이 결정됩니다.<br/>
                    · 게임에 참여하여 골드(골드)을 획득하거나 아바타 구입 후 골드(골드)을 획득하여도 등급이 올라갑니다.<br/>
                    · 10,000전(실버)은 1골드(골드)의 가치를 지닙니다.<br/>
                    · 등급에 따라 즐길 수 있는 게임이 다릅니다.<br/>
                    · 실버채널에서는 전(실버)머니로 표기되며 하수 이하만 입장할 수 있고 그 외 중수 이상<br/>
                      &nbsp;&nbsp;&nbsp;게임장에서는 골드(골드)으로 표기됩니다.
                </em>
            </div>

            <div className="gradeBox" style={{ borderTop: '2px solid #603512' }}>
            <GradeBox title="초　보" range="0골드 ~ 999골드" />
            </div>
            <GradeBox title="하　수" range="1천골드 ~ 9999골드" />
            <GradeBox title="중　수" range="1만골드 ~ 9만9999골드" />
            <GradeBox title="고　수" range="10만골드 ~ 99만9999골드" />
            <GradeBox title="영　웅" range="100만골드 ~ 999만9999골드" />
            <GradeBox title="지　존" range="1천만골드 ~ 4999만9999골드" />
            <GradeBox title="초　인" range="5천만골드 ~ 9999만9999골드" />
            <GradeBox title="제　왕" range="1억골드 ~ 4억9999만9999골드" />
            <GradeBox title="도　신" range="5억골드 ~ 9억9999만9999골드" />
            <GradeBox title="신" range="10억골드 ~" />
            <br /><br />

        </div>
    );
}


const Channel = () => {
    return (
        <div className="guide_right_content">
            <img src={require("../images/baduki_guide_img/elements_01.png")}/>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">무료 채널</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        등급에 제한 없이, 모든 등급이 이용할 수 있는 채널입니다.
                    </li>
                    <li>
                        매일 무료로 지급되는 알을 사용하여, 게임을 즐길 수 있습니다.
                    </li>
                    <li>
                        1회 베팅 한도가 적용되지 않습니다.
                    </li>
                </ol>
            </div>


            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">실버 채널</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        보유 머니가 1억골드 미만 인 경우만 접속이 가능한 채널입니다.
                    </li>
                    <li>
                        전용 단위인 실버 을 이용해 게임을 즐기실 수 있습니다.
                    </li>
                    <li>
                        1만실버 = 1골드의 가치를 지니며, 보유 골드가 실버로 자동으로 환산됩니다.
                    </li>
                </ol>
            </div>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">친구 채널</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        등급에 제한 없이, 모든 등급이 이용할 수 있는 채널입니다.
                    </li>
                    <li>
                        골드를 사용하며, 본인의 보유머니 이상으로 금액을 획득할 수 없습니다.
                    </li>
                </ol>
            </div>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">골드1 채널</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        보유 머니가 1억골드 이상 인 경우만 접속이 가능한 채널입니다.
                    </li>
                    <li>
                        골드를 사용하며, 본인의 보유머니 이상으로 금액을 획득할 수 없습니다.
                    </li>
                </ol>
            </div>

            <div className="guide_right_txt_content">
                <div>
                    <span className="guide_bullet_txt_img"></span>
                    <em className="guide_bullet_txt">골드2 채널</em>
                </div>

                <ol className="guide_additional_rules">
                    <li>
                        보유 머니가 5억골드 이상 인 경우만 접속이 가능한 채널입니다.
                    </li>
                    <li>
                        골드를 사용하며, 본인의 보유머니 이상으로 금액을 획득할 수 없습니다.
                    </li>
                </ol>
            </div>


        </div>
    );
}

const Safe = () => {
  return (
      <div className="guide_right_content">
          <img src={require("../images/baduki_guide_img/elements_02.png")}/>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_txt_img"></span>
                  <em className="guide_bullet_txt">입금</em>
              </div>

              <ol className="guide_additional_rules">
                  <li>
                      현재 본인이 보유한 머니(알, 골드)을 금고에 입금할 수 있습니다.
                  </li>
                  <li>
                      본인이 소지한 머니 이상 입금은 불가능합니다.
                  </li>
              </ol>
          </div>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_txt_img"></span>
                  <em className="guide_bullet_txt">출금</em>
              </div>

              <ol className="guide_additional_rules">
                  <li>
                      현재 본인이 금고에 입금한 머니를 출금할 수 있습니다.
                  </li>
                  <li>
                      본인이 입금해둔 금액 이상의 출금은 불가능합니다.
                  </li>
              </ol>
          </div>

      </div>
  );
}

const FreeRecharge = () => {
  return (
      <div className="guide_right_content">
          <img src={require("../images/baduki_guide_img/elements_03.png")}/>

          <div className="guide_right_bullet_txt">
              <div>
                  <span className="guide_bullet_img"></span>
                  머니가 올인되어도 걱정하지 마세요! 무료 머니를 제공해드립니다.
              </div>
          </div>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_txt_img"></span>
                  <em className="guide_bullet_txt">골드 무료충전</em>
              </div>

              <ol className="guide_additional_rules">
                  <li>
                      골드 무료충전은 금고, 적립금을 제외하고 보유한 골드가 500골드 이하일 때 충전할 수 있습니다.
                  </li>
                  <li>
                      1회 5,000 골드만큼 충전되며, 1일 5회만큼 제공됩니다.
                  </li>
              </ol>
          </div>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_txt_img"></span>
                  <em className="guide_bullet_txt">알 무료충전</em>
              </div>

              <ol className="guide_additional_rules">
                  <li>
                      알 무료충전은 금고, 적립금을 제외하고 보유한 알이 100만알 이하일 때 충전할 수 있습니다.
                  </li>
                  <li>
                      1회 300만알 충전되며, 1일 5회만큼 제공됩니다.
                  </li>
              </ol>
          </div>

          <div className="guide_right_txt_content">
              <div>
                  <span className="guide_bullet_img"></span>
                  무료충전은 24시간 기준으로 매일 오전 0시에 갱신됩니다.
              </div>
          </div>

      </div>
  );
}


const DalerFee = () => {
    return (
        <div className="guide_right_content">
            <div className="guide_right_bullet_txt">
                <div>
                    <span className="guide_bullet_img"></span>
                    딜러비는 일종의 게임 이용료를 의미합니다.
                </div>

                <div>
                    <span className="guide_bullet_img"></span>
                    SM7포커 딜러비는 승리 플레이어가 획득하는 금액의 4% 만큼을 공제합니다.
                </div>
            </div>
        </div>
    );
}


const BettingRules = () =>{
    return (
        <div className="guide_right_content">
            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/etc_01.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">1회 베팅 한도</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>플레이어간 1회 베팅 한도는<br/>
                            현금 7만원(49억골드) 상당의 금액을<br/>
                            초과하여 베팅할 수 없습니다.
                        </li>
                        <li>
                            1회 베팅한도는 상시 적용됩니다.
                        </li>
                    </ol>
                </div>
            </div>

            <div style={{display: "flex", gap: '20px', paddingBottom: '40px'}}>
                <img src={require("../images/daulgo_guide_img/etc_02.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">머니 획득</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>플레이어는, 본인의 보유머니보다<br/>
                            많은 머니를 획득할 수 없습니다.
                        </li>
                    </ol>
                </div>
            </div>

        </div>
    );
}

const LimitRegulation = () => {
    return (
        <div className="guide_right_content">
            <div style={{display: "flex", gap: '20px', paddingBottom: '80px'}}>
                <img src={require("../images/daulgo_guide_img/etc_04.png")}/>

                <div className="guide_right_txt_content" style={{marginTop: '40px'}}>
                    <div>
                        <span className="guide_bullet_txt_img"></span>
                        <em className="guide_bullet_txt">결제 한도</em>
                    </div>

                    <ol className="guide_additional_rules">
                        <li>플레이어의 결제 한도는 명의 기준<br/>
                            월 70만원을 초과할 수 없습니다.
                        </li>
                        <li>
                            월별 결제 한도는, 매월 1일에 초기화 됩니다.
                        </li>
                        <li>
                            게임물산업진흥에 관한 법률에 따라 사용자가<br/>
                            직접 고스톱/포커류 손실 머니 금액을 설정할 수<br/>
                            있습니다.
                        </li>
                    </ol>
                </div>
            </div>

        </div>
    );
}



const GuideBaduki = () => {
    const [tabs, setTabs] = useState([true, false, false, false, false]);
    const [content, setContent] = useState([true, false, false, false, false]);

    const handelMenuButtonClick = (event, tab_index) => {
        const newTabs = [false, false, false, false, false];
        newTabs[tab_index] = !tabs[tab_index];
        setTabs(newTabs);

        switch (tab_index){
            case 0:
                setContent(InstallAndRun);
                break;
            case 1:
                setContent(HowToPlay);
                break;
            case 2:
                setContent(HowToBet);
                break;
            case 3:
                setContent(Jackpot);
                break;
            case 4:
                setContent(BettingRules);
                break;
        }

        $('.guide_left_menu_inner_tab .active').removeClass('active');
        $('#tab_'+tab_index).addClass('active');
        $('.guide_inner_tab_baduki span').remove('span');
        $('#tab_'+tab_index).append('<span class="guide_left_arrow"></span>');
    };

    const changeContent = (event, pageName) => {
        $('.guide_left_menu_inner_tab .active').removeClass('active');
        $(event.target).addClass('active');
        setContent(pageName);

        $('.guide_inner_tab_baduki span').remove('span');
        $(event.currentTarget).append('<span class="guide_left_arrow"></span>');
    };

    useEffect(() => {
        if(!localStorage.getItem('isMobile')){
                $('body').css({
                'transform': 'unset',
                'min-width': 'unset',
                'background': 'white',
                'zoom': 'unset',

            });
        } else {
            $('body').css({
                'background': 'white'
            });
        }

        setContent(<InstallAndRun/>);
    }, []);

    return (
        <div className="guide_wrap guide_wrap_baduki">
            <div className="guide_left_wrap" style={{backgroundColor: '#143798'}}>
                <div className="guide_left_logo_box">
                    <div style={{fontSize: '21.5px', color: '#4e9dff'}}>SM바둑이</div>
                    <div style={{fontSize: '24.34px', color: '#ffffff'}}>게임방법</div>
                </div>

                <div>
                    <div className="guide_left_menu_button">
                        <div className="guide_left_menu_button_inner"
                             onClick={event => handelMenuButtonClick(event, 0)}>
                            <div style={{color: tabs[0] ? "#fff": "#4e9dff"}}>게임시작</div>
                            <div id="icon_tab_1"
                                 className={tabs[0] ? "guide_left_button_img_open_tab" : "guide_left_button_img_close_tab"}/>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#06226f'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#1f48b8'}}/>
                    </div>

                    <div className="guide_left_menu_inner_button"
                         style={{display: tabs[0] ? 'block' : 'none', height: '145px'}}>

                        <div className="guide_left_menu_inner">
                            <div className="guide_left_menu_inner_tab">
                                <div id="tab_0" className="guide_inner_tab_baduki active"
                                     onClick={event => changeContent(event, InstallAndRun)}>설치 및 실행
                                    <span className="guide_left_arrow"></span>
                                </div>

                                <div className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, MakingRoom)}>방만들기
                                </div>

                                <div className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, DirectEntry)}>바로입장
                                </div>
                                <div className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, UseGameRoom)}>게임방 사용법
                                </div>
                            </div>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#06226f'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#1f48b8'}}/>

                    </div>
                </div>


                <div>
                    <div className="guide_left_menu_button">
                        <div className="guide_left_menu_button_inner"
                             onClick={event => handelMenuButtonClick(event, 1)}>
                            <div style={{color: tabs[1] ? "#fff": "#4e9dff"}}>초보자가이드</div>
                            <div id="icon_tab_1"
                                 className={tabs[1] ? "guide_left_button_img_open_tab" : "guide_left_button_img_close_tab"}/>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#06226f'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#1f48b8'}}/>
                    </div>

                    <div className="guide_left_menu_inner_button"
                         style={{display: tabs[1] ? 'block' : 'none', height: '72px'}}>
                        <div className="guide_left_menu_inner">
                            <div className="guide_left_menu_inner_tab">
                                <div id="tab_1" className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, HowToPlay)}>게임방법
                                </div>
                                <div className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, AdditionalRules)}>추가룰
                                </div>
                            </div>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#06226f'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#1f48b8'}}/>

                    </div>
                </div>


                <div>
                    <div className="guide_left_menu_button">
                        <div className="guide_left_menu_button_inner"
                             onClick={event => handelMenuButtonClick(event, 2)}>
                            <div style={{color: tabs[2] ? "#fff" : "#4e9dff"}}>게임진행</div>
                            <div id="icon_tab_1"
                                 className={tabs[2] ? "guide_left_button_img_open_tab" : "guide_left_button_img_close_tab"}/>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#06226f'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#1f48b8'}}/>
                    </div>

                    <div className="guide_left_menu_inner_button"
                         style={{display: tabs[2] ? 'block' : 'none', height: '72px'}}>
                        <div className="guide_left_menu_inner">
                            <div className="guide_left_menu_inner_tab">
                                <div id="tab_2" className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, HowToBet)}>베팅방법
                                </div>
                                <div className="guide_inner_tab_baduki" onClick={event => changeContent(event, Pedigree)}>족보
                                </div>
                            </div>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#06226f'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#1f48b8'}}/>

                    </div>
                </div>


                <div>
                    <div className="guide_left_menu_button">

                        <div className="guide_left_menu_button_inner"
                             onClick={event => handelMenuButtonClick(event, 3)}>
                            <div style={{color: tabs[3] ? "#fff" : "#4e9dff"}}>게임요소</div>
                            <div id="icon_tab_1"
                                 className={tabs[3] ? "guide_left_button_img_open_tab" : "guide_left_button_img_close_tab"}/>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#06226f'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#1f48b8'}}/>
                    </div>

                    <div className="guide_left_menu_inner_button"
                         style={{display: tabs[3] ? 'block' : 'none', height: '217px'}}>
                        <div className="guide_left_menu_inner">
                            <div className="guide_left_menu_inner_tab">
                                <div id="tab_3" className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, Jackpot)}>잭팟
                                </div>
                                <div id="tab_3" className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, RankAndMoney)}>등급과 머니
                                </div>
                                <div className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, Channel)}>채널
                                </div>
                                <div className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, Safe)}>금고
                                </div>
                                <div className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, FreeRecharge)}>무료충전
                                </div>
                                <div className="guide_inner_tab_baduki"
                                     onClick={event => changeContent(event, DalerFee)}>딜러비
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <div className="guide_left_menu_button">
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#06226f'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#1f48b8'}}/>

                        <div className="guide_left_menu_button_inner"
                             onClick={event => handelMenuButtonClick(event, 4)}>
                            <div style={{color: tabs[4] ? "#fff" : "#4e9dff"}}>기타</div>
                            <div id="icon_tab_1"
                                 className={tabs[4] ? "guide_left_button_img_open_tab" : "guide_left_button_img_close_tab"}/>
                        </div>

                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#06226f'}}/>
                        <div className="guide_left_menu_under_line" style={{backgroundColor: '#1f48b8'}}/>
                    </div>

                    <div className="guide_left_menu_inner_button" style={{display: tabs[4] ? 'block' : 'none'}}>
                        <div className="guide_left_menu_inner">
                            <div className="guide_left_menu_inner_tab">
                                <div id="tab_4" className="guide_inner_tab_baduki active" onClick={event => changeContent(event, BettingRules)}>베팅 규정</div>
                                <div className="guide_inner_tab_baduki" onClick={event => changeContent(event, LimitRegulation)}>한도 규정</div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className="guide_right_wrap">
                {content}
            </div>
        </div>
    );
};

export default GuideBaduki;