import React from "react";


function AuthTermOfUse() {
    return (
        <div className="policy">
            <div>
                <div className="PolicyTitle">
                    제1장 총칙
                </div>

                <div className="policy2">
                    <span className="PolicyTitle2">제1조 (목적)</span><br/>
                    이 약관은 ㈜에스엠게임즈(이하 “회사”)가 온라인으로 제공하는 게임 및 이에 부수된 제반 서비스(이하 “게임서비스”)의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임사항,
                    기타 필요한 사항을 규정함을 목적으로 합니다.


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제2조 (용어의 정의) </span><br/>
                        <ol className="round_number_circle">
                            <li>이 약관에서 사용하는 정의는 다음과 같습니다.</li>
                            <ol>
                                <li>“회사”라 함은 온라인을 통하여 게임서비스를 제공하는 사업자를 의미합니다.</li>
                                <li>“회원”이라 함은 본 약관에 동의하고 게임서비스 이용 자격을 부여받은 자를 의미합니다.</li>
                                <li>“게임서비스”라 함은 회사가 회원에게 온라인으로 제공하는 게임 및 이에 부수된 제반 서비스를 의미합니다.</li>
                                <li>“게임세계”라 함은 게임서비스를 통해 다중의 회원이 일정한 규칙(이하 “게임규칙”)에 따라 오락을 하거나 오락에 부수하여 여가선용, 친목도모, 정보매개
                                    등을 할 수 있도록 게임성을 구현한 가변적인 가상세계를 의미합니다.
                                </li>
                                <li>“계정(ID)”이라 함은 회원의 식별과 게임서비스 이용을 위하여 회원이 선정하고 회사가 부여하는 문자, 숫자 또는 특수문자의 조합을 의미합니다.
                                </li>
                                <li>“계정정보“라 함은 회원의 계정, 비밀번호, 성명 등 회원이 회사에 제공한 일반정보 및 게임이용정보, 이용요금 결제상태 등 생성정보를 통칭합니다.
                                </li>
                                <li>“캐릭터”라 함은 회원이 게임서비스의 이용을 위하여 게임세계 내에서 회사에서 제공하는 방식에 따라 선정하고 조종하는 게임데이터를 의미합니다.</li>
                                <li>“비밀번호”라 함은 회원이 부여받은 계정과 일치되는 회원임을 확인하고 회원의 정보 및 권익보호를 위해 회원 자신이 선정하여 비밀로 관리하는 문자, 숫자
                                    또는 특수문자의 조합을 의미합니다.
                                </li>
                                <li>“캐쉬”라 함은 게임서비스를 이용 또는 구매하기 위해 사용되는 가상의 데이터로서 회원이 대금을 지급하고 구입하는 것을 말합니다.</li>
                                <li>“게임머니”라 함은 게임서비스를 이용 또는 구매하기 위해 사용되는 가상의 데이터로서 회사가 임의로 책정하고 무료로 지급하는 일체의 재산적 가치가 없는
                                    것을 말합니다.
                                </li>
                                <li>“게시물”이라 함은 회원이 서비스를 이용함에 있어 회원이 게시한 문자, 문서, 그림, 음성, 영상 또는 이들의 조합으로 이루어진 모든 정보를
                                    말합니다.
                                </li>
                            </ol>
                            <li>이 약관에서 사용하는 용어의 정의는 제1항 각호에서 정하는 것을 제외하고는 관계법령 및 기타 일반적인 상관례에 의합니다.</li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제3조 (회사정보 등의 제공)  </span><br/>
                        회사는 다음 각 호의 사항을 ㈜에스엠게임즈 홈페이지 초기화면(https://www.smgame.co.kr)에 <br/>
                        게시하여, 회원이 이를 쉽게 알 수 있도록 합니다.<br/>
                        다만, 개인정보취급방침과 약관은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br/>

                        <ol>
                            <li> 상호 및 대표자의 성명</li>
                            <li> 영업소 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함한다) 및 전자우편주소</li>
                            <li> 전화번호, 모사전송번호(Fax 번호)</li>
                            <li> 사업자등록번호, 통신판매업 신고번호</li>
                            <li> 개인정보취급방침</li>
                            <li> 서비스 이용약관</li>
                        </ol>


                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제4조 (약관의 명시와 개정) </span><br/>
                        <ol className="round_number_circle">
                            <li> 회사는 이 약관의 내용을 회원이 알 수 있도록 게임 초기 화면이나 게임서비스 홈페이지
                                (https://www.smgame.co.kr)에 게시하거나 연결화면을 제공하는 방법으로 회원에게 공지합니다.
                            </li>

                            <li> 회사는 회원이 고객센터를 통해 회사와 이 약관의 내용에 관하여 질의 및 응답을
                                할 수 있도록 조치를 취합니다.
                            </li>

                            <li> 회사는 게임서비스를 이용하고자 하는 자(이하 “이용자”라 한다)가 약관의 내용을 쉽게 알 수 있도록 작성하고
                                약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 과오납금의 환급, 계약 해제ㆍ해지, 회사의
                                면책사항 및 회원에 대한 피해보상 등과 같은 중요한 내용을 회원이 쉽게 이해할 수 있도록 굵은 글씨 등으
                                로 처리하거나 별도의 연결화면 또는 팝업화면 등을 제공하고 이용자의 동의를 얻도록 합니다.
                            </li>

                            <li> 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「게임산업진흥에 관한
                                법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」, 「콘텐츠산업진흥법」 등 관련 법령에 위배하지 않
                                는 범위에서 이 약관을 개정할 수 있습니다.
                            </li>

                            <li>회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 그 적용일자로부터 최소한 7
                                일 이전(회원에게 불리하거나 중대한 사항의 변경은 30일 이전)부터 그 적용일자 경과 후 상당한 기간이 경과
                                할 때까지 초기화면 또는 초기화면과의 연결화면을 통해 공지합니다.
                            </li>

                            <li> 회사가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 개
                                정약관 공지시 회원이 동의 또는 거부의 의사표시를 하지 않으면 승낙한 것으로 간주하겠다는 내용도 함께
                                공지한 경우에는 회원이 약관 시행일 까지 거부의사를 표시하지 않는다면 개정약관에 동의한 것으로 간주할
                                수 있습니다.
                            </li>

                            <li> 회원이 개정약관의 적용에 동의하지 않는 경우 회사 또는 회원은 게임서비스 이용계약을 해지할 수 있습니
                                다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제5조 (약관 외 준칙) </span><br/>
                        <ol>
                            <li>회사는 개별 게임 서비스에 대해서는 별도의 이용약관 및 운영정책(이하 "개별 게임 서비스 약관 등")을
                                둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 개별 게임서비스 약관 등이 우선하여 적용됩니다.
                            </li>

                            <li> 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법
                                률」,「약관의 규제에 관한 법률」,「게임산업진흥에 관한 법률」,「정보통신망이용촉진 및 정보보호 등에 관한 법
                                률」,「콘텐츠산업진흥법」 등 관련 법령에 따릅니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제6조 (운영정책) </span><br/>
                        <ol className="round_number_circle">
                            <li> 약관을 적용하기 위하여 필요한 사항과 회원의 권익을 보호하고 게임세계 내 질서를 유지하기 위하여 회사는
                                약관에서 구체적 범위를 정하여 위임한 사항을 게임서비스 운영정책(이하 “운영정책”이라 합니다)으로 정할 수 있습니다.
                            </li>

                            <li> 회사는 운영정책의 내용을 회원이 알 수 있도록 게임초기 화면이나 게임서비스 홈페이지에 게시하거나 연결화
                                면을 제공하는 방법으로 회원에게 공지하여야 합니다.
                            </li>

                            <li> 회원의 권리 또는 의무에 중대한 변경을 가져오거나 약관 내용을 변경하는 것과 동일한 효력이 발생하는 운
                                영정책 개정의 경우에는 제4조의 절차에 따릅니다. 단, 운영정책 개정이 다음 각 호의 어느 하나에 해당하는 경
                                우에는 제2항의 방법으로 사전에 공지합니다.
                            </li>

                            <ol className="normal_list_number">
                                <li> 약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우</li>
                                <li> 회원의 권리·의무와 관련 없는 사항을 개정하는 경우</li>
                                <li> 운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고 회원이 예측가능한 범위내에서 운영정책을 개정하는 경우</li>
                            </ol>
                        </ol>
                    </div>

                    <div className="PolicyTitle">
                        제2장 이용계약의 체결
                    </div>

                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제7조 (이용신청 및 방법) </span><br/>
                        <ol>
                            <li> 회사가 제공하는 게임서비스를 이용하고자 하는 자는 회사가 게임 초기 화면이나 게임서비스 홈페이지
                                (https://www.smgame.co.kr)에서 제공하는 이용신청서를 작성하는 방법으로 이용신청을 하여야 합니다.
                            </li>
                            <li> 이용자는 이용신청시 회사에서 요구하는 제반 정보를 제공하여야 합니다.</li>
                            <li> 이용자는 제1항의 이용 신청 시 본인의 실명 및 실제 정보를 기재하여야 합니다. 실명 또는 식별정보를 허위로
                                기재하거나 타인의 명의를 도용한 경우 이 약관에 의한 회원의 권리를 주장할 수 없고, 회사는 환급없이 이용계약
                                을 취소하거나 해지할 수 있습니다.
                            </li>
                            <li> 청소년(18세 미만의 자로서 「초·중등교육법」제2조의 규정에 의한 고등학교에 재학 중인 학생을 포함한다)이
                                이용신청을 할 경우에는 법정대리인의 동의를 얻어야 하고, 구체적인 동의절차는 게임산업진흥에 관한 법률 및
                                시행령에 따라 회사가 제공하는 방법에 따르도록 합니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제8조 (이용신청의 승낙과 제한) </span><br/>
                        <ol className="round_number_circle">
                            <li> 회사는 회사가 이용자에게 요구하는 정보에 대해 이용자가 실명 및 실제 정보를 정확히 기재하여 이용신청을
                                한 경우에 상당한 이유가 없는 한 이용신청을 승낙합니다.
                            </li>
                            <li> 회사는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지 않거나, 사후에 이용계약을
                                취소할 수 있습니다.
                            </li>

                            <ol>
                                <li> 제7조에 위반하여 이용신청을 하는 경우</li>
                                <li> 이용요금을 납부하지 않거나 잘못 납부하여 확인할 수 없는 경우</li>
                                <li> 청소년(제7조 제4항의 청소년과 같다)이 법정대리인의 동의를 얻지 아니하거나 동의를 얻었음을 확인할 수 없
                                    는 경우
                                </li>
                                <li> 이전에 회원 자격을 상실한 적이 있는 자로서 회사의 회원 재가입 승낙을 얻지 못한 경우</li>
                                <li> 제3자의 신용카드, 유/무선 전화, 은행 계좌 등을 무단으로 이용 또는 도용하여 서비스 이용요금을 결제하는
                                    경우
                                </li>
                                <li> 대한민국 이외의 국가 중 회사에서 아직 서비스를 제공할 것으로 결정하지 않은 국가에서 서비스를 이용하는
                                    경우로 회사가 해외 서비스 업체와 체결한 계약이나 특정 국가에서 접속하는 회원에 대한 서비스 제공과 관련
                                    하여 서비스제공을 제한할 필요가 있는 경우
                                </li>
                                <li>「게임산업진흥에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및 그 밖의 관계 법령에서
                                    금지하는 위법행위를 할 목적으로 이용신청을 하는 경우
                                </li>
                                <li> 그 밖에 1호 내지 7호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우</li>
                            </ol>

                            <li> 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.</li>
                            <ol>
                                <li> 회사의 설비에 여유가 없거나 기술적 장애가 있는 경우</li>
                                <li> 서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우</li>
                                <li> 그 밖에 위 각 호에 준하는 사유로서 이용신청의 승낙이 곤란한 경우</li>
                            </ol>

                            <li> 회사가 제 1항 단서 및 제 3항에 따라 회원가입 신청의 승낙을 하지 아니하거나 유보한 경우, 회사는
                                원칙적으로 이를 이용신청자에게 알리도록 합니다.
                            </li>

                            <li> 회사는 제1항 및 제2항과 관련하여 사전 또는 사후에 이용신청자에게 전문기관을 통한 본인 인증을
                                요청할 수 있습니다.
                            </li>

                            <li> 이 약관에 따른 이용계약의 성립 시기는 회사가 이용신청절차 상에서 회원가입 완료를 표시한 시점으로
                                합니다
                            </li>

                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제9조 (회원 계정(ID) 및 비밀번호) </span><br/>
                        <ol>
                            <li> 회사는 회원에 대하여 회원의 정보 보호, 서비스 이용안내 등의 편의를 위해 회원이 선정한 일정한 문자, 숫
                                자 또는 특수문자의 조합을 계정으로 부여합니다.
                            </li>
                            <li> 회사는 계정정보를 통하여 당해 회원의 서비스 이용가능 여부 등의 제반 회원 관리업무를 수행합니다.</li>
                            <li> 회원은 자신의 계정정보를 선량한 관리자로서의 주의 의무를 다하여 관리하여야 합니다. 회원이 본인의 계정
                                정보를 소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습
                                니다.
                            </li>
                            <li> 비밀번호의 관리책임은 회원에게 있으며, 회원이 원하는 경우에는 보안상의 이유 등으로 언제든지 변경이 가
                                능합니다.
                            </li>
                            <li> 회원은 계정 정보가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고
                                회사의 안내에 따라야 합니다.
                            </li>
                            <li> 제5항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지
                                않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
                            </li>
                            <li> 회원은 정기적으로 비밀번호를 변경하여야 합니다.</li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제10조 (회원 정보의 제공 및 변경) </span><br/>
                        <ol>
                            <li> 회원은 이 약관에 의하여 회사에 정보를 제공하여야 하는 경우에는 진실된 정보를 제공하여야 하며, 허위정
                                보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.
                            </li>
                            <li> 회원은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스
                                관리를 위해 필요한 실명, 계정(ID) 등은 수정이 불가능합니다.
                            </li>
                            <li> 회사는 다음과 같은 경우 회원의 아이디(ID)를 변경할 수 있으며, 회사는 이를 제35조에 따라 회원에게 통지
                                합니다.
                            </li>

                            <ol>
                                <li>회사가 회원에게 보다 많은 서비스를 효율적으로 제공하기 위해 부득이한 경우</li>
                                <li>회사의 서비스 운영이나 운영정책상 다른 서비스와의 통합이 필요한 경우</li>
                                <li>기타 회원이 신청한 아이디(ID)가 관계법령 또는 회사의 운영 정책에 따라 상당한 변경 이유가
                                    있는 경우
                                </li>
                            </ol>

                            <li>회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 기타 방법으로 회사에
                                대하여 그 변경사항을 알려야 합니다.
                            </li>
                            <li>제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.</li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제11조 (개인정보의 보호 및 관리) </span><br/>
                        <ol>
                            <li>회사는 관계 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를 보호하기 위해 노력합니다. 회원
                                개인정보의 보호 및 사용에 대해서는 관계법령 및 회사가 별도로 고지하는 개인정보취급방침이 적용됩니다.
                            </li>
                            <li>서비스의 일부로 제공되는 개별 서비스를 제외한 것으로서 홈페이지 및 게임서비스별 웹사이트에서 단순히
                                링크된 제3자 제공의 서비스에 대하여는 회사의 개인정보취급방침이 적용되지 않습니다.
                            </li>
                            <li>회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서 일체의 책임을 지지
                                않습니다.
                            </li>
                        </ol>

                    </div>

                    <div className="PolicyTitle">
                        제3장 계약 당사자의 의무
                    </div>

                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제12조 (회사의 의무) </span><br/>
                        <ol>
                            <li>회사는 관련 법령을 준수하고, 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 따라 성실하게 합니다.</li>
                            <li>회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추
                                어야 하며 개인정보취급방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보취급방침에서 정한 경우를
                                제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.
                            </li>
                            <li>회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정될 경우에는 이를
                                처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을
                                통하여 회원에게 처리 과정 및 결과를 전달합니다.
                            </li>
                            <li>회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터
                                등이 멸실된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 결함 및 장애 등 부득이한 사유가 없
                                는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제13조 (회원의 의무) </span><br/>
                        <ol>
                            <li>회원은 다음 행위를 하여서는 안 됩니다.</li>
                            <ol>
                                <li>신청 또는 변경 시 허위내용의 기재</li>
                                <li>타인의 정보도용</li>
                                <li>회사의 임직원, 운영자, 기타 관계자를 사칭하는 행위</li>
                                <li>회사가 게시한 정보의 변경</li>
                                <li>회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시</li>
                                <li>회사가 제공 또는 승인하지 아니한 컴퓨터 프로그램이나 기기 또는 장치를 제작, 배포, 이용, 광고하는 행위</li>
                                <li>회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                                <li>회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                                <li>외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위</li>
                                <li>게임 데이터(계정, 캐릭터, 게임아이템 등)를 유상으로 처분(양도, 매매 등)하거나 권리의 객체(담보제공, 대여 등)
                                    로 하는 행위
                                </li>
                                <li>10호의 행위를 유도하거나 광고하는 행위</li>
                                <li>회사의 동의 없이 영리, 영업, 광고, 정치활동 등을 목적으로 게임서비스를 사용하는 행위</li>
                                <li>기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 않는 행위</li>
                            </ol>
                            <li>회원은 이 약관의 규정, 이용안내 및 게임서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을
                                확인하고 준수하여야 하며, 기타 회사 업무에 방해되는 행위를 하여서는 안 됩니다.
                            </li>
                            <li>회사는 제1항, 제2항 및 다음 각 호의 어느 하나에 해당하는 행위의 구체적인 유형을 운영정책에서 정할 수
                                있으며, 회원은 이를 준수할 의무가 있습니다.
                            </li>
                            <ol>
                                <li>회원의 계정명, 캐릭터명, 혈맹명, 길드명에 대한 제한</li>
                                <li>채팅내용과 방법에 대한 제한</li>
                                <li>게시판이용에 대한 제한</li>
                                <li>게임플레이방법에 대한 제한</li>
                                <li>기타 회원의 게임서비스 이용에 대한 본질적 권리를 침해하지 않는 범위내에서 회사가 게임서비스 운영상
                                    필요하다고 인정되는 사항
                                </li>
                            </ol>

                        </ol>

                    </div>

                    <div className="PolicyTitle">
                        제4장 서비스 이용
                    </div>

                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제14조 (서비스의 변경 및 내용수정) </span><br/>
                        <ol>
                            <li>회원은 회사가 제공하는 게임서비스를 이 약관, 운영정책 및 회사가 설정한 게임규칙에 따라 이용할 수 있습
                                니다.
                            </li>
                            <li>회사가 게임서비스를 통하여 회원에게 제공하는 게임세계는 회사가 창조한 가상세계로서 회사는 게임 내용
                                의 제작, 변경, 유지, 보수에 관한 포괄적인 권한을 가집니다.
                            </li>
                            <li>회사는 현실 세계로부터 게임 세계를 보호하고 게임 세계의 질서와 게임성을 유지하기 위하여 필요한 조치
                                를 취합니다.
                            </li>
                            <li>회사가 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 게임서비스 수정(패치)을 할 수 있으며, 게
                                임서비스 수정(패치)을 하는 경우에는 변경 후 해당 게임사이트 등을 통하여 공지합니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제15조 (서비스의 제공 및 중단 등) </span><br/>
                        <ol>
                            <li>게임서비스는 회사의 영업방침에 따라 정해진 시간동안 제공합니다. 회사는 게임서비스 제공시간을 게임 초
                                기화면이나 게임서비스 홈페이지(https://www.smgame.co.kr)에 적절한 방법으로 안내합니다.
                            </li>
                            <li>제1항에도 불구하고, 다음 각 호의 어느 하나에 해당하는 경우에는 일정한 시간동안 게임서비스가 제공되지
                                아니할 수 있으며, 해당 시간 동안 회사는 게임서비스를 제공할 의무가 없습니다.
                            </li>

                            <ol>
                                <li>컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검 또는 게임 내용이나 게임서비스의 수정을 위하여 필요
                                    한 경우
                                </li>
                                <li>해킹 등의 전자적 침해사고, 통신사고, 회원들의 비정상적인 게임 이용행태, 미처 예상하지 못한 게임서비스의
                                    불안정성에 대응하기 위하여 필요한 경우
                                </li>
                                <li>관련 법령에서 특정 시간 또는 방법으로 게임서비스 제공을 금지하는 경우</li>
                                <li>천재지변, 비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 게임서비스 제공이
                                    불가능할 경우
                                </li>
                                <li>회사의 분할, 합병, 영업양도, 영업의 폐지, 당해 게임서비스의 수익 악화 등 회사의 경영상 중대한 필요에
                                    의한 경우
                                </li>
                            </ol>

                            <li>회사는 제2항 제1호의 경우, 매주 또는 격주 단위로 일정 시간을 정하여 게임서비스를 중지할 수 있습니다.
                                이 경우 회사는 최소한 24시간 전에 그 사실을 회원에게 게임 초기 화면이나 게임서비스 홈페이지
                                (https://www.smgame.co.kr)에 고지합니다.
                            </li>

                            <li>제2항 제2호의 경우, 회사는 사전 고지 없이 게임서비스를 일시 중지할 수 있습니다. 회사는 이러한 경우
                                그 사실을 게임 초기 화면이나 게임서비스 홈페이지(https://www.smgame.co.kr)에 사후 고지할 수 있습니다.
                            </li>

                            <li>회사는 회사가 제공하는 무료서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 대해서도 책임을 지
                                지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.
                            </li>

                            <li>회사는 회사가 제공하는 유료서비스 이용과 관련하여 회사의 귀책사유로 사전고지 없이 1일 4시간(누적시간) 이
                                상 연속하여 서비스가 중지되거나 장애가 발생한 경우 계속적 이용계약 계정에 한하여 서비스 중지ㆍ장애시간
                                의 3배에 해당하는 이용시간을 무료로 연장하고, 이용자는 회사에 대하여 별도의 손해배상을 청구할 수 없습
                                니다. 다만, 회사가 서버점검 등의 사유로 서비스 중지ㆍ장애를 사전에 고지하였으나, 서비스 중지ㆍ장애시간
                                이 10시간이 초과하는 경우에는 그 초과된 시간만큼 이용시간을 무료로 연장하고, 이용자는 회사에 대하여 별도의 손해배상을 청구할 수 없습니다.
                            </li>

                            <li>제2항 제3호 내지 제5호의 경우에 회사는 기술상․운영상 필요에 의해 게임서비스 전부를 중단할 수 있으며, 30
                                일전에 홈페이지에 이를 공지하고 게임서비스의 제공을 중단할 수 있습니다. 사전에 통지할 수 없는 부득이
                                한 사정이 있는 경우는 사후에 통지를 할 수 있습니다.
                            </li>

                            <li>회사가 제7항에 따라 게임서비스를 종료하는 경우 회원은 무료서비스 및 사용 기간이 남아 있지 않은
                                유료서비스․계속적 유료 이용계약․기간제 유료아이템에 대하여 손해배상을 청구할 수 없습니다.
                            </li>

                            <li>제7항의 경우 회사는 서비스 중단 이후 30일 이상의 기간을 정하여 그 기간 동안 제8항의 손해배상절차를
                                이행하기 위한 전담 창구 등 고객 대응 수단을 마련하여 운영합니다.
                            </li>

                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제16조 (정보의 제공 및 광고의 게재) </span><br/>
                        <ol>
                            <li>회사는 다음의 사항을 게임 초기 화면이나 인터넷 웹사이트인 게임서비스 홈페이지<br/>
                                (https://www.smgame.co.kr)에 회원이 알기 쉽게 표시합니다.
                            </li>
                            <ol>
                                <li>상호</li>
                                <li>게임물의 제명</li>
                                <li>이용등급</li>
                                <li>등급분류번호</li>
                                <li>제작연월일</li>
                                <li>게임물제작업자 또는 배급업자의 신고번호 또는 등록번호</li>
                                <li>「게임산업진흥에 관한 법률」 등 관련 법령에 따라 표시할 의무가 있는 확률형 아이템 관련 정보</li>
                                <li>기타 회사가 필요하다고 인정하는 사항</li>
                            </ol>

                            <li>회사는 관계법령을 준수하는 범위 내에서 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를
                                공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만 회원은 언제든지
                                개인정보관리화면, 전자우편 등을 통하여 수신 거절을 할 수 있습니다.
                            </li>

                            <li>제2항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 회원의 사전 동의를 받아
                                전송합니다.
                            </li>

                            <li>회사는 서비스의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다.
                                광고가 게재된 전자우편 등을 수신한 회원은 수신 거절을 회사에 할 수 있습니다.
                            </li>

                            <li>회사가 제공하는 서비스는 배너와 링크(Link) 등 다양한 형태의 광고가 포함되어 있으며, 이는 제3자가
                                제공하는 페이지와 연결될 수 있습니다.
                            </li>

                            <li>전항에 따라 제3자가 제공하는 페이지로 연결될 경우 해당 페이지는 회사의 서비스 영역이 아니므로
                                회사가 신뢰성, 안전성 등을 보장하지 않으며 그로 인한 회원의 손해에 대하여도 회사는 책임을 지지
                                않습니다.
                            </li>

                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제17조 (정보의 수집 등) </span><br/>
                        <ol>
                            <li>회사는 서비스의 운영, 프로그램 안정화 및 오류개선, 악성코드 감염 여부 확인 등 게임서비스 품질 개선을
                                위해 회원들의 개인정보를 제외한 PC등 단말기 설정 및 사양정보와 시스템 정보, 오류 정보를 수집, 활용할
                                수 있습니다.
                            </li>
                            <li>회사는 게임서비스 내에서 회원간에 이루어지는 모든 채팅내용을 저장ㆍ보관할 수 있습니다. 회사는 회원간
                                의 분쟁 조정, 민원 처리 또는 게임 질서의 유지를 위하여 회사가 필요하다고 판단하는 경우에 한하여 본 정
                                보를 열람하도록 할 것이며, 본 정보는 회사만이 보유하고 법령으로 권한을 부여 받지 아니한 제3자는 절대
                                로 열람할 수 없습니다. 회사는 해당 정보를 열람하기 전에 채팅정보의 열람이 필요한 사유 및 열람 범위를 개
                                인에게 사전 고지하기로 합니다. 다만, 계정도용, 현금거래, 언어폭력, 게임 내 사기 등 기망행위, 버그 악용, 기
                                타 현행 법령 위반행위 및 이 약관 제13조에서 정하는 중대한 약관위반 행위의 조사, 처리, 확인 및 이의 구
                                제와 관련하여 회원의 채팅 정보를 열람해야 할 필요가 있는 경우에는, 사후에 채팅정보가 열람된 개인들에
                                대하여 열람한 사유와 열람한 정보 중 본인과 관련된 부분을 고지하기로 합니다.
                            </li>

                            <li>회사는 회원에게 안정적이고 신속하게 서비스를 제공하기 위하여, 서비스 설치 및 이용 시에 회원 PC의
                                일부 저장공간이나 리소스를 활용하여 다른 회원에게 데이터를 전송하는 Grid Computing, 분산 처리 시스템
                                과 같은 기술을 적용할 수 있습니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제18조 (이용상품) </span><br/>
                        <ol>
                            <li>회사는 회원이 별도 비용지급 없이 이용할 수 있는 게임서비스(이하 “무료서비스”라 한다)와 회사가 미리 책정
                                한 요금을 지불하고 이용하는 게임서비스(이하 “유료서비스”라 한다)를 제공할 수 있으며, 회원은 게임서비스
                                를 선택하여 이용할 수 있습니다.
                            </li>

                            <li>게임서비스의 이용에 대한 대금지급방법은 회사가 미리 정한 방법을 통하여 할 수 있습니다.</li>

                            <li>제1항의 유료서비스 이용과 관련하여, 회원이 선택한 유료서비스 이용신청 후, 다음 각 호에 해당하는 사항
                                을 준수하여야 합니다.
                            </li>

                            <ol>
                                <li>유료서비스 이용을 신청한 회원은 게임서비스 이용요금을 성실히 납부하여야 합니다.</li>
                                <li>유료서비스 이용을 신청한 회원은 게임서비스 이용요금을 신청 후 회사에서 제공하는 방법으로 지급하여야 합
                                    니다. 단, 무통장입금의 방식으로 신청한 회원은 신청 완료 후 30일 이내에 해당 금액을 입금하여야 하며, 30일
                                    이내에 입금하지 않을시에는 그 신청을 철회한 것으로 봅니다.
                                </li>
                            </ol>

                            <li>회사는 미성년자인 회원이 결제가 필요한 유료 게임서비스를 이용하고자 하는 경우 부모 등 법정 대리인의
                                동의를 얻어야 하고, 동의 없이 이루어진 유료 게임서비스 이용은 법정대리인이 취소할 수 있다는 내용을 유
                                료 게임서비스 이용을 위한 결제 전에 고지하도록 합니다.
                            </li>

                            <li>회원이 유료서비스 이용 시 정기결제 방식을 선택하였지만 사전 고지된 정기결제일에 정상 결제가 이루어
                                지지 않은 경우, 정기결제 대상 유료서비스가 종료되거나 변경되는 경우 회사는 정기결제 계약을 해지할
                                수 있습니다.
                            </li>

                            <li>회사의 운영정책 및 제1항의 결제업체(이동통신사, 카드사 등)의 기준에 따라 회원 당 월 누적 결제액 및
                                충전 한도가 제한될 수 있습니다. 해당 기준을 초과하는 경우 유료서비스의 추가 이용은
                                불가능할 수 있습니다. 이 경우 연속적 사용을 전제로 한 혜택은 소멸될 수 있습니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제19조 (저작권 등의 귀속) </span><br/>
                        <ol className="">
                            <li>게임서비스 내 회사가 제작한 콘텐츠에 대한 저작권 기타 지적재산권은 회사의 소유입니다.
                                회사는 게임서비스와 관련하여 회원에게 정한 이용조건에 따라 게임이나 캐릭터, 게임아이템, 게임머니,
                                등을 이용할 수 있는 이용 권한만을 부여하며 회원은 이를 유상양도, 판매, 담보제공 등의
                                처분 행위를 할 수 없습니다.
                            </li>

                            <li>회원은 회사가 제공하는 게임서비스를 이용함으로써 얻은 정보 중 회사 또는 제공업체에 지적재산권이 귀속
                                된 정보를 회사 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적
                                으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
                            </li>

                            <li>회원은 게임내에서 보여지거나 게임서비스와 관련하여 회원 또는 다른 이용자가 게임 클라이언트 또는 게임
                                서비스를 통해 업로드 또는 전송하는 대화 텍스트를 포함한 커뮤니케이션, 이미지, 사운드 및 모든 자료 및
                                정보(이하 '이용자 콘텐츠'라 한다.)에 대하여 회사가 다음과 같은 방법과 조건으로 이용하는 것을 허락합니다.
                            </li>

                            <ol>
                                <li>해당 이용자 콘텐츠를 이용, 편집 형식의 변경 및 기타 변형하는 것(공표, 복제, 공연, 전송, 배포, 방송, 2차적
                                    저작물 작성 등 어떠한 형태로든 이용 가능하며, 이용기간과 지역에는 제한이 없음)
                                </li>
                                <li>이용자 콘텐츠를 제작한 이용자의 사전 동의 없이 거래를 목적으로 이용자 콘텐츠를 판매, 대여, 양도 행위
                                    를 하지 않음
                                </li>
                            </ol>

                            <li>게임내에서 보여지지 않고 게임서비스와 일체화되지 않은 회원의 이용자 콘텐츠(예컨대, 일반게시판 등에의
                                게시물)에 대하여 회사는 회원의 명시적인 동의가 없이 상업적으로 이용하지 않으며, 회원은 언제든지 이러
                                한 이용자 콘텐츠를 삭제할 수 있습니다.
                            </li>

                            <li>회사는 제3항 및 제4항의 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편
                                등을 통해 사전에 회원의 동의를 얻어야 합니다.
                            </li>

                            <li>회사는 회원이 게시하거나 등록하는 게임서비스 내의 게시물, 게시 내용에 대해 제 13조에서 규정하는 금지
                                행위에 해당된다고 판단되는 경우, 사전통지 없이 이를 삭제하거나 이동 또는 등록을 거부할 수 있습니다.
                            </li>

                            <li>회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 회원은 회사에게 당해 정보의 삭
                                제 또는 반박내용의 게재를 요청할 수 있습니다. 이 경우 회사는 신속하게 필요한 조치를 취하고, 이를 신청
                                인에게 통지합니다.
                            </li>
                            <li>제3항은 회사가 게임서비스를 운영하는 동안 유효하며 회원탈퇴 후에도 지속적으로 적용됩니다.</li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제20조 (테스트 목적 서비스 등) </span><br/>
                        <ol>
                            <li>회사는 회원들을 대상으로 일정기간 테스트 목적의 서비스를 제공할 수 있습니다.</li>
                            <li>테스트 목적 서비스의 경우는 서비스 안정성 등을 위해 게임 데이터의 변경, 추가, 삭제 등이 실시될 수
                                있으며 테스트 기간동안 회원이 취득한 게임머니, 캐릭터 등 해당 테스트 목적 서비스와 관련된
                                모든 데이터는 초기화 될 수 있습니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyTitle">
                        제5장 청약철회, 계약 해제·해지 및 이용제한
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제21조 (청약의 철회) </span><br/>
                        <ol>
                            <li>회사의 유료서비스는 청약철회가 가능한 콘텐츠와 청약철회가 제한되는 콘텐츠로 구분되어 제공되며,
                                이러한 내용은 회원이 콘텐츠를 구매할 시 고지합니다. 회사와 유료서비스 이용에 관한 계약을 체결한 회원
                                은 구매일 또는 유료서비스 이용가능일로부터 7일 이내에는 청약의 철회를 할 수 있습니다.
                            </li>
                            <li>회원은 다음 각 호의 어느 하나에 해당하는 경우에는 회사의 의사에 반하여 제1항에 따른 청약철회 등을 할
                                수 없습니다.
                            </li>
                            <ol>
                                <li>회원에게 책임이 있는 사유로 재화 등이 멸실되거나 훼손된 경우</li>
                                <li>회원이 재화를 사용 또는 일부 소비한 경우</li>
                                <li>시간이 지나 다시 판매하기 곤란할 경우</li>
                                <li>복제가능한 재화 등의 포장을 훼손한 경우</li>
                                <li>그 밖에 거래의 안전을 위하여 법령으로 정하는 경우</li>
                            </ol>

                            <li>회사는 제2항 제2호부터 4호까지 규정에 따라 청약철회 등이 불가능한 재화 등의 경우에는 그 사실을 재화
                                등의 포장이나 그밖에 회원이 쉽게 알 수 있는 곳에 명확하게 적시하거나 시험 사용 상품을 제공하는 등의
                                방법으로 청약철회 등의 권리 행사가 방해받지 아니하도록 조치하여야 합니다. 만약 회사가 이러한 조치를
                                하지 아니한 경우에는 제2항 제2호 내지 제4호의 청약철회 제한사유에도 불구하고원 회은 청약철회를 할 수 있습니다.
                            </li>

                            <li>회원은 제1항 내지 제3항의 규정에도 불구하고 유료서비스의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게
                                이행된 경우에는 구매일 또는 유료서비스 이용가능일로부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던
                                날부터 30일 이내에 청약철회를 할 수 있습니다.
                            </li>

                            <li>회원은 구두 또는 서면(전자문서 포함), 전자우편으로 청약철회를 할 수 있습니다.</li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제22조(청약 철회 등의 효과) </span><br/>
                        <ol>
                            <li>회원이 청약철회를 한 경우 회사는 지체없이 회원의 유료서비스를 회수 또는 삭제하고 유료서비스를 회수
                                또는 삭제한 날로부터 3영업일 이내에 지급받은 대금을 환급합니다.
                            </li>

                            <li>이 경우 회사가 회원에게 환급을 지연한 때에는 그 지연기간에 대하여 전자상거래 등에서의 소비자보호에
                                관한 법률 및 시행령에서 정하는 이율을 곱하여 산정한 지연이자를 지급합니다
                            </li>

                            <li>회사는 위 대금을 환급함에 있어서 회원이 신용카드나 그 밖에 전자상거래 등에서의 소비자보호에 관한 법
                                률 시행령으로 정하는 결제수단으로 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한 사업자로 하여
                                금 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만 회사가 결제업자로부터 이미 대금을 지급받은 때
                                에는 이를 결제업자에게 환불하고 이를 소비자에게 통지합니다.
                            </li>

                            <li>회사는 이미 재화 등이 일부 사용되거나 일부 소비된 경우에는 그 재화 등의 일부 사용 또는 일부 소비에
                                의하여 회원이 얻은 이익 또는 그 재화 등의 공급에 든 비용에 상당하는 금액을 회원에게 청구할 수 있습니
                                다.
                            </li>

                            <li>회원이 청약철회를 한 경우 재화 등의 반환에 필요한 비용은 회원이 부담하고, 회사는 회원에게 청약철회를
                                이유로 위약금 또는 손해배상을 청구할 수 없습니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제23조 (회원의 해제 및 해지) </span><br/>
                        <ol>
                            <li>회원은 게임서비스 이용계약을 해지(이하 '회원탈퇴'라 한다)할 수 있습니다. 회원이 회원탈퇴를 신청한 경우
                                회사는 회원 본인 여부를 확인할 수 있으며, 해당 회원이 본인으로 확인되는 경우에 회원의 신청에 따른 조
                                치를 취합니다.
                            </li>
                            <li>회원이 회원탈퇴를 원하는 경우에는 고객센터 및 서비스 내 회원탈퇴 절차를 통 하여 회원탈퇴를 할 수 있
                                습니다. 단 회사는 이용자의 회원가입 후 일정시간동안 서비스 부정 이용 방지 등의 사유로
                                즉시 탈퇴를 제한할 수 있습니다.
                            </li>
                            <li>회원이 이용계약을 해지할 경우, 관계법령 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는
                                경우를 제외하고는 해지 즉시 회원의 계정정보를 포함한 모든 데이터는 소멸됩니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제24조 (회사의 해제 및 해지) </span><br/>
                        <ol>
                            <li>회사는 회원이 이 약관에서 정한 회원의 의무를 위반한 경우에는 회원에 대한 사전 통보 후 계약을 해지할 수
                                있습니다. 다만, 회원이 현행법 위반 및 고의 또는 중대한 과실로 회사에 손해를 입힌 경우에는 사전 통보 없
                                이 이용계약을 해지할 수 있습니다.
                            </li>
                            <li>회사가 이용계약을 해지하는 경우 회사는 회원에게 서면, 전자우편 또는 이에 준하는 방법으로 다음 각 호의
                                사항을 회원에게 통보합니다.
                            </li>
                            <ol>
                                <li>해지사유</li>
                                <li>해지일</li>
                            </ol>
                            <li>단서의 경우, 회원은 유료서비스의 사용권한을 상실하고 이로 인한 환불 및 손해배상을 청구할 수 없습니다.</li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제25조 (회원에 대한 서비스 이용제한) </span><br/>
                        <ol>
                            <li>회사는 회원에게 다음 각 호의 구분에 따라 회원의 게임서비스 이용을 제한할 수 있습니다. 이용제한이 이루
                                어지는 구체적인 회원의 의무위반 사유는 제27조에 따라 개별 게임의 운영정책에서 정하기로 합니다.
                            </li>
                            <ol>
                                <li>캐릭터 일부 권한 제한 : 일정기간 캐릭터의 채팅 등 일정 권한을 제한</li>
                                <li>캐릭터 이용제한 : 일정기간 또는 영구히 회원 캐릭터의 이용을 제한</li>
                                <li>계정 이용제한 : 일정기간 또는 영구히 회원 계정의 이용을 제한</li>
                                <li>회원 이용제한 : 일정기간 또는 영구히 회원의 게임서비스 이용을 제한</li>
                            </ol>

                            <li>회사의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 회원이 입은 손해를 배상하지 않습니다.</li>

                            <li>회사는 객관적으로 계정정보 도용 피해가 우려되는 경우 또는 회원이 서비스를 1년 이상 사용하지 않은
                                경우에는 회원정보의 보호 및 운영의 효율성을 위해 임시조치, 이용 제한, 계정 정보 삭제 등의
                                필요한 조치를 취할 수 있습니다.
                            </li>

                            <li>회사는 회원의 게임 과물입 방지를 위해 이용방법, 이용시간, 이용횟수 등을 제한할 수 있으며 회원의
                                게임 과몰입 정도에 따라 이용자 보호프로그램을 적용할 수 있습니다. 회원은 이용자 보호프로그램에 따라
                                게임 이용이 단계적으로 제한될 수 있으며, 이용자 보호프로그램의 적용 거부 시 회사는 서비스의 제공을
                                중단할 수 있습니다. 이에 대한 자세한 내용은 이용자 보호프로그램 정책에 따릅니다.
                            </li>

                            <li>회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때
                                이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제26조 (잠정조치로서의 이용제한) </span><br/>
                        <ol>
                            <li>회사는 다음 각 호에 해당하는 문제에 대한 조사가 완료될 때까지 계정을 정지할 수 있습니다.</li>
                            <li>계정이 해킹 또는 도용당하였다는 정당한 신고가 접수된 경우</li>
                            <li>불법프로그램 사용자, 작업장 등 위법행위자로 합리적으로 의심되는 경우</li>
                            <li>그 밖에 위 각호에 준하는 사유로 계정의 잠정조치가 필요한 경우</li>
                            <li>제1항의 경우 회사는 조사가 완료된 후 게임서비스 이용 기간에 비례하여 일정액을 지급하여 이용하는 회원
                                에게 정지된 기간만큼 회원의 게임서비스 이용기간을 연장합니다. 다만, 제1항에 의한 위법행위자로 판명된 경
                                우에는 그러하지 아니합니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제27조 (이용제한의 사유와 절차) </span><br/>
                        <ol>
                            <li>회사는 위반행위의 내용, 정도, 횟수, 결과 등 제반사정을 고려하여 이용제한이 이루어지는 구체적인 사유 및
                                절차를 운영정책으로 정합니다.
                            </li>
                            <li>회사가 제25조에서 정한 이용제한을 하는 경우에는 회원에게 서면 또는 전자우편이나 게임 초기 화면 또는 게
                                임서비스 홈페이지(https://www.smgame.co.kr)에 게재하는 방법으로 다음 각 호의 사항을 회원에게 통보합니
                                다.
                            </li>
                            <ol>
                                <li>이용제한 사유</li>
                                <li>이용제한 유형 및 기간</li>
                                <li>이용제한에 대한 이의신청 방법</li>
                            </ol>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제28조 (이용제한에 대한 이의신청 절차) </span><br/>
                        <ol>
                            <li>회원이 회사의 이용제한에 불복하고자 할 때에는 통보를 받은 날로부터 15일 이내에 회사의 이용제한에 불복
                                하는 이유를 기재한 이의신청서를 서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.
                            </li>
                            <li>제1항의 이의신청서를 접수한 회사는 접수한 날로부터 15일 이내에 회원의 불복 이유에 대하여 서면, 전자우
                                편 또는 이에 준하는 방법으로 답변하여야 합니다. 다만, 회사는 15일 이내에 답변이 곤란한 경우 회원에게 그
                                사유와 처리일정을 통보합니다.
                            </li>
                            <li>회사는 위 답변 내용에 따라 상응하는 조치를 취하여야 합니다.</li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제29조 (과오금) </span><br/>
                        <ol>
                            <li>회원이 대금을 결제함에 있어 과오금을 지급한 경우 회사는 대금의 결제와 동일한 방법으로 과오금을
                                환급하여야 합니다. 다만 동일한 방법으로 환급이 불가능할 때에는 이를 사전에 고지합니다.
                            </li>
                            <li>회사의 책임 있는 사유로 과오금이 발생한 경우 회사는 계약비용, 수수료 등에 관계없이 과오금을
                                전액 환불합니다. 다만 회원의 책임이 있는 사유로 과오금이 발생한 경우, 회사가 과오금을 환급하는데
                                소요되는 비용은 합리적인 범위 내에서 회원이 부담하여야 합니다.
                            </li>
                            <li>회사는 회원이 주장하는 과오금에 대해 환급을 거부할 경우에 과오금이 없음을 입증할 책임을 집니다.</li>
                        </ol>
                    </div>


                    <div className="PolicyTitle">
                        제6장 손해배상 및 환불 등
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제30조 (손해배상) </span><br/>
                        <ol>
                            <li>회사가 고의 또는 중과실로 회원에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다.</li>
                            <li>회원이 본 약관을 위반하여 회사에 손해를 끼친 경우, 회원은 회사에 대하여 그 손해에 대하여 배상할 책임
                                이 있습니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제31조 (환불) </span><br/>
                        <ol>
                            <li>회원이 직접 구매한 캐쉬의 환불을 요청하면 이를 환불 받을 수 있습니다. 환불 시에는 은행이체 및 결제대
                                행 수수료 등의 사유로 현재 남아 있는 캐쉬 잔액의 10%이내 금액 또는 캐쉬잔액이 10,000원 이내인 경우
                                에는 일정금액을 공제하고 남은 금액을 환불하여 드립니다. 일정금액을 공제하는 경우 공제 후 남은 금액이
                                공제금액보다 작은 경우에는 환불할 수 없습니다.
                            </li>
                            <li>계속적 이용계약의 경우에는 상품가액에서 이용자가 이미 사용한 부분에 해당하는 금액(각 상품별 사용금액 산
                                정기준은 상품 구매 시 별도 고지함)을 제외하고 남은 금액에서 남은금액의 10% 이내 금액 또는 남은 금액
                                이 10,000원 이내인 경우에는 일정금액을 공제한 후 나머지 금액을 환불해 드립니다. 일정금액을 공제하는
                                경우 공제 후 남은 금액이 공제금액보다 작은 경우에는 환불할 수 없습니다.
                            </li>
                            <li>무상으로 지급한 캐쉬아이템, 캐쉬, 게임아이템 등은 본 조항의 환불 대상에서 제외됩니다.</li>
                            <li>현행법령 및 중대한 약관 위반 등 회원의 귀책사유로 이용계약을 해지하는 경우 환불이 제한될 수 있습니다.</li>
                            <li>서비스 종료에 따른 환불기준은 발생일 1개월 전에 공지사항 등을 통해 명시합니다.</li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제32조 (회사의 면책) </span><br/>
                        <ol>
                            <li>회사는 전시, 사변, 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 기술적 결함 기타 불가항력적 사유로
                                서비스를 제공할 수 없는 경우에는 책임이 면제됩니다.
                            </li>

                            <li>회사는 회원의 귀책사유로 인한 게임서비스의 중지, 이용장애 및 계약해지에 대하여 책임이 면제됩니다.</li>

                            <li>회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가
                                발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
                            </li>

                            <li>회사는 사전에 공지된 게임서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 게임서비스가
                                중지되거나 장애가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
                            </li>

                            <li>회사는 회원의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는 회사의 고의 또는 중대한 과실이 없는 네
                                트워크 환경으로 인하여 발생하는 문제에 대해서 책임이 면제됩니다.
                            </li>

                            <li>회사는 회원 또는 제3자가 게임서비스 내 또는 웹사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도,
                                정확성 등의 내용에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
                            </li>

                            <li>회사는 회원 상호간 또는 회원과 제3자간에 게임서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으
                                며 이로 인한 손해를 배상할책임도 없습니다.
                            </li>

                            <li>회사가 제공하는 게임서비스 중 무료서비스의 경우에는 회사의 고의 또는 중대한 과실이 없는 한 회사는
                                손해배상을 하지 않습니다.
                            </li>

                            <li>본 게임서비스 중 일부의 게임서비스는 다른 사업자가 제공하는 게임서비스를 통하여 제공될 수 있으며, 회
                                사는 다른 사업자가 제공하는 게임서비스로 인하여 발생한 손해 등에 대해서는 회사의 고의 또는 중대한
                                과실이 없는 한 책임이 면제됩니다.
                            </li>

                            <li>회사는 회원이 게임서비스를 이용하며 기대하는 캐릭터, 경험치, 아이템 등의 결과를 얻지 못하거나 상실한
                                것에 대하여 책임을 지지 않으며, 게임서비스에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서
                                회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
                            </li>

                            <li>회사는 회원의 게임상 사이버 자산(게임머니), 등급/내공 손실에 대하여 회사의 고의 또는 중대한 과실이 없
                                는 한 책임이 면제됩니다.
                            </li>

                            <li>회사는 회원의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재
                                하거나 미기재하여 손해가 발생한 경우에 대하여 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩
                                니다.
                            </li>

                            <li>회사는 관련 법령, 정부 정책 등에 따라 게임서비스 또는 회원에 따라 게임서비스 이용시간 등을 제한할 수
                                있으며, 이러한 제한사항 및 제한에 따라 발생하는 게임서비스 이용 관련 제반사항에 대해서는 책임이 면제
                                됩니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제33조 (회원의 고충처리 및 분쟁해결) </span><br/>
                        <ol>
                            <li>회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 게임 초기화면이나 게임서비스 홈
                                페이지에서 안내합니다. 회사는 이러한 회원의 의견이나 불만을 처리하기 위한 전담조직을 운영합니다.
                            </li>
                            <li>회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내
                                에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유
                                와 처리일정을 게임서비스 홈페이지(https://www.smgame.co.kr)에 공지하거나 전자우편, 전화 또는 서면
                                등으로 통보합니다.
                            </li>
                            <li>회사와 회원간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우 회사는 이용제한 등 회원에게 조치한
                                사항을 성실히 증명하고, 조정기관의 조정에 따를 수 있습니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제34조 (회원에 대한 통지) </span><br/>
                        <ol>
                            <li>회사가 회원에게 통지를 하는 경우 회원이 지정한 전자우편주소, 전자메모 등으로 할 수 있습니다.</li>
                            <li>회사는 회원 전체에게 통지를 하는 경우 7일 이상 회사의 게임사이트의 초기화면에 게시하거나 팝업화면 등
                                을 제시함으로써 제1항의통지에 갈음할 수 있습니다.
                            </li>
                        </ol>
                    </div>


                    <div className="PolicyContent">
                        <span className="PolicyTitle2">제35조 (재판권 및 준거법) </span><br/>
                        본 약관은 대한민국 법률에 따라 규율되고 해석되며, 회사와 회원간에 발생한 분쟁으로 소송이 제기되는 경우,
                        법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.<br/>
                    </div>

                    <div className="PolicyContent">
                        <span className="PolicyTitle2"></span><br/>
                    </div>

                </div>
            </div>
        </div>
    );

}

export default AuthTermOfUse;
