import React, {useEffect, useState} from 'react';
import '../css/inGamePopup.css';
import MessagePopup from "./messagePopup";
import {MessagePopupDayLimit, CheckPlatform} from "./messagePopup";
import axios from "axios";
import baseUrl from "../BaseUrl";
import $ from 'jquery';
import DayLimit from "../css/inGamePopup.module.css";

export default function DayLimitPopup() {
    const [dayLimit, setDayLimit] = useState(490);
    const [currency, setCurrency] = useState('');
    const [platformMessage, setPlatformMessage] = useState('');
    const [showCloseBtn, setShowCloseBtn] = useState(false);
    const [messagePopup, setMessagePopup] = useState(false);
    const [status, setStatus] = useState(false);
    const [dayLimitMessage, setDayLimitMessage] = useState(false);
    const [data, setData] = useState({});

    const params = new URLSearchParams(window.location.search);
    const gameName = params.get('gameName');
    const token = params.get('token');
    


    const fetchData = async () => {
        try {
            const response = await axios.get(baseUrl + 'popups/day_limit/', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            if (response.status === 200){
                setData(response.data);
            }

        } catch (e) {
            alert("Invalid token");
        }
    };

    const dayLimitMessagePopup = () => {
        if (dayLimit === data.limit_amount){
            setStatus(false);
            return setMessagePopup('설정하신 금액이 기존 설정 금액과 같습니다.');
        } else if (0 === data.remain_changes){
            setStatus(false);
            return setMessagePopup('설정은 최대 세 번만 가능합니다');
        }

        if (gameName === "sm_dualgo") {
            setDayLimitMessage(
                <span>
                    설정하셨던 {data.limit_amount} 억 {currency} 에서 {dayLimit} 억 {currency} 으로<br/>
                    일일 손실한도를 변경하시겠습니까?
                </span>
            );
        } else {
            setDayLimitMessage(
                <span>
                    설정하셨던 {data.limit_amount} 억 {currency} 에서 {dayLimit} 억 {currency} 로<br/>
                    일일 손실한도를 변경하시겠습니까?
                </span>
            );
        }


    };
    
    const changeDayLimit = async () => {
        setDayLimitMessage(false)

        if(!gameName){
            alert("invalid game name");
        }

        const post_data = {
            limit_amount: dayLimit,
            game_name: gameName
        };

        try {
            const response = await axios.post(baseUrl + 'popups/day_limit/', post_data,{
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            if (response.status === 200){
                setData(response.data);
                setMessagePopup('손실한도 금액 설정이  완료되었습니다.');
                setStatus(true);
            }

        } catch (e) {

        }
    };

    function checkDayLimit(status) {
        if(status === 'increase'){
            if (dayLimit < 490){
                setDayLimit(dayLimit + 70);
            } else {
                setStatus(false);
                setMessagePopup('사용 가능한 범위에서 한도를 선택하세요.');
            }
        } else if (status === 'decrease'){
            if (dayLimit > 70){
                setDayLimit(dayLimit - 70);
            } else {
                setStatus(false);
                setMessagePopup('사용 가능한 범위에서 한도를 선택하세요.');
            }
        }
    }

    useEffect(() => {
         $('body').css({
            'transform': 'unset',
            'min-width': 'unset',
             'zoom': 'unset',
        });

         if (!gameName) {
            return alert("gameName is missing in url parameter");
        } else if (!token){
            return alert("token is missing in url parameter");
        }

        fetchData();

        if (gameName === "sm_dualgo"){
            setCurrency('냥');
        } else {
            setCurrency('골드');
        }

        var checkPlatform = CheckPlatform();
        setPlatformMessage(checkPlatform[0]);
        setShowCloseBtn(checkPlatform[1]);
    }, []);

    return (
        <>
        {messagePopup && (<MessagePopup message={messagePopup} status={status} closeMessagePopup={setMessagePopup}/>)}

        {dayLimitMessage && (<MessagePopupDayLimit message={dayLimitMessage} closeMessagePopup={setDayLimitMessage} changeDayLimit={changeDayLimit}/>)}

            { showCloseBtn && (<div className={DayLimit.in_game_close_btn} onClick={() => window.self.close()}/>)}



            <div className={DayLimit.in_game_popup_background}>

                <div className="in_game_day_limit_tab">
                    <div className="in_game_day_limit_tab_img">
                    </div>
                </div>

                <div className="day_limit_content">
                    <div className="day_limit_content_inner">
                        <div className="day_limit_instruction">
                            게임 산업 진흥에 관한 법률에 따라 사용자가 직접 고스톱/포커류 게임의 손실 머니 금액을 설정할 수 있습니다.
                        </div>
                        <div className="day_limit_instruction">
                            손실한도 금액 초과 발생 후, 매일 00시가 된 경우 손실한도 제한이 초기화되어 게임을 즐기실 수 있습니다.
                        </div>
                        <div className="day_limit_instruction">
                            손실한도를 별도로 설정하지 않으신 경우, 490억 {currency} 으로 자동 적용됩니다.
                        </div>
                        <div className="day_limit_instruction">
                            손실한도는 월 3회 변경 가능합니다. 변경 횟수는 매월 1일 초기화 됩니다.
                        </div>
                        <div className="day_limit_instruction">
                            변경된 손실한도 금액의 적용은 게임 재실행 시 적용됩니다.
                        </div>
                    </div>
                </div>

                <div className="day_limit_buttons">
                    <div className="day_limit_buttons_button_family">
                        <div className="day_limit_left_button button_space" onClick={event => checkDayLimit('decrease')}>
                            <p className="day_limit_button_text" style={{ paddingRight: '0.781vw' }}>70억 {currency}</p>
                        </div>
                        <div className="day_limit_center_button button_space">
                            <p className="day_limit_button_text" id="changedvalue">{dayLimit}억 {currency}</p>
                        </div>
                        <div className="day_limit_right_button button_space" onClick={event => checkDayLimit('increase')}>
                            <p className="day_limit_button_text" style={{ paddingLeft: '0.781vw' }}>70억 {currency}</p>
                        </div>
                    </div>
                </div>

                <div className="day_limit_submit_btn_box">
                    <button className="in_game_submit_btn" onClick={dayLimitMessagePopup}>
                        <span>설정</span>
                    </button>
                </div>

                <div className="day_limit_remaining_limit">
                    <div className="day_limit_last_limit">
                        현재 설정된 손실한도 금액 : <br/> <br/>
                        남은 손실한도 변경 횟수 :
                    </div>
                    <div className="day_limit_last_limit_2">
                        <span style={{color: '#E4831B'}}>{data.limit_amount}억 {currency}</span><br/> <br/>
                        <span style={{color: '#E4831B'}}>{data.remain_changes} 회</span>
                    </div>
                </div>

                <div className="day_limit_bottom_txt" style={{marginTop: "18vh"}}>
                    {platformMessage}
                </div>

            </div>
        </>
    )
}
