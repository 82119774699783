import React, {useEffect, useState} from "react";
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";
import $ from "jquery";

const getDivisionText = (division) => {
    switch (division) {
        case 0:
            return '[공지]';
        case 1:
            return '[이벤트]';
        case 2:
        case 3:
        case 4:
            return '[점검]';
        default:
            return '';
    }
};


function calculateGrade(totalMoney) {
    let grade = "";

    if (totalMoney >= 1000000000) {
        grade = "신";
    } else if (totalMoney >= 500000000) {
        grade = "도신";
    } else if (totalMoney >= 100000000) {
        grade = "제왕";
    } else if (totalMoney >= 50000000) {
        grade = "초인";
    } else if (totalMoney >= 10000000) {
        grade = "지존";
    } else if (totalMoney >= 1000000) {
        grade = "영웅";
    } else if (totalMoney >= 100000) {
        grade = "고수";
    } else if (totalMoney >= 10000) {
        grade = "중수";
    } else if (totalMoney >= 1000) {
        grade = "하수";
    } else {
        grade = "초보";
    }

    return grade;
}


const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};


function RightGamesWithoutLogin() {
    const navigate = useNavigate();
    const [dateLogined, setDateLogined] = useState(sessionStorage.getItem("date_login"));
    const [grade, setGrade] = useState(sessionStorage.getItem("grade"));
    const [nickname, setNickname] = useState(sessionStorage.getItem("nickname"));
    const [notices, setNotices] = useState([]);
    const [avatarID, setAvatarID] = useState('');
    const gameName = localStorage.getItem('gameName');


    const rigthGamedata = async () => {
        try {
            const response = await axios.get(baseUrl + 'game/right_game_data/', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            });

            const data = response.data;

            setDateLogined(data.date_login.split('T')[0] + ".");
            setGrade(calculateGrade(data.money_go));
            setNickname(data.nickname);
            setNotices(data.notices);
            setAvatarID(data.avatar_id);

            sessionStorage.setItem("money_al", data.money_al.toLocaleString());
            sessionStorage.setItem("money_go", data.money_go.toLocaleString());
            sessionStorage.setItem("money_cal", data.money_cal.toLocaleString());
            sessionStorage.setItem("money_cgo", data.money_cgo.toLocaleString());

            sessionStorage.setItem("date_login", data.date_login.split('T')[0] + ".");
            sessionStorage.setItem("grade", calculateGrade(data.money_go));
            sessionStorage.setItem("nickname", data.nickname);
            sessionStorage.setItem("notice", JSON.stringify(data.notices));
            sessionStorage.setItem("avatar_id", data.avatar_id);

        } catch (error) {
            navigate('/login', {state: {redirect: window.location.href}});
            console.log(error);
        }
    };

    const noticeData = async () => {
        try {
            const response = await axios.get(baseUrl + 'game/right_game_data/');
            const data = response.data;
            setAvatarID("100");
            setNotices(data.notices);
            sessionStorage.setItem("avatar_id", "100");
            sessionStorage.setItem("notice", JSON.stringify(data.notices));
        } catch (error) {
            navigate('/login', {state: {redirect: window.location.href}});
            console.log(error);
        }
    };


    const getAvatarId = async () => {
        try {
            const response = await axios.get(baseUrl + 'my_page/get_avatar_id/', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            });

            const data = response.data;
            setAvatarID(data.avatar_id);
            sessionStorage.setItem("avatar_id", data.avatar_id);
        } catch (error) {
            console.log(error);
        }
    };


    function serviceCenter(noticeId) {
        window.location.href = `/service_center/notice_content?notice_id=${noticeId}`;
    }

    useEffect(() => {
        $('.right_warp').css({
            'height': '542px'
        });


        if (sessionStorage.getItem('token') && !sessionStorage.getItem("date_login")) {
            rigthGamedata();
        } else {
            if (sessionStorage.getItem("notice")) {
                setAvatarID(sessionStorage.getItem("avatar_id"));
                setNotices(JSON.parse(sessionStorage.getItem("notice")));
            } else {
                noticeData();
            }
        }

        if(sessionStorage.getItem('token')){
            getAvatarId();
        }

    }, [gameName]);

    return (
        <div className="right_warp">
            <div className="Rightwarp_myinfoBox">
                <div className="myinfo_imgBox">
                    {avatarID && (
                        <img src={require('../images/shop_img/' + avatarID + '.gif')} alt="user avatar"
                             onClick={() => navigate('/my_page')}/>
                    )}

                    {!avatarID && (
                        <img src={require('../images/shop_img/100.gif')} alt="user avatar"/>
                    )}

                </div>
                {grade && (
                    <div className="myinfo_textBox" style={{paddingTop: '28px'}}>

                        <em style={{color: '#7b7b7b'}}>닉네임: {nickname}<br/></em>
                        <em style={{color: '#7b7b7b'}}>등급: {grade}<br/></em>
                        <div style={{marginTop: '20px'}} onClick={event => navigate('/my_page')}>내정보보기
                        </div>
                        <div onClick={(event) => navigate('/my_page?page=myWallet')}>내지갑보기
                        </div>
                    </div>
                )
                }
                {!grade && (
                    <div className="myinfo_textBox" style={{paddingTop: '65px'}}>

                        <em style={{fontSize: '16px'}}>에스엠게임즈에<br/>오신걸 환영합니다.</em>
                    </div>
                )}

            </div>

            <div className="Rightwarp_lastjoinBox">
                <em>최종 접속일 {dateLogined}</em>
            </div>

            <div className="Rightwarp_noticeBox">
                <div className="notice_textBox">
                    <em style={{fontSize: '27px', fontWeight: 'bold'}}>공지사항</em>
                    <em className="notice_text_box_see_more">
                        <span onClick={event => navigate('/service_center/notice')}>더보기+</span><br/><br/></em>
                    {notices.map((notice, index) => (
                        <div className="notice_text_box_content" key={index}
                             onClick={event => serviceCenter(notice.notice_id)}>
                            <em>{getDivisionText(notice.division)} {notice.title}<br/></em>
                            <em className="notice_text_box_date">{formatDate(notice.date)}<br/><br/></em>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
}


export default RightGamesWithoutLogin;