import React, {useEffect, useState} from 'react';
import "../css/serviceCenter.css";
import "../css/myPage.css";
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";
import $ from 'jquery';


const QuitOKContent = () => {
    const navigate = useNavigate();

    useEffect(() => {
        $('.my_quit_box').css('height', '316px');
    }, []);

    return (
        <>
            <div className="my_quit_box">
                <div className="my_quit_title_box" style={{height: '110px'}}>
                    <div className="my_quit_ok_title">
                        회원탈퇴가<br/>
                        정상적으로 완료되었습니다.
                    </div>

                    <div className="my_quit_ok_contents"><br/>
                        <em style={{color: '#15a3e1'}}>(주)에스엠 게임즈</em>를 이용해 주셔서 감사합니다.<br/>
                        더 좋은 모습으로 찾아뵙겠습니다.
                    </div>

                </div>
            </div>

            <div className="ok_no_box_button">
                <button className="ok_no_button" onClick={event => navigate('/')}>
                    확인
                    <div className="man_ok_no_button_img">
                        <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                    </div>
                </button>
            </div>
        </>
    );
};

const QuitContent = ({changeContent}) => {
    const [agree, setAgree] = useState(false);

    const handelQuite = async () => {
        if (agree) {
            try {
                const response = await axios.post(baseUrl + 'my_page/terminate/', {},{
                    headers: {
                        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                    },
                });

                if (response.status === 200) {
                    sessionStorage.removeItem('token');
                    changeContent('quiteOK');
                }
            } catch (error) {

            }
        } else {
            alert('동의하기를 체크해주세요.');
        }
    };

    function handleAgreeButtonClick() {
        setAgree(!agree);
    }

    useEffect(() => {
        $('.my_quit_box').css('height', '416px');
    }, []);

    return (
        <>
            <div className="my_quit_box">
                <div className="my_quit_title_box">
                    <div className="my_quit_title">
                        현재 탈퇴를 진행하려는 계정 현황입니다.
                    </div>

                    <div className="my_quit_contents_box">
                        <br/><br/> 계정정보 : <em style={{color: '#7b7b7b', fontWeight: 'normal'}}>{sessionStorage.getItem('nickname')}</em> <br/>
                        <em style={{color: '#c10000', fontWeight: 'normal'}}>
                            ※ 남아 있는 캐시가 있을 시, <br/>
                            캐시가 소멸됩니다. 그대로 진행 하시겠습니까?
                        </em><br/><br/>
                    </div>

                    <div style={{
                        position: 'relative',
                        width: '100%',
                        fontSize: '16px',
                        textAlign: 'center',
                        padding: '48px 0'
                    }}>
                        <div className="my_withdraw_check_img">
                            <img
                                src={agree ? require("../images/membership_img/Icon-check.png") : require("../images/membership_img/Icon-uncheck.png")}
                                alt="Agree"
                                onClick={handleAgreeButtonClick}
                            />
                        </div>
                        모든 내용을 확인하였으며 탈퇴시 서비스 이용내역 복구가 불가함에 동의합니다.
                    </div>

                </div>
            </div>

            <div className="ok_no_box_button">
                <button className="ok_no_button" onClick={handelQuite} style={{width: '60px', textAlign: "left"}}>
                    확인
                    <div className="man_ok_no_button_img" style={{margin: '-18px 0 0 85px'}}>
                        <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                    </div>
                </button>
                <button
                    className="ok_no_button"
                    onClick={event => changeContent('withdraw')}
                    style={{backgroundColor: '#4e4e4e', marginLeft: '5px', width: '60px', textAlign: "left"}}
                >
                    탈퇴취소
                    <div className="man_ok_no_button_img" style={{margin: '-18px 0 0 85px'}}>
                        <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                    </div>
                </button>
            </div>
        </>
    );
};

const WithdrawContent = ({changeContent}) => {
    const navigate = useNavigate();

    const handelNoButtonClick = () => {
        navigate('/');
    }

    return (
        <>
            <div className="my_quit_box">
                <div className="my_quit_title_box">
                    <div className="my_quit_title">
                        <em style={{color: '#15a3e1'}}>(주)에스엠게임즈</em>
                        <em> 회원 탈퇴를 진행하려고 합니다.</em>
                        <em style={{color: '#7b7b7b', fontSize: '14px', fontWeight: 'normal'}}>
                            {' '}
                            아래의 내용을 꼭 검토해 주시고, 회원 탈퇴를 진행해 주시길 바랍니다.
                        </em>
                    </div>
                    <div className="my_quit_contents">
                        <div className="my_title_interval">○ 게임 서비스 이용 제한</div>
                        - 회원 탈퇴 시, (주)에스엠 게임즈에서 제공되는 서비스에 로그인 할 수 없으며,
                        <br/>
                        &nbsp;&nbsp;(주)에스엠 게임즈에서 이용했었던 모든 온라인 및 모바일 게임 이용이 불가능합니다.
                        <br/>
                        <div className="my_contents_interval"></div>
                        - (주)에스엠 게임즈 계정에 보유하고 있던 게임 캐릭터, 게임 아이템, 게임 전적 등의 모든 게임 정보가 삭제되고 복구할 수 없습니다.
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="ok_no_box_button">
                <button className="ok_no_button" onClick={event => changeContent('quite')}
                        style={{width: '60px', textAlign: "left"}}>
                    탈퇴진행
                    <div className="man_ok_no_button_img" style={{margin: '-18px 0 0 85px'}}>
                        <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                    </div>
                </button>

                <button
                    className="ok_no_button"
                    onClick={handelNoButtonClick}
                    style={{backgroundColor: '#4e4e4e', marginLeft: '5px', width: '60px', textAlign: "left"}}
                >
                    취소
                    <div className="man_ok_no_button_img" style={{margin: '-18px 0 0 85px'}}>
                        <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                    </div>
                </button>
            </div>
        </>
    );

};

const Withdraw = () => {
    const changeContent = (catagory) => {
        if (catagory === 'withdraw') {
            setContent(<WithdrawContent changeContent={changeContent}/>);
        } else if (catagory === 'quite') {
            setContent(<QuitContent changeContent={changeContent}/>);
        } else if (catagory === 'quiteOK'){
            setContent(<QuitOKContent/>);
        }
    };


    const [content, setContent] = useState(<WithdrawContent changeContent={changeContent}/>);


    useEffect(() => {
    }, []);


    return (
        <>
            <div className="subMaintitleBox">
                <div className="subMainGrayBox"/>
                <div className="subMaintitle">회원탈퇴</div>

                <div className="subMainDepth">내정보 > 회원탈퇴</div>
            </div>

            {content}

        </>
    );
};

export default Withdraw;
