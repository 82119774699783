import React, {useEffect} from "react";
import MobileHeader from "./mHeader";
import MobileFooter from "./mFooter";
import AuthTermOfUse from "../authentication/termOfUsePolicy";
import $ from 'jquery';

const MobileTermOfUse = () => {

    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'background-color': '#333333',
            'zoom': 'unset'
        });

        $('.m_footer_box').css('position', 'relative');
        window.scroll(0, 0);
    }, []);

    return (
        <>
            <MobileHeader/>
            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>이용약관</span>
                </div>

                <div className="m_policy_content">
                    <div className="m_policy_content_box">
                        <div className="m_agreement_contents">
                            <div className="m_agreement_contents_text">
                                <AuthTermOfUse/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MobileFooter/>
        </>
    );
}

export default MobileTermOfUse;