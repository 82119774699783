import React, {useEffect, useState} from "react";
import SubHeader from "../home/subHeader";
import $ from "jquery";
import ServiceCenterTermsOfUsePolicy from "./serviceCenterTermsOfUsePolicy";
import ServiceCenterPrivacyPolicy from "./serviceCenterPrivacyPolicy";
import ServiceCenterOperatingPolicy from "./serviceCenterOperatingPolicy";
import SubFooter from "../home/subFooter";
import {useNavigate} from "react-router-dom";

const ServiceCenterPolicyMain = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');

    const [content, setContent] = useState('');

    function changeTab(e, tabCategory) {
        $('.service_tab_links').removeClass('active');
        $(e.currentTarget).addClass('active');
        navigate('/policy?page='+tabCategory);
    }

    useEffect(() => {
        $('.service_tab_links').removeClass('active');

        if(page === "privacy_policy"){
            $('.service_tab_links').eq(1).addClass('active');
            setContent(<ServiceCenterPrivacyPolicy/>);
        }
        else if(page === 'operating_policy'){
            $('.service_tab_links').eq(2).addClass('active');
            setContent(<ServiceCenterOperatingPolicy/>);
        } else {
            $('.service_tab_links').eq(0).addClass('active');
            setContent(<ServiceCenterTermsOfUsePolicy/>);
        }

    }, [page]);

    return (
      <>
          <SubHeader bodyColor={false}/>
          <div className="TopMysection">
              <div className="TopMysection_inner" style={{justifyContent: 'left', maxWidth: '1400px'}}>
                  <div className="shop-tab">
                      <div className="tablinks">
                          <div className="shop-top-my-section-title">약관/정책</div>
                      </div>

                      <div className="service_tab_links active" onClick={event => changeTab(event, 'terms_of_use')}>
                          <div className="service-top-my-section-tit">이용약관</div>
                      </div>

                      <div className="service_tab_links" onClick={event => changeTab(event, 'privacy_policy')}>
                          <div className="service-top-my-section-tit">개인정보처리방침</div>
                      </div>


                      <div className="service_tab_links" onClick={event => changeTab(event, 'operating_policy')}>
                          <div className="service-top-my-section-tit">운영정책</div>
                      </div>
                  </div>
              </div>
          </div>

          {content}
          <SubFooter/>
      </>
  );
};

export default ServiceCenterPolicyMain;