import React, {useEffect, useState} from 'react';
import axios from "axios";
import baseUrl from "../BaseUrl";
import $ from "jquery";
import popup_ok from "../images/dualgo_ingame_img/ingame_popupOK.png";
import popup_questions from "../images/dualgo_ingame_img/Questions_file.svg";

function MobileBagAvatar() {
    const [profileAvatar, setProfileAvatar] = useState();
    const [exchange, setExchange] = useState([]);
    const [selected, setSelected] = useState([]);

    const [showPopup, setShowPopup] = useState(false);
    const [avatar_count, setAvatar_count] = useState(0);
    const [totalMoney, setTotalMoney] = useState('');
    const [exchangeAvatar, setExchangeAvatar] = useState('');

    const featchAvatar = async () => {
        try {
            const response = await axios.get(baseUrl + 'my_page/wallet', {
               headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            });

            if (response.status === 200) {
                setExchange(response.data.exchange);
                setSelected(response.data.selected);
                setProfileAvatar(response.data.profile_avatar);
            }
        } catch (error) {

        }
    };

    const calculateMoney = () => {
        setShowPopup(false);
        var money = avatar_count * 70000000;
        money =  parseInt(money.toString().replace(/0/g, ''), 10) ;
        setTotalMoney(money);
    };

    async function exchangeProfileAvatar() {
        setTotalMoney('');

        const data = {
            avatar_id: exchangeAvatar,
            d_avatar_count :avatar_count
        };

        try {
            const response = await axios.post(baseUrl + 'my_page/change_profile_avatar/', data, {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            });

            if(response.status === 200){
                const token = sessionStorage.getItem('token');
                sessionStorage.clear();
                sessionStorage.setItem('token', token);
                window.location.reload();
            }
        } catch (e) {
        }
    }


    async function changeProfileAvatar(avatar_id, d_avatar_count=null) {
        if(d_avatar_count) {
            setShowPopup(true);
            setAvatar_count(d_avatar_count);
            setExchangeAvatar(avatar_id);
            return ;
        }

        setTotalMoney('');

        const data = {
            avatar_id: avatar_id
        };

        try {
            const response = await axios.post(baseUrl + 'my_page/change_profile_avatar/', data, {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            });

            if(response.status === 200) {
                const token = sessionStorage.getItem('token');
                sessionStorage.clear();
                sessionStorage.setItem('token', token);
                window.location.reload();
            }
        } catch (e) {
        }
    }

    useEffect(() => {
         $('body').css({
            'transform': 'unset',
            'min-width': '320px',
            'zoom': 'unset'
        });

        featchAvatar();
    }, []);

    return (
        <>
            <button className="m_tab_links_a active">아바타</button>

            <div className="m_shop_section_2">
                <div className="tab_box">
                    <div style={{display: "flex", alignItems: "center", paddingLeft: "4vw"}}>
                        <div id="tab2" className="m_shop_tab_content">
                            {exchange.map((item, index) => {

                                return (
                                    <div key={index} className="m_shop_col_type1" style={{height: '35vw'}}>
                                        <div className="card card_square">
                                            <span className="m_card_img_box_game">
                                                <img loading="lazy"
                                                     src={require('../images/shop_img/' + item.d_avatarid + '.gif')}/>
                                            </span>
                                            <em className="m_shop_card_tit" style={{lineHeight: '14px'}}>{item.d_avatar_name}</em>
                                            <em className="m_shop_avatar_count">{item.d_avatar_count * 7}억 골드(냥)</em>

                                            <div
                                                onClick={event => changeProfileAvatar(item.d_avatarid, item.d_avatar_count)}>
                                                <button className="m_buy_bag_button">사용
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );

                            })}

                            {selected.map((item, index) => {

                                return (
                                    <div key={index} className="m_shop_col_type1" style={{height: '35vw'}}>
                                        <div className="card card_square">
                                            <span className="m_card_img_box_game">
                                                <img loading="lazy"
                                                     src={require('../images/shop_img/' + item.d_avatarid + '.gif')}/>
                                            </span>
                                            <em className="m_shop_card_tit" style={{lineHeight: '14px'}}>{item.d_avatar_name}</em>
                                            <em className="m_shop_avatar_count">&nbsp; </em>


                                            {profileAvatar === item.d_avatarid ?
                                                <div>
                                                    <button className="m_buy_bag_button"
                                                            style={{backgroundColor: '#4e4e4e', color: '#ffffff'}}>선택됨
                                                    </button>
                                                </div>
                                                :
                                                <div
                                                    onClick={event => changeProfileAvatar(item.d_avatarid)}>
                                                    <button className="m_buy_bag_button">선택
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                );

                            })}
                        </div>
                    </div>

                    <div className="shop_tab_center_bar" style={{position: 'relative', height: '8vw'}}></div>
                </div>
            </div>


            {showPopup && (
                <div className="m_my_wallet_popup">
                    <div className="m_my_wallet_popup_content">
                        <div className="message-popup-content" style={{marginTop: '0'}}>
                            <img style={{width: '14vw', height: '14vw'}} src={popup_questions} className="animated rubberBand" alt="OK"/>
                            <span style={{fontSize: '3.5vw', marginTop: '20px'}}>구매한 상품을 사용하시면 환불이 불가합니다.</span>
                            <span style={{fontSize: '3.5vw'}}>사용하시겠습니까?</span>
                        </div>
                        <div onClick={calculateMoney} className="m_message_pass_btn_web">
                            확 인
                        </div>
                    </div>
                </div>
            )}


            {totalMoney && (
                <div className="m_my_wallet_popup">
                    <div className="m_my_wallet_popup_content">
                        <div className="message-popup-content" style={{marginTop: '0'}}>
                            <img src={popup_ok} className="animated rubberBand" alt="OK"/>
                            <span style={{fontSize: '4vw', marginTop: '6vw'}}>{totalMoney} 골드(냥)을 획득했습니다.</span>
                        </div>
                        <div onClick={exchangeProfileAvatar} className="message-pass-btn-web">
                            확 인
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}

export default MobileBagAvatar;
