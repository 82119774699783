import React, {useEffect} from "react";
import $ from "jquery";

const MobileFooter = () => {
    const pcModel = () => {
        window.location.href = '/?device=pc';
    };

    useEffect(() => {
        $("body").css("background-color", "#333333");
    }, []);

    return (
        <>
            <div className="m_footer_box">
                <div className="m_footer_contents" style={{lineHeight: 1.4}}>
                    <div className="m_footer_link">
                        <span onClick={() => window.location.href='/mobile/term_of_use'}>이용약관<em className="m_info_txt_bar"/></span>
                        <span onClick={() => window.location.href='/mobile/privacy_policy'}>개인정보처리방침<em className="m_info_txt_bar"/></span>
                        <span onClick={pcModel}>PC버전</span>
                    </div>
                    <em style={{fontSize: '2.5vw'}}>ⓒ SMGAMES. All Rights Reserved</em>
                </div>
            </div>
        </>
    );
}

export default MobileFooter