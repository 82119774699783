import React, {useEffect} from "react";

const MobileShopRedirect = () => {
    useEffect(() => {
        window.history.go(-3);
    }, []);

    return (
        <div>

        </div>
    );

};

export default MobileShopRedirect;