const ShopArrayM = [
    ["101", "장원급제", 10000, 700000000],
    ["102", "애견카페", 10000, 700000000],
    ["103", "삐약놀이터", 10000, 700000000],
    ["104", "드론택배", 10000, 700000000],
    ["105", "칵테일한잔", 10000, 700000000],
    ["106", "와인한잔", 10000, 700000000],
    ["107", "노래와함께", 10000, 700000000],
    ["108", "개굴놀이터", 10000, 700000000],
    ["109", "폭주라이더", 10000, 700000000],
    ["110", "나이트헌터", 10000, 700000000],
    ["111", "불꽃슈터", 10000, 700000000],
    ["112", "낙엽떨어진날", 10000, 700000000],
    ["113", "토끼마법사", 10000, 700000000],
    ["114", "시상식대상", 10000, 700000000],
    ["115", "남극탐험", 10000, 700000000],
    ["116", "동해강태공", 10000, 700000000],
    ["117", "바가지사장님", 10000, 700000000],
    ["118", "흰머리레이서", 10000, 700000000],
    ["119", "바비보이", 10000, 700000000],
    ["120", "레트로파이터", 10000, 700000000],
    ["121", "자산관리", 10000, 700000000],
    ["122", "오함마기술자", 10000, 700000000],
    ["123", "폭죽촬영", 10000, 700000000],
    ["124", "헬스사이클", 10000, 700000000],
    ["125", "근육히어로", 10000, 700000000]
];


const ShopArrayW = [
    ["1001", "냥이집사", 10000, 700000000],
    ["1002", "양갈래학생", 10000, 700000000],
    ["1003", "파란걸", 10000, 700000000],
    ["1004", "불타는걸", 10000, 700000000],
    ["1005", "사랑여", 10000, 700000000],
    ["1006", "치파오", 10000, 700000000],
    ["1007", "캠핑하개", 10000, 700000000],
    ["1008", "야타녀", 10000, 700000000],
    ["1009", "다리꼬지마", 10000, 700000000],
    ["1010", "유리부츠", 10000, 700000000],
    ["1011", "붉은공주", 10000, 700000000],
    ["1012", "연지곤지", 10000, 700000000],
    ["1013", "여름휴가", 10000, 700000000],
    ["1014", "얼음겅듀", 10000, 700000000],
    ["1015", "음악과함께", 10000, 700000000],
    ["1016", "놀이동산", 10000, 700000000],
    ["1017", "사장님애인", 10000, 700000000],
    ["1018", "레드카레이서", 10000, 700000000],
    ["1019", "바비걸", 10000, 700000000],
    ["1020", "비오는날", 10000, 700000000],
    ["1021", "주식관리", 10000, 700000000],
    ["1022", "복부인", 10000, 700000000],
    ["1023", "폭죽셀카", 10000, 700000000],
    ["1024", "헬스바이크", 10000, 700000000],
    ["1025", "섹시히어로", 10000, 700000000]
];


const SMPL_Male = [
    ["2001", "2001", 10000, 700000000],
    ["2002", "2002", 10000, 700000000],
    ["2003", "2003", 10000, 700000000],
    ["2004", "2004", 10000, 700000000],
    ["2005", "2005", 10000, 700000000],
    ["2006", "2006", 10000, 700000000],
    ["2007", "2007", 10000, 700000000],
    ["2008", "2008", 10000, 700000000],
    ["2009", "2009", 10000, 700000000],
    ["2010", "2010", 10000, 700000000],
    ["2011", "2011", 10000, 700000000],
    ["2012", "2012", 10000, 700000000],
    ["2013", "2013", 10000, 700000000],
    ["2014", "2014", 10000, 700000000],
    ["2015", "2015", 10000, 700000000],
    ["2016", "2016", 10000, 700000000],
    ["2017", "2017", 10000, 700000000],
    ["2018", "2018", 10000, 700000000],
    ["2019", "2019", 10000, 700000000],
    ["2020", "2020", 10000, 700000000],

    ["4001", "4001", 10000, 700000000],
    ["4002", "4002", 10000, 700000000],
    ["4003", "4003", 10000, 700000000],
    ["4004", "4004", 10000, 700000000],
    ["4005", "4005", 10000, 700000000],
    ["4006", "4006", 10000, 700000000],
    ["4007", "4007", 10000, 700000000],
    ["4008", "4008", 10000, 700000000],
    ["4009", "4009", 10000, 700000000],
    ["4010", "4010", 10000, 700000000],

];


const SMPL_Female = [
    ["3001", "3001", 10000, 700000000],
    ["3002", "3002", 10000, 700000000],
    ["3003", "3003", 10000, 700000000],
    ["3004", "3004", 10000, 700000000],
    ["3005", "3005", 10000, 700000000],
    ["3006", "3006", 10000, 700000000],
    ["3007", "3007", 10000, 700000000],
    ["3008", "3008", 10000, 700000000],
    ["3009", "3009", 10000, 700000000],
    ["3010", "3010", 10000, 700000000],
    ["3011", "3011", 10000, 700000000],
    ["3012", "3012", 10000, 700000000],
    ["3013", "3013", 10000, 700000000],
    ["3014", "3014", 10000, 700000000],
    ["3015", "3015", 10000, 700000000],
    ["3016", "3016", 10000, 700000000],
    ["3017", "3017", 10000, 700000000],
    ["3018", "3018", 10000, 700000000],
    ["3019", "3019", 10000, 700000000],
    ["3020", "3020", 10000, 700000000],


    ["4001", "4001", 10000, 700000000],
    ["4002", "4002", 10000, 700000000],
    ["4003", "4003", 10000, 700000000],
    ["4004", "4004", 10000, 700000000],
    ["4005", "4005", 10000, 700000000],
    ["4006", "4006", 10000, 700000000],
    ["4007", "4007", 10000, 700000000],
    ["4008", "4008", 10000, 700000000],
    ["4009", "4009", 10000, 700000000],
    ["4010", "4010", 10000, 700000000],

];


const getRandomItems = (category, gender) => {
    var ShopArray;
    if (category === 'smpl_avatars') {
        if (gender === 'y'){
            ShopArray =  SMPL_Male;
        } else {
            ShopArray =  SMPL_Female;
        }
    }
    else {
        if (gender === 'y'){
            ShopArray =  ShopArrayM;
        } else {
            ShopArray =  ShopArrayW;
        }
    }


    const selectedItems = [];
    const itemIndexes = [];

    while (selectedItems.length < 10) {
        const randomIndex = Math.floor(Math.random() * ShopArray.length);
        if (!itemIndexes.includes(randomIndex)) {
            itemIndexes.push(randomIndex);
            selectedItems.push(ShopArray[randomIndex]);
        }
    }

    return selectedItems;
};

export function getAvatars(category, gender) {
    if(category === 'smpl_avatars'){
        if (gender === 'y'){
            return SMPL_Male;
        } else {
            return SMPL_Female;
        }
    } else {
        if (gender === 'y'){
            return ShopArrayM;
        } else {
            return ShopArrayW;
        }
    }


}

export default getRandomItems;