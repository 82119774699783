import React from "react";


const GoStopPointTable = () => {
    return [
        <>
            기쁨은 나눠도 3배!<br/>
            어디서나 고스톱을<br/>
            플레이해보세요!
        </>,

        <>
            <em>SM 고~스톱은 PC, Mobile 환경</em>에<br/>
            구애받지 않고 게임을<br/>
            플레이하실 수 있습니다. <br/>
            3명의 플레이어로 재미가<br/>
            흘러넘치는 고스톱을 즐겨보세요!
        </>,

        <>
        귀를 호강시켜주는<br/>
                        사운드!<br/>
                        시선을 사로잡는 효과!
        </>,

        <>
            패와 패가 짝! 짝! 맞을 때 마다<br/>
                        경쾌하게 울려퍼지는 사운드와,<br/>
                        족보 달성이나 승리 등에 의한<br/>
                        생동감 넘치는 애니메이션 효과를<br/>
                        즐겨보세요! 저희 SM 고~스톱에<br/>
                        푹~ 빠지게 될거에요!<br/>
        </>,

        <>
            개성 만점, 내 캐릭터의<br/>
                        옷을 꾸며봐요!
        </>,

        <>
        상점에서 구매한 아바타(의상)<br/>
                        등을 옷장에서 본인의<br/>
                        개성과 취향에 맞추어 입혀보세요.<br/>
                        상대방에게 나만의 개성을<br/>
                        돋보이게 만들어 줄거에요!<br/>
        </>


    ];
}


function SuddaPointTable() {
    return [
        <>
            16:9 고퀄리티<br/>
            섯다
        </>,

        <>
            SM 섯다는<br/>
            <em>16:9 해상도를 지원</em>하여,<br/>
            언제 어디서나 깔끔하고 쾌적한<br/>
            플레이를 즐기실 수 있어요!
        </>,

        <>
            스릴 넘치는<br/>
            끗장 승부
        </>,

        <>
            한끗, 두끗…<br/>
            38광땡까지 클래식한<br/>
            규칙으로 스릴넘치는<br/>
            끗장 승부를 봅시다!<br/>
            긴장감과 심리전이 넘치는 베팅은 1+1!
        </>,

        <>
            나의 개성을 뽐내볼까
        </>,

        <>
            고퀄리티의 플레이어<br/>
            아바타, 꾸미기 상품, 패 뒷면 스킨<br/>
            등등의 자신만의 개성을<br/>
            뿜뿜! 해보세요!
        </>


    ];
}


function DarkworldPointTable(){
    return [
        <>
            언제, 어디서나<br/>
            플레이 할 수 있습니다!
        </>,

        <>
            Dark World 는<br/>
            <em>Cross-Platform기</em>술을 적용하여,<br/>
            언제, 어디서나 최적화된 환경에서<br/>
            쾌적하게 플레이할 수 있습니다.
        </>,

        <>
            세력의 이권을 위해<br/>
            분주히 움직이십시오!
        </>,

        <>
            휴먼, 엘프로 구성된 이너월드 세력과<br/>
            악마, 늑대인간으로 구성된<br/>
            아우터 월드 세력과<br/>
            경쟁하십시오!<br/>
            당신이 강해질수록<br/>
            세력도 같이 강해집니다.
        </>,

        <>
            다양한 협동 및<br/>
            경쟁 컨텐츠
        </>,

        <>
            필드 보스 사냥, 레이드 공략,<br/>
            세력전, PVP, 장비 수집, 업적,<br/>
            커스터마이징 등 무엇하나<br/>
            빼 놓을 수 없는<br/>
            무한한 재미에 빠져드십시오
        </>
    ];
}


function BattlesniperPointTable() {

    return [
        <>
            언제 어디서나<br/>
            펼쳐지는 전장
        </>,

        <>
            Battle Sniper는<br/>
            <em>PC와 Mobile에 관계없이</em><br/>
            같이 즐길 수 있는<br/>
            FPS 게임입니다.<br/>
        </>,

        <>
            다양한 전략적<br/>
            요소 극대화
        </>,

        <>
            지형, 지물, 날씨, 환경 모든 것을<br/>
            이용하여 자신의 방어 또는 공격에<br/>
            <em>유용하게 사용</em>할 수 있습니다.<br/>
            이러한 모든 것을 토대로 전략을 짜내<br/>
            전장을 플레이하십시오.
        </>,

        <>
        극한의 생존<br/>
        </>,

        <>
            <em>최후의 1인이 승자</em>가 됩니다.<br/>
            다양한 전략과 요소를 이용해<br/>
            최후의 1인이 되십시오.
        </>

    ];
}

function ProbaseballPointTable() {
    return [
        <>
            언제 어디서나 끊임없이<br/>
            손을 떼지 못하는<br/>
            야구 게임
        </>,

        <>
            SM 프로 베이스볼은 <em>PC, Mobile에서<br/>
            게임</em>을 즐기실 수 있습니다.<br/>
            PC, Mobile 각각 상호보완적인 관계로<br/>
            SM 프로 베이스볼에서<br/>
            손을 떼지 못하고 경기를<br/>
            즐기실 수 있습니다!
        </>,

        <>
            경기와 매니지먼트를<br/>
            동시에!
        </>,

        <>
            SM 프로 베이스볼은 야구 경기의<br/>
            강렬한 타격감! 을 선사하여<br/>
            컨트롤의 재미를 느낄 수 있고<br/>
            <em>선수들을 직접 관리하고 육성하는<br/>
                시스템</em>으로 매니지먼트의<br/>
            재미까지 잡았습니다.
        </>,

        <>
            끊임없이 즐길 수 있는<br/>
            컨텐츠!
        </>,

        <>
            단순히 선수 육성, 경기 플레이<br/>
            뿐만 아니라, 다른 구단과 선수를<br/>
            거래하거나 실제 리그 스케쥴에<br/>
            따라 즐길 수 있는 <em>리얼 리그 모드</em>로<br/>
            실제 구단을 플레이하듯 즐기며<br/>
            푸짐한 보상을 획득할 수 있습니다.
        </>
    ];

}


function BadukPointTable() {
    return [
        <>
            언제 어디서나<br/>
            즐기는 바둑 게임
        </>,

        <>
            SM 바둑은 언제, 어디서나,<br/>
            남녀노소 쉽게 SM 바둑을<br/>
            즐기실 수 있습니다.<br/>
            <em>PC, Mobile 동일한 환경</em>에서<br/>
            심오한 바둑에 세계로 빠져보세요.
        </>,

        <>
            클래식한 바둑 게임
        </>,

        <>
            SM 바둑은 정통 바둑의 느낌을<br/>
            한가득 살렸습니다.<br/>
            <em>고풍스러운 바둑알과 바둑판</em>은<br/>
            더욱 게임에 몰입할 수 있도록<br/>
            도와줍니다.
        </>,

        <>
            착착 감기는<br/>
            바둑 게임
        </>,

        <>
            SM 바둑은 착착 손에 감기는<br/>
            재미가 있습니다. 착수 진동 효과나,<br/>
            음성 효과 등을 통해 플레이어가<br/>
            직접 바둑을 두고 있다고<br/>
            느끼게 만들고, 여러 아이템을 통해<br/>
            <em>본인의 개성</em>을 뽐내<br/>
            더욱 몰입할 수 있게 만듭니다.
        </>
    ];
    return (
        <div className="PointBackgoround">
            <div className="m_point_box">
                <div style={{height: '81px'}}></div>
                <div className="m_point_b_text">
                    언제 어디서나<br/>
                    즐기는 바둑 게임
                </div>
            </div>
            <div className="m_point_box" style={{marginLeft: '407px'}}>
                <div style={{height: '48px'}}></div>
                <div className="m_point_s_text">
                    SM 바둑은 <em style={{fontWeight: 'bold'}}>정통 바둑</em>의<br/>
                    느낌을 한가득 살렸습니다.<br/>
                    고풍스러운 바둑알과 바둑판은<br/>
                    더욱 게임에 몰입할 수 있도록<br/>
                    도와줍니다.
                </div>
            </div>
            <div className="m_point_box" style={{marginLeft: '814px'}}>
                <div style={{height: '102px'}}></div>
                <div className="m_point_b_text">
                    착착 감기는 바둑 게임<br/>
                </div>
            </div>
            <div className="m_point_box" style={{marginTop: '244px'}}>
                <div style={{height: '70px'}}></div>
                <div className="m_point_s_text">
                    SM 바둑은<em style={{fontWeight: 'bold'}}> 언제, 어디서나, 남녀노소</em><br/>
                    쉽게 SM 바둑을 즐기실 수 있습니다.<br/>
                    PC, Mobile 동일한 환경에서<br/>
                    심오한 바둑에 세계로 빠져보세요.
                </div>
            </div>
            <div className="m_point_box" style={{marginTop: '244px', marginLeft: '407px'}}>
                <div style={{height: '82px'}}></div>
                <div className="m_point_b_text">
                    클래식한<br/>
                    바둑 게임
                </div>
            </div>
            <div className="m_point_box" style={{marginTop: '244px', marginLeft: '814px'}}>
                <div style={{height: '19px'}}></div>
                <div className="m_point_s_text">
                    SM 바둑은 착착 손에 감기는<br/>
                    재미가 있습니다.<br/>
                    착수 진동 효과나, 음성 효과등을 통해<br/>
                    플레이어가 직접 바둑을 두고 있다고 <br/>
                    느끼게 만들고, 여러 아이템을 통해<br/>
                    <em style={{fontWeight: 'bold'}}>본인의 개성</em>을 뽐내<br/>
                    더욱 몰입할 수 있게 만듭니다.
                </div>
            </div>
        </div>
    );
}


function JanggiPointTable() {
    return [
        <>
            Cross-Platform
        </>,

        <>
            언제, 어디서나, 남녀노소 쉽게<br/>
            SM 장기를 즐기실 수 있습니다.<br/>
            PC, Mobile 동일한 룰로 긴장감 넘치는<br/>
            장기를 즐기세요!<br/>
        </>,

        <>
            Classic
        </>,

        <>
            전통 장기의 멋과 맛을 살렸습니다.<br/>
            고풍스러운 기물과 판의 디자인,<br/>
            클래식한 게임 룰을 통해<br/>
            최고가 되어 볼까요?<br/>
        </>,

        <>
            Casual
        </>,

        <>
            전통의 멋은 그대로 유지하고,<br/>
            플레이어 개성을<br/>
            뽐낼 수 있는 다양한 요소들이<br/>
            준비되어 있습니다.<br/>
            장기 기물이나, 판 등을 변경하고,<br/>
            아바타를 바꿔 본인의 취향에 맞는<br/>
            플레이를 할 수 있답니다!
        </>


    ];
}


function MatchingPointTable() {

    return [
        <>
            PC? Mobile? 관계없이<br/>
            드루와~ 드루와~
        </>,

        <>
            SM matching은<br/>
            <em>PC와 Mobile에</em> 관계없이<br/>
            같이 즐길 수 있는<br/>
            크로스 플랫폼 게임이에요
        </>,

        <>
            손에 땀을 쥐게 하는<br/>
            타이밍 승부

        </>,

        <>
            여러분이 직접 버튼을 눌러,<br/>
            떨어지는 그림을 맞춰보세요!<br/>
            대박 타이밍이 당신의 손에~!?
        </>,

        <>
            모두와 함께하는<br/>
            SM matching
        </>,

        <>
            인게임에서 제공되는<br/>
            채팅창으로 게임을 즐기며,<br/>
            커뮤니케이션 활동을<br/>
            할 수 있어요!
        </>
    ];
}

function UpcomingGamesPointTable(props) {
    const gamesList = {
        game_gostop: GoStopPointTable(),
        game_sudda: SuddaPointTable(),
        game_darkworld: DarkworldPointTable(),
        game_battlesniper: BattlesniperPointTable(),
        game_probaseball: ProbaseballPointTable(),
        game_baduk: BadukPointTable(),
        game_janggi: JanggiPointTable(),
        game_matching: MatchingPointTable()
    };


    const content =  gamesList[props.gameName];

    return (
        <>
            <div className="m_point_background m_point_img_1">

                <div className="m_point_box">
                    <div className="m_point_b_text">
                        {content[0]}
                    </div>
                </div>

                <div className="m_point_box">
                    <div className="m_point_s_text">
                        {content[1]}
                    </div>
                </div>

            </div>


            <div className="m_point_background m_point_img_2">

                <div className="m_point_box">
                    <div className="m_point_b_text">
                        {content[2]}
                    </div>
                </div>

                <div className="m_point_box">
                    <div className="m_point_s_text" style={{color: '#15a3e1'}}>
                        {content[3]}
                    </div>
                </div>

            </div>


            <div className="m_point_background m_point_img_3">

                <div className="m_point_box">
                    <div className="m_point_b_text">
                        {content[4]}
                    </div>
                </div>

                <div className="m_point_box">
                    <div className="m_point_s_text">
                        {content[5]}
                    </div>
                </div>

            </div>

        </>
    );
}


export default UpcomingGamesPointTable;