import React from 'react';
import icon_plush from '../images/Icon-plus.png';
import icon_won from '../images/Icon-won.png';

import getRandomItems, {getAvatars} from "../shop/avatar";
import {useNavigate} from "react-router-dom";
import $ from "jquery";

function ShopSection( ) {
    const navigate = useNavigate();
    const gender = localStorage.getItem('gender');
    const params = new URLSearchParams(window.location.search);
    var gameName = params.get('gameName');
    var shopAvatarArray;

    if(gameName === 'sm_smpl'){
        shopAvatarArray = getRandomItems('smpl_avatars', gender);
    } else {
        shopAvatarArray = getRandomItems('main_avatars', gender);
    }


    function numberToKorean(number) {
        var inputNumber = number < 0 ? false : number;
        var unitWords = ['', '만', '억', '조', '경'];
        var splitUnit = 10000;
        var splitCount = unitWords.length;
        var resultArray = [];
        var resultString = '';

        for (var i = 0; i < splitCount; i++) {
            var unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
            unitResult = Math.floor(unitResult);
            if (unitResult > 0) {
                resultArray[i] = unitResult;
            }
        }

        for (var i = 0; i < resultArray.length; i++) {
            if (!resultArray[i]) continue;
            resultString = String(resultArray[i]) + unitWords[i] + resultString;
        }

        return resultString;
    }

    function numberFormat(number) {
        return Intl.NumberFormat().format(number);
    }

    function buyAvatar (avatarId, reward, price, avatarName) {
        localStorage.setItem('avatarId', avatarId);
        localStorage.setItem('reward', reward);
        localStorage.setItem('avatarPrice', price);
        localStorage.setItem('avatarName', avatarName);
        navigate('/buy_avatar_web');
    }

    return (
        <div className="shop_section">
            <div className="tab_box">
                <div className="section_tit_boxGame">
                    <div className="section_tit_box_inner_game">
                        <em className="section_tit" style={{cursor: 'pointer'}} onClick={() => window.location.href = gameName === 'sm_smpl' ? '/shop?page=smpl_avatars': '/shop'}>
                            상점 <img loading="lazy" src={icon_plush} style={{padding: '8px 0px'}}/>
                            <a className="section_tit_box_underL" style={{width: '30px', margin: '37px -80px'}}></a>
                            <a className="section_tit_box_underL2" style={{width: '55px', margin: '37px -53px'}}></a>
                        </em>
                    </div>
                </div>
                <div className="tab">
                    {/*{gender === 'y' ? (*/}
                    {/*    <button className="tablinks_game active"*/}
                    {/*            onClick={(event) => openTab(event, 'tab2')}>남성아바타</button>*/}
                    {/*) : gender === 'n' ? (*/}
                    {/*    <button className="tablinks_game active"*/}
                    {/*            onClick={(event) => openTab(event, 'tab3')}>여성아바타</button>*/}
                    {/*) : (*/}
                    {/*    <button className="tablinks_game active"*/}
                    {/*            onClick={(event) => openTab(event, 'tab1')}>인기아바타</button>*/}
                    {/*)}*/}
                    <button className="tablinks_game" style={{cursor: 'default'}}></button>
                    <button className="tablinks_game" style={{cursor: 'default'}}></button>
                    <button className="tablinks_game" style={{cursor: 'default'}}></button>
                </div>

                <div id="tab2" className="shop-tab-content">
                    {shopAvatarArray.map((item, index) => {
                        const avatarId = item[0];
                        const avatarName = item[1];
                        const price = item[2];
                        const reward = item[3];

                        return (
                            <div key={index} className="shop_card_item game_shop_col_type">
                                <div className="card card_square">
                                            <span className="card_img_box_game"
                                                  onClick={() => buyAvatar(avatarId, reward, price, avatarName)}>
                                                <img loading="lazy"
                                                     src={require('../images/shop_img/' + avatarId + '.gif')} alt=""/>
                                            </span>
                                    <em className="card_tit">{avatarName}</em>
                                    <span className="card_txt">({numberToKorean(reward)}골드 지급)</span>
                                    <div className="shop_card_price"><img loading="lazy" src={icon_won}
                                                                          style={{padding: '6px 3px'}}/>{numberFormat(price)}원
                                    </div>
                                    <input type="hidden" style={{width: '100%'}} id="goodname" name="goodname"
                                           value={avatarName}/>
                                    <input type="hidden" style={{width: '100%'}} id="Rgoodname" name="Rgoodname"
                                           value={avatarId}/>
                                    <div onClick={event => buyAvatar(avatarId, reward, price, avatarName)}>
                                        <button className="buybutton">구매</button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>


                {!gender && (
                    <div id="tab1" className="tabcontentGame">
                        <div className="card_tit" style={{width: '100%', margin: '180px auto', textAlign: 'center'}}>로그인
                            후 이용해주세요.
                        </div>
                    </div>
                )}
                <div id="tab4" className="tabcontentGame" style={{display: 'none'}}></div>
            </div>
        </div>
    );
}

export default ShopSection;
