import "../css/shop.css";
import React, {useEffect, useState} from "react";
import icon_button from "../images/mypage_img/Icon-button.png";
import $ from 'jquery';
import MobileHeader from "./mHeader";
import MobileFooter from "./mFooter";
import MobilePaymentRequest from "../payment/mobilePaymentRequest";
import {useNavigate} from "react-router-dom";


function numberToKorean(number) {
    var avatarQuantity = number < 0 ? false : number;
    var unitWords = ['', '만', '억', '조', '경'];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = '';

    for (var i = 0; i < splitCount; i++) {
        var unitResult = (avatarQuantity % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
        unitResult = Math.floor(unitResult);
        if (unitResult > 0) {
            resultArray[i] = unitResult;
        }
    }

    for (var i = 0; i < resultArray.length; i++) {
        if (!resultArray[i]) continue;
        resultString = String(resultArray[i]) + unitWords[i] + resultString;
    }

    return resultString;
}

function MobileBuyAvatarWebOK(props) {
    const navigate = useNavigate();
    const [reward, setReward] = useState('');
    const [avatarQuantity, setAvatarQuantity] = useState(1);
    const [paymentData, setPaymentData] = useState(null);

    const avatarId = localStorage.getItem('avatarId');
    const avatarPrice = localStorage.getItem('avatarPrice');
    const avatarName = localStorage.getItem('avatarName');
    const avatarCount = localStorage.getItem('avatarCount');

    const handleInputChange = (event) => {
        let inputValue = event.target.value;

        inputValue = inputValue.replace(/[^0-9\s]/g, '');
        if (inputValue) {
            const newValue = Math.min(Math.max(parseInt(inputValue || 0), 1), 70);
            setAvatarQuantity(newValue);
        } else {
            setAvatarQuantity(inputValue);
        }

    };


    useEffect(() => {
        $('body').css({
            'transform': 'unset',
            'min-width': '320px',
            'zoom': 'unset'
        });

        const reward = localStorage.getItem('reward');

        setAvatarQuantity(avatarCount);
        setReward(reward);

    }, []);


    return (
        <>
            <MobileHeader/>
            <div style={{background: '#f5f5f5'}}>

                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>아바타 구매</span>
                </div>

                <div className="ShopGameCenterwarpMain">
                    <div className="m_buy_shop_center_wrap">
                        <div className="m_buy_section">
                            <div className="m_buy_contents">
                                <div className="m_buy_avatar_img">
                                    <img src={require('../images/shop_img/' + avatarId + '.gif')} alt="Avatar"/>
                                </div>
                                <div className="m_buy_contents_inner">
                                    <div className="m_buy_title_box" style={{borderBottom: '2px solid #4e4e4e'}}>
                                        {avatarName}
                                    </div>
                                    <div className="m_buy_contents_box">
                                        <div className="m_buy_contents_title_box">수량</div>
                                        <div className="m_buy_contents_text_box">
                                            <div className="m_count_number">
                                                <input type="text" id="numberUpDown" value={avatarQuantity}
                                                       onChange={handleInputChange}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="m_buy_contents_box">
                                        <div className="m_buy_contents_title_box">게임머니</div>
                                        <div className="m_buy_contents_text_box">
                                            <div style={{marginTop: '13px'}}>
                                                <em id="GetGold">{numberToKorean(reward * avatarQuantity)} 골드(냥) 지급</em>
                                            </div>
                                            <input type="hidden" id="PHPreward" value={reward}/>
                                        </div>
                                    </div>
                                    <div className="m_buy_contents_box">
                                        <div className="m_buy_contents_title_box">결제금액</div>
                                        <div className="m_buy_contents_text_box">
                                            <div id="BuyPayment"
                                                 style={{marginTop: '13px'}}>{new Intl.NumberFormat().format(avatarPrice * avatarQuantity)}원
                                            </div>
                                            <input type="hidden" id="PHPBuyPayment"
                                                   value={avatarPrice * avatarQuantity}/>
                                        </div>
                                    </div>

                                    <div className="m_buy_money_text_box">
                                        <div className="m_buy_money_text_box_inner" >
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    marginTop: '1.5vw',
                                                    textAlign: 'center',
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: "100%",
                                                    lineHeight: '4vw',
                                                }}
                                            >
                                                구매가 완료 되었습니다.<br/>
                                                내지갑에서 사용한 아바타는 환불이 불가능합니다. <br/>
                                                좋은시간 되세요~
                                            </div>
                                        </div>
                                    </div>

                                    <div className="quit_oknoBox_button">
                                        <button className="m_quit_ok_no_button" type="button" onClick={() => navigate('/mobile')}>
                                            확인
                                            <div className="m_quit_ok_no_button_img">
                                                <img src={icon_button} alt="구매하기"/>
                                            </div>
                                        </button>
                                        <button className="m_quit_ok_no_button" type="button" onClick={() => {navigate('/mobile/bag')}} style={{backgroundColor: '#4e4e4e'}}>
                                            내지갑으로
                                            <div className="m_quit_ok_no_button_img">
                                                <img src={icon_button} alt="취소"/>
                                            </div>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
            {paymentData && <MobilePaymentRequest paymentData={paymentData}/>}

            <MobileFooter/>
        </>
    );
}

export default MobileBuyAvatarWebOK;