import React, {useEffect, useState} from "react";
import '../css/webGame.css';
import PokerGameBanner from '../file/sub_banner/Poker.mp4';
import DualgoGameBanner from '../file/sub_banner/Gostop.mp4';
import BadukiGameBanner from '../file/sub_banner/Baduki.mp4';
import SMPLGameBanner from '../file/sub_banner/SMPL.mp4';
import ShopSection from "./gameShop";
import GameFooter from "./gameFooter";
import RightGames from "./rightGames";
import SubHeader from "../home/subHeader";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import baseUrl from "../BaseUrl";


function MainGames(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    var gameName = params.get('gameName');


    try {
        gameName = location.state.gameName;
    } catch (e) {

    }


    const [gameBanner, setGameBanner] = useState(DualgoGameBanner);
    const [showRestrictionPopup, setShowRestrictionPopup] = useState(localStorage.getItem('is_user_restricted'));

    const donloadGame = async () => {
        try {
            const response = await axios.get(`${baseUrl}game/game_download/?game_name=${gameName}`, {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
                responseType: 'blob', // Important to handle the file download
            });

            // Create a URL for the file and trigger the download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', gameName + '.exe'); // Set the downloaded file name
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            console.error('Error downloading file:', e);
        }
    };

    const openGuide = () => {
        var guide;

        if(gameName === 'sm_dualgo'){
            guide = '/guide_dualgo';
        }
        else if(gameName === 'sm_baduki'){
            guide = '/guide_baduki';
        }
        else if(gameName === 'sm_poker'){
            guide = '/guide_7poker';
        }
        else if(gameName === 'sm_smpl'){
            return ;
        }

        window.open(guide, '_blank', 'width=885px,height=630px,toolbars=no,scrollbars=no,left=485,top=163');

    };

    useEffect(() => {
        localStorage.setItem('gameName', gameName);
        window.scrollTo(0, 0);
        setShowRestrictionPopup(localStorage.getItem('is_user_restricted'));

        if (gameName === "sm_dualgo") {
            setGameBanner(DualgoGameBanner);
        } else if (gameName === "sm_baduki") {
            setGameBanner(BadukiGameBanner);
        } else if (gameName === "sm_poker") {
            setGameBanner(PokerGameBanner);
        } else if (gameName === "sm_smpl") {
            setGameBanner(SMPLGameBanner);
        } else {
            setGameBanner(DualgoGameBanner);
        }

    }, [gameName]);

    return (
        <>
            <SubHeader/>
            <div id="TopVideo" className="TopGamesection3">
                <video className="TopGamesection_video3" src={gameBanner} autoBuffer autoPlay muted loop playsInline frameBorder="0"></video>
                <div className="game-banner-buttons">
                    <div className="game-download-btn" onClick={donloadGame}/>
                    <div className="game-guide-btn" onClick={openGuide}/>
                </div>
            </div>

            <div className="GameCenterwarpMain">
            <div className="GameCenterwarp">
                    <div className="GameCenterwarp_inner">
                        <ShopSection/>
                    </div>

                    <RightGames/>
                </div>
            </div>

            {showRestrictionPopup == 1 && (<div className="user_restriction_popup"  style={{paddingTop: '300px'}}>
                <div className="user_restriction_popup-content" style={{width: '650px', height: '270px'}}>
                    <div className="user_restrict_popup_div_1">
                        <span style={{width: '100%', textAlign: 'center'}}>자가 이용제한 안내</span>
                    </div>


                    <div className="user_restrict_popup_div_2">
                        회원님께서는 자가 이용제한을 설정하여 웹보드류 게임 이용이 제한됩니다.
                    </div>

                    <div className="user_restrict_popup_div_3">
                        <div id="restricted_date">
                            <span>해지 예정일: {localStorage.getItem('restricted_date_upto')}</span>
                        </div>
                    </div>

                    <div className="ok_no_box_button">
                        <button className="ok_no_button" onClick={event => navigate('/service_center/inquiry?page=selfUserRestriction')}>
                            이용제한내역 보기
                            <div className="man_ok_no_button_img" style={{margin: '-18px 0 0 144px'}}>
                                <img loading="lazy" src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                            </div>
                        </button>

                        <button className="ok_no_button" style={{marginLeft: '5px'}} onClick={event => navigate('/')}>
                            확인
                            <div className="man_ok_no_button_img">
                                <img loading="lazy" src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                            </div>
                        </button>


                    </div>

                </div>

            </div>)}

            <GameFooter  gameName={gameName}/>
        </>
    );
}

export default MainGames;