import React, {useEffect, useState} from 'react';

const PaymentPopup = () => {

    useEffect(() => {
        // Load INIStdPay script
        const script = document.createElement('script');
        script.src = 'https://stdpay.inicis.com/stdjs/INIStdPay_popup.js';
        script.charset = 'UTF-8';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    return (
        <>
        </>
    );
};

export default PaymentPopup;
