import React, {useEffect} from "react";

const ShopRedirect = () => {
    useEffect(() => {
        if(/firefox/i.test(navigator.userAgent)){
            window.location.href = "/shop";
        } else {
            window.history.go(-3);
        }
    }, []);

    return null;

};

export default ShopRedirect;