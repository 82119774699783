import axios from "axios";
import baseUrl from "../BaseUrl";

const VerifyToken = async () => {
    if (sessionStorage.getItem('token')) {
        try {
            const response = await axios.get(baseUrl + 'verify_token', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
            });

            if (response.status === 200) {
                sessionStorage.setItem('tokenVerified', true);
                return true;
            }

        } catch (e) {
            sessionStorage.removeItem('token');
            sessionStorage.setItem('tokenVerified', false);
            return false;
        }
    } else {
        sessionStorage.setItem('tokenVerified', false);
        return false;
    }

};

export default VerifyToken;