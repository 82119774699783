import SubHeader from "../home/subHeader";
import "../css/shop.css";
import "../css/serviceCenter.css";
import RightGames from "../game/rightGames";
import React, {useEffect, useState} from "react";
import $ from 'jquery';
import SubFooter from "../home/subFooter";
import {useNavigate} from "react-router-dom";
import BasicInformation from "./basicInformation";
import ChangePassword from "./changePassword";
import MyWallet from "./myWallet";
import Withdraw from "./withdraw";


function MyPageMain(props) {
    const [content, setContent] = useState('');
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');


    function changeTab(e, tabCategory) {
        navigate(`/my_page?page=${tabCategory}`);
    }

    useEffect(() => {
        $('.Rightwarp_moneyinfoBox').css('display', 'none');
        $('#RightWarpCashORfree').css('display', 'none');
        $('.right_warp').css('height', '541px');

        $('.service_tab_links').removeClass('active');

        if (page === 'basicInformation'){
            $('.service_tab_links').eq(0).addClass('active');
            setContent(<BasicInformation/>);

        } else if (page === 'changePassword'){
            $('.service_tab_links').eq(1).addClass('active');
            setContent(<ChangePassword/>);

        } else if(page === 'myWallet'){
            $('.service_tab_links').eq(2).addClass('active');
            setContent(<MyWallet/>);

        } else if(page === 'withdraw'){
            $('.service_tab_links').eq(3).addClass('active');
            setContent(<Withdraw/>);
        } else {
            $('.service_tab_links').eq(0).addClass('active');
            setContent(<BasicInformation/>);
        }

    }, [page]);


    return (
        <>
            <SubHeader bodyColor={false}/>
            <div className="TopMysection">
                <div className="TopMysection_inner" style={{justifyContent: 'left', maxWidth: '1400px'}}>
                    <div className="shop-tab">
                        <div className="tablinks">
                            <div className="shop-top-my-section-title" onClick={event => changeTab(event)}>내정보</div>
                        </div>

                        <div className="service_tab_links active"
                             onClick={event => changeTab(event, 'basicInformation')}>
                            <div className="service-top-my-section-tit">기본정보</div>
                        </div>

                        <div className="service_tab_links"
                             onClick={event => changeTab(event, 'changePassword')}>
                            <div className="service-top-my-section-tit">비밀번호 변경</div>
                        </div>

                        <div className="service_tab_links" onClick={event => changeTab(event, 'myWallet')}>
                            <div className="service-top-my-section-tit">내지갑</div>
                        </div>


                        <div className="service_tab_links" onClick={event => changeTab(event, 'withdraw')}>
                            <div className="service-top-my-section-tit">회원탈퇴</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ServiceCenterWarpMain">
                <div className="ServiceGameCenterWarp">
                    <div className="GameCenterwarp_inner">
                    {content}
                </div>

                    <RightGames redirect={true}/>
                </div>
            </div>

            <SubFooter/>
        </>
    );
}

export default MyPageMain;