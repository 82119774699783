import $ from "jquery";
import {useEffect} from "react";

const BodyColor = () => {
    useEffect(() => {
        $("body").css("background-color", "#FFFFFF");
    }, []);
};

export default BodyColor;
